const Statuscard = ({statusdata}) =>{
    const convertDate = (dateValue) => {
        let date2=new Date(dateValue).toLocaleDateString()
        return date2
    }
    // console.log(statusdata)
    // // console.log(props.otherData)
    // const intTime = parseInt(profileData?.interview_time)> 12 && parseInt(profileData?.interview_time) < 24 ? profileData?.interview_time + " PM" : profileData?.interview_time + " AM";
    return(
        <div>
            <div className="info-cards int-result small rounded pointer overflow-auto mt-2">
                                    <div className="info-header fs-6 text-black text-end px-3 py-2 intStarted">{statusdata?.status}</div>
                                    <div className="info-body p-3">
                                        <div className="row g-3 mb-3">
                                            <div className="col-sm-6">
                                                <div className="cand-name text-primary fs-6 fw-medium text-capitalize">{statusdata?.name}</div>
                                                <div className="cand-role text-muted text-capitalize">{statusdata?.email}</div>
                                                <div className="cand-company text-black text-capitalize">{statusdata?.type}</div>
                                            </div>
                                            <div className="col-sm-6 text-xl-end">
                                                {/* <div className="cand-date text-muted text-capitalize">Thu Mar 02 2023 13:31:16</div>
                                                <div className="cand-status fw-medium text-danger text-capitalize">{profileData?.job_status}</div> */}
                                            </div>
                                        </div>
    
                                        <div className="row g-3">
                                            <div className="col-sm-6 col-xl-8">
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Next Step:</div>
                                                    <div className="label-value text-danger">{statusdata?.nextStep}</div>
                                                </div>
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Note:</div>
                                                    <div className="label-value text-danger">{statusdata?.note}</div>
                                                </div>
                                                {/* <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                                                    <div className="label-title text-black">Test End Time:</div>
                                                    <div className="label-value text-danger">{convertDate(statusdata?.test_endtime)}</div>
                                                </div> */}
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div></div>
        )
    }
    
    export default Statuscard;