// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAWga21acDKGhY7aqGOtVdnMmCF0HGRWKY",
//   authDomain: "live-35345.firebaseapp.com",
//   projectId: "live-35345",
//   storageBucket: "live-35345.appspot.com",
//   messagingSenderId: "786978845564",
//   appId: "1:786978845564:web:c0d40d778d1ba4b756aaf9",
//   measurementId: "G-53V6QZ7FZX",
// };

const secondaryAppConfig = {
  apiKey: "AIzaSyCogzjq7BGyEzM8TVf23S2VmhO38PcqH1c",
  authDomain: "interview-7d0b2.firebaseapp.com",
  projectId: "interview-7d0b2",
  storageBucket: "interview-7d0b2.appspot.com",
  messagingSenderId: "141496720652",
  appId: "1:141496720652:web:5a3ccdee8171da062abd29",
  measurementId: "G-KH4CEN26VN"
};

const firebaseConfig  = {
  apiKey: "AIzaSyAWga21acDKGhY7aqGOtVdnMmCF0HGRWKY",
  authDomain: "live-35345.firebaseapp.com",
  projectId: "live-35345",
  storageBucket: "live-35345.appspot.com",
  messagingSenderId: "786978845564",
  appId: "1:786978845564:web:c0d40d778d1ba4b756aaf9",
  measurementId: "G-53V6QZ7FZX",
};
// Initialize Firebase
export const app = initializeApp(secondaryAppConfig);
export const auth = getAuth(app);
export const database = getFirestore(app);
export const messaging = getMessaging(app);
const provider = new OAuthProvider("microsoft.com");
provider.setCustomParameters({
  prompt: "consent",
  tenant: "a28e6f27-7914-40eb-8b05-dc4a98d7b241",
});

 const secondaryApp = initializeApp(secondaryAppConfig, "secondary");
 const secauth = getAuth(secondaryApp);

export const secondDatabase = getFirestore(secondaryApp);
 //export const secondApp = initializeApp(secondFirebaseConfig);
// export const secondDatabase = getFirestore(secondApp);
export {
  provider,
  OAuthProvider,
  signInWithPopup,
  getAuth,
  onAuthStateChanged,
  getToken,
  onMessage,
  secondaryApp,
  secauth
};
