import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png'
import { UserAuth } from '../context/AuthContext';
import { Loader } from '../components/loader';
import { getAuth, database, secondDatabase, secauth } from '../firebaseConfig';
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { Button } from 'react-bootstrap';
import { useNavigationContext } from "../context/NavContext";
// pagination
import Pagination from '../components/Pagination';
// pagination
export const CompletedProfileUser = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [loading, setIsLoading] = useState(false);
  const [completedProfiles, setCompletedProfiles] = useState(0);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [totalHR, setTotalHR] = useState(0);
  const [totalInterviewCount, setTotalInterviewCount] = useState(0);
  const [usersWith60PercentAccuracy, setUsersWith60PercentAccuracy] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDateSet, setDateVal] = useState(false);
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchCandidate",
    "queryType": [],
    "isSearch": false,
  });
  const [userData, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const { activepage, handlePageClick } = useNavigationContext()

  handlePageClick('dashboard')

  //Fetch Data from Api 
  useEffect(() => {
    // const today = moment().format('YYYY-MM-DD');
    // const today = '';
    // setStartDate(today);
    if (user?.accessToken) {
      fetchCandidateList();
    }

  }, [user, currentPage]);

  //Fetch candidate List
  const fetchCandidateList = async (dateValue) => {
    let filterObj = {};
    filterObj = {
      fromDate: dateValue != '' && dateValue != undefined ? dateValue : startDate,
      page:currentPage,
      pageSize:itemsPerPage
    }

    if (user?.userType == "HR") {
      filterObj = {
        fromDate: dateValue != '' ? dateValue : startDate,
        // refered_by_email: user?.email,
        eMailId: user?.email,
      }
    }
    // console.log(filterObj)
    setfetchObj(filterObj);
    setIsLoading(true);
    // let getToken = await auth.currentUser.getIdToken();
    //let getToken = user?.accessToken;  
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(filterObj);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/get-profile-completed-candidates?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        let data = userDatares.list?.length > 0 ? userDatares.list : [];
        setUsers(data);
        setTotalHR(userDatares?.totalCount)
        setTotalPages(userDatares?.totalPage)
        // console.log(userDatares)
        setIsLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);
      });
  }

  const InterviewerDetails = (info) => {
    // // console.log(info)
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }
    let sendInfo = {
      "candidate_id": info?.id,
      "email": info?.email,
      "profile_img": info?.profileImage,
      "candidate_name": info?.name,
      "login_token": token
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
    navigate(`/user-detail?query=${encodeURIComponent(encryptedQuery)}`);
  }

  //Select Date  
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    if (event.target.value != "")
      fetchCandidateList(event.target.value);
  }

  const convertDate = (dateValue) => {
    let date2 = new Date(dateValue).toLocaleDateString()
    return date2
  }

  // buttonClick
  const handleClick = (type) => {
    const getData = startDate != '' ? startDate : ''
    setStartDate(getData);
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  // buttonClick

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination

  return (
    <div className='dashboard-home py-3'>
      <div className='container-fluid'>
        {loading ? <Loader /> : ''}
        {/* Card Listing */}
        <div className='calender-sec bg-white rounded-3 mb-2 p-3'>
          <div className='row align-items-center'>
            <div className='col-xl-8 col-md-7'>
              <div className="fs-5 fw-medium">Total Candidate: <span className='text-success'>{totalHR >= 1 ? totalHR : 0}</span></div>
            </div>
            <div className='col-xl-4 col-md-5 mt-2 mt-md-0'>
              <div className="d-md-flex align-items-center gap-md-3">
                <label className="form-label text-muted text-nowrap fw-medium m-md-0" htmlFor='calender'>Select Date</label>
                <input type="date" value={startDate} onChange={handleStartDateChange} className="form-control py-2" id="calender" name="calender" />
              </div>
            </div>
          </div>
        </div>

        <div className='candidate-listing-sec'>
          <div className='candidates-wrapper'>
            <div className='candidate-list'>
              {userData && userData?.length > 0 ? userData?.map((info, id) => (
                <div className="info-cards card small bg-white rounded overflow-auto pointer p-3 mt-2" onClick={() => InterviewerDetails(info)} key={id}>
                  <div className="row g-2">
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="img-wrapper mb-2">
                          <img src={info?.user_photo != null && info?.user_photo != '' ? info?.user_photo : imgDefault} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                        </div>
                        <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info?.display_name}</div>
                        {info?.email && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info?.email}</div>}
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Current Company</div>
                        <div className="cand-company text-capitalize">{info?.current_company}</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Total Experience</div>
                        <div className="cand-exp text-capitalize">{info?.total_exp} {info?.total_exp >=2 ? "Years" : "Year"}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Prefered Language</div>
                        <div className="info-box test-lang d-flex flex-wrap gap-2">
                          <span className="skills" key={info?.prefered_lang}>{info?.prefered_lang}</span>
                        </div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Profile Created</div>
                        <div className="test-duration text-capitalize">{info?.created_at ? moment(info?.created_at).utc().format('YYYY-MM-DD HH:mm:ss A') : ''}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      {/* <div className="info-box">
                        <div className="info-label text-secondary">Total Test</div>
                        <div className="cand-lang text-capitalize">{info.test_entry_count}</div>
                      </div> */}
                    </div>
                    <div className="col-12 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Job Status</div>
                        <span className={`fs-12 badge rounded-pill px-3 ${info?.job_status === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info?.job_status === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info?.job_status === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info?.job_status == "Currently On Notice Period" ? "Notice Period" : info?.job_status}</span>
                      </div>

                      <div className='action-wrapper d-flex gap-2 mt-2'>
                        <Button variant='success' size='sm' className='border px-3' onClick={() => InterviewerDetails(info)}>View Details</Button>
                      </div>
                    </div>
                  </div>
                </div>
              )) :
                <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                  <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    {/* <div className='small text-muted'>No candidates registered on the selected date</div> */}
                  </div>
                </div>
              }

              {/* Start: Pagination code */}
              <div className="pagination-wrapper text-center mt-3">
                {userData?.length >= 1 ?
                  // <div className='bg-white d-inline-flex align-items-center border rounded overflow-hidden'>
                  //   <button type="button" className="btn btn-secondary border-0 rounded-0 py-2" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
                  //   <span className='px-3'>Page <span className="fw-bold text-primary">{currentPage}</span> of <span className="fw-bold text-primary">{totalPages}</span></span>
                  //   <button type="button" className="btn btn-primary border-0 rounded-0 py-2" onClick={() => handleClick('next')} disabled={currentPage === totalPages}>Next</button>
                  // </div> 
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                  : ''}
              </div>
              {/* End: Pagination code */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
