import React, { useState, useEffect } from 'react';
import LoadConsentModal from './LoadConsentModal';
import { getAuth, database, secondDatabase, secauth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../components/loader';
import { useNavigationContext } from "../context/NavContext";

const Consentmodalview = () => {
  const [isConsentModalOpen, setConsentModalOpen] = useState(false);
  const [headername, setHeadername] = useState('');
  const [bodyData, setBodyData] = useState('');
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [getConsentval, setConsentVal] = useState('')
  const navigate = useNavigate();
  const { activepage, handlePageClick } = useNavigationContext()
  // getImage
  const getImage = async () => {
      try {
          setIsLoading(true);
          const refreshedToken = await currentUserVal?.getIdToken(true);
          const user_id  = user?.userId
          const postData = {
            "user_id":user_id
          }
          var headers = {
              'Authorization': `Bearer ${refreshedToken}`,
              'Content-Type': 'application/json'
          };
          const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/consent/get-list`, postData, { headers })
          const countData = response?.data.data.length
          const getDataVal = countData == 1 ? response?.data.data[0].image_url : ''
          setBodyData(getDataVal)
          setIsLoading(false);
          let getImgUrl = getDataVal
          return getImgUrl
      } catch (error) {
          console.error('Error fetching requisitions:', error);
          setIsLoading(false);
      }
  };
  // getImage

  useEffect(() => {
    setConsentModalOpen(true);
    setHeadername('First Step')
    async function fetchData() {
      const response = await getImage();
      setBodyData(response)
      if(response==''){
        navigate('/')
        handlePageClick('dashboard')
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {isLoading ? <Loader /> : ''}
      {bodyData != '' ? <LoadConsentModal
        isOpen={isConsentModalOpen}
        isHeader={headername}
        isBody={bodyData}
        pageNumber = {1}
      /> : ''}
      
    </div>
  );
};

export default Consentmodalview;