import moment from 'moment';

const ApplicationHistory = ({ applicationsData, user }) => {
    // console.log(applicationsData)
    const timeFormat = (dateData) => {
        const originalDateString = dateData;
        const formattedDate = moment(originalDateString).format('YYYY-MM-DD');
        return formattedDate
    }

    return (
        <>
            {applicationsData.map((info, id) => (
                <div className="separate-info-card rounded-3 p-3 mb-3" style={{ backgroundColor: '#f7f7f7' }}>
                    <div className="row g-3 g-xl-2">
                        <div className="col-12 col-md-4 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Current Company</div>
                                <div className="cand-company text-capitalize">{info?.current_company}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Job Status</div>
                                <div className="text-capitalize">{info?.job_status}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Job Role Awareness</div>
                                <div className="text-capitalize">{info?.is_job_role_aware}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Notice Period</div>
                                <div className="text-capitalize">{info?.notice_period}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Reason for Job Change</div>
                                <div className="text-capitalize">{info?.reason_job_change}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Current CTC</div>
                                <div className="text-capitalize">{info?.current_ctc}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Expected CTC</div>
                                <div className="text-capitalize">{info?.expected_ctc}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Salary Negotiation</div>
                                <div className="text-capitalize">{info?.is_salary_negotiation}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Possible of Notice BuyOut</div>
                                <div className="text-capitalize">{info?.can_notice_buyout}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Interview Date & Time</div>
                                <div className="regis-date text-capitalize">{info?.interview_date ? timeFormat(info?.interview_date) : ""}, {info?.interview_time}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Comm. Skill Rating</div>
                                <div className="text-capitalize">{info?.comm_skill_rating}/5</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Cultural Fit Rating</div>
                                <div className="text-capitalize">{info?.cultural_fit_rating}/5</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Reason for Cultural fit</div>
                                <div className="text-capitalize">{info?.cultural_fit}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Interview Medium</div>
                                <div className="text-capitalize">{info?.interview_medium}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Requisition ID</div>
                                <div className="requisition-id fw-semibold text-danger text-capitalize">VEREQ{info?.requisition_id}</div>
                            </div>
                        </div>
                        <div className="recruiter-info rounded px-2 py-3" style={{ backgroundColor: '#efefef' }}>
                            <div className="row g-3 g-xl-2">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex align-items-center gap-2 mt-1">
                                        <img src={require('../../assets/Avatar.png')} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '30px', width: '30px' }} />
                                        {
                                            user?.userType == "HR" && user.hrType == '1' ?
                                                info.hrType != 1 ?
                                                <div className="recruiter-name text-capitalize text-truncate">External HR</div>
                                                :
                                                <div className="recruiter-name text-capitalize text-truncate">{info?.added_by_name}</div>
                                            :
                                            <div className="recruiter-name text-capitalize text-truncate">{info?.added_by_name}</div>
                                        }
                                        
                                    </div>
                                </div>
                                <div className="col-6 col-md-4">
                                    <div className="info-box">
                                        <div className="info-label text-clr1 fs-12">Source of Candidate</div>
                                        <div className="cand-company text-capitalize">{info?.source_of_candidate}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4">
                                    <div className="info-box">
                                        <div className="info-label text-clr1 fs-12">Added on</div>
                                        <div className="cand-company text-capitalize">{info?.created_at ? timeFormat(info?.created_at) : ""}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ApplicationHistory