import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageRange = 3; // Adjust the range as needed
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= currentPage - pageRange && i <= currentPage + pageRange)) {
            pageNumbers.push(i);
        } else if (pageNumbers[pageNumbers.length - 1] !== "...") {
            pageNumbers.push("...");
        }
    }

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div>
            <div className="pagination-wrapper text-center">
                <ul className="pagination bg-white d-inline-flex align-items-center border rounded overflow-hidden mt-3">
                    <li className={`btn btn-secondary border-0 rounded-0 py-2 ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className='text-primary text-white text-decoration-none pointer'  onClick={handlePrevious}>Prev</a>
                    </li>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`px-1 ${currentPage === number ? 'active' : ''}`}>
                            <a className='fw-bold text-primary text-decoration-none pointer'  onClick={() => typeof number === 'number' ? onPageChange(number) : null}>
                                {number}
                            </a>
                        </li>
                    ))}
                    <li className={`btn btn-primary border-0 rounded-0 py-2 ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a className='text-primary text-white text-decoration-none pointer'  onClick={handleNext}>Next</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Pagination;
