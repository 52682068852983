import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class HRCandidateChart extends Component {
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {

    if (chart._super) {
      chart.dispose();
    }
    this.initChart();
  }

  initChart() {
    // console.log(this.props.data)
    am4core.useTheme(am4themes_animated);

    chart = am4core.create("HRCandidateChart", am4charts.XYChart);

    //// console.log("chart dAta:",fullData);

    let fullData = [];
    this.props.data.forEach((item) => {
      if (item.interview_created_count > 0) {
        const stuList = item?.interview_created_list != null ? item.interview_created_list : ''
        fullData.push({ name: item.name, number: item.interview_created_count, studentName: stuList })
      }
    })
    // console.log(fullData) 
    // student_name
    chart.data = fullData;

    // chart.data = 
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.labels.template.fontSize = 14;


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 0;
    valueAxis.renderer.labels.template.fontSize = 14;
    valueAxis.integersOnly = true;
    valueAxis.maxPrecision = 0;
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "number";
    series.dataFields.categoryX = "name";
    series.dataFields.categoryY = "studentName";
    series.tooltipText = "[{categoryX}: bold]{categoryX} \n Number Of Candidates: {valueY} \n Candidate Names: {categoryY}[/]";
    series.columns.template.strokeWidth = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    // series.columns.tooltip.maxWidth = am4core.percent(20);

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;


    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();

  }


  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }

  components = {
    foo: 'dataVal',
    bar: 'dataVal',
  };


  render() {
    const fooData = this.components.foo
    return (
      <>
        <h5 className="text-center">Applicant Added to Any Requisition by HR </h5>
        <h6 className="fw-normal text-muted text-center mb-3">Total Candidates: {this.props.totalcount}</h6>
        <div id="HRCandidateChart" style={{ width: "100%", height: "400px", margin: "0 auto" }} />
      </>);
  }
}

export default HRCandidateChart;
