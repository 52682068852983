// src/components/UnauthorizedAccess.js
import React from 'react';

const UnauthorizedAccess = () => {
  return (
    <div className="unauthorized-access py-3" key="unautohiri">
      <div className="container">
        <div className="wrapper text-center">
          <h3 className='text-danger'>Not authorized for this request</h3>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
