import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import ExportToExcel from '../components/ExportToExcel';
import moment from 'moment';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import { useMyContext } from '../context/MyContext';
import CryptoJS from 'crypto-js';
import { useNavigationContext } from "../context/NavContext";
// pagination
import Pagination from '../components/Pagination';
// pagination
export const CandidateSixtyPlus = () => {
  const { values, handleChange } = useMyContext()
  const navigate = useNavigate();
  const auth = getAuth();
  const [candidateList, setCandidate] = useState([]);
  const [workingStatus, setWorkingStatus] = useState(values.workstatusValueSixtyPlus);
  const [resultStatus, setResultStatus] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [isDateSet, setDateVal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchtestResult",
    "queryType": [],
    "isSearch": false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [changehrtype, setChangeHRtype] = useState(values.getHRSixtyPlus)

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const { activepage, handlePageClick } = useNavigationContext()

  handlePageClick('dashboard')

  const openFilter = (event) => {
    setIsActive(current => !current);
  };


  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);
      let filterObj = {
        "hrType": changehrtype,
        "currentJobStatus": workingStatus,
        "resultStatus": 'Pass',
        "is_unique": 1,
        "page": currentPage,
        "pageSize": itemsPerPage
      };
      if (user?.userType == "HR") {
        filterObj.hr_email = user?.email;
        filterObj.eMailId = user?.email;
      }
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-interiew?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
        .then(function (response) {
          let userDatares = response.data;
          let data = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
          setCandidate(data);
          setTotalPages(userDatares?.totalPages)
          setIsLoading(false);
        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);
        });
    }
    // fetchList();
    if (((values.workstatusValueSixtyPlus == '') || (values.workstatusValueSixtyPlus == undefined) || (values.workstatusValueSixtyPlus == null)) && ((values.getHRSixtyPlus == '') || (values.getHRSixtyPlus == undefined) || (values.getHRSixtyPlus == null))) {
      fetchList();
    } else {
      onloadcheckStatus()
    }
  }, [currentPage, currentUserVal, values.getHRSixtyPlus, values.workstatusValueSixtyPlus])

  const fetchListwithFilter = async (filterObj) => {
    setIsLoading(true);
    filterObj.page = currentPage;
    filterObj.pageSize = itemsPerPage;
    if (user?.userType == "HR") {
      filterObj.hr_email = user?.email;
      filterObj.eMailId = user?.email;
    }
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(filterObj);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-interiew?page=${currentPage}&pageSize=${itemsPerPage}`, data, { headers })
      .then(function (response) {
        let userDatares = response.data;
        let data = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
        setCandidate(data);
        setTotalPages(userDatares?.totalPages)
        setIsLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);
      });
  }


  //On Work Status Change
  const workStatusChange = (event) => {
    const workstatusValueTotInt = event.target.value
    handleChange('workstatusValueSixtyPlus', workstatusValueTotInt)
    setWorkingStatus(event.target.value);
    setError(null);
    setCurrentPage(1)
  }

  const HRstatuschange = (e) => {
    const HRtype = e.target.value
    handleChange('getHRSixtyPlus', HRtype)
    setChangeHRtype(e.target.value);
    setError(null);
    setCurrentPage(1)
  }


  // onload Data
  const onloadcheckStatus = () => {
    let updatedFilterData = {
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      currentJobStatus: workingStatus ? workingStatus : '',
      hrType: changehrtype ? changehrtype : '',
      searchString: searchVal ? searchVal : "",
      resultStatus: "Pass",
      is_unique: 1
    };
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
    setError(null);
  }
  // onload data

  const handleStartDateChange = (event) => {
    setError(null);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setError(null);
    if (!startDate && event.target.value != "") {
      setError("Please select start Date before end Date.");
      setDateVal(false);
      return;
    }
    if (startDate > event.target.value && event.target.value != "") {
      setError("End Date cannot be less than the start Date.");
      setDateVal(false);
      return;
    }
    setEndDate(event.target.value);
    setDateVal(event.target.value != "" ? true : false);
  };

  const handleApplyClick = () => {
    const filterData = {
      operation: "fetchtestResult",
      queryType: [],
      isSearch: false,
      searchString: searchVal,
      resultStatus: "Pass",
      is_unique: 1
    };
    let updatedFilterData = {
      operation: "fetchtestResult",
      queryType: [],
      isSearch: false,
    };

    if (!isDateSet && (startDate != "" || endDate != "")) {
      setError("Please select from and to Date.");
      setDateVal(false);
      return;
    }

    if (isDateSet && resultStatus !== "" && workingStatus !== "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        currentJobStatus: workingStatus,
        resultStatus: "Pass",
        is_unique: 1
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (isDateSet && resultStatus !== "" && workingStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        resultStatus: "Pass",
        is_unique: 1
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (isDateSet && workingStatus != "" && resultStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
        currentJobStatus: workingStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }

    if (isDateSet && workingStatus == "" && resultStatus == "") {
      const updates = {
        startDate: startDate,
        endDate: endDate,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus != "" && resultStatus != "") {
      const updates = {
        currentJobStatus: workingStatus,
        resultStatus: "Pass",
        is_unique: 1
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus != "" && resultStatus == "") {
      const updates = {
        currentJobStatus: workingStatus,
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    if (!isDateSet && workingStatus == "" && resultStatus != "") {
      const updates = {
        resultStatus: "Pass",
        is_unique: 1
      };
      updatedFilterData = { ...filterData, ...updates };
      setError(null);
    }
    setfetchObj(updatedFilterData);
    fetchListwithFilter(updatedFilterData);
  };

  const searchFunction = (event) => {
    let searchText = event.target.value;
    // console.log(searchText)
    setCurrentPage(1)
    setSearchVal(searchText);
    // data
    let updatedFilterData = {
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      currentJobStatus: workingStatus,
      hrType: changehrtype,
      searchString: searchText,
      resultStatus: "Pass",
      is_unique: 1,
    };
    fetchListwithFilter(updatedFilterData);
    // data
    // OLD
    // if (searchText == "") {
    //   fetchListwithFilter(fetchObj);
    // }
    // if (searchText != "" & searchText?.length > 2) {
    //   let updatedFilterData = {
    //     startDate: "",
    //     endDate: "",
    //     currentJobStatus: "",
    //     resultStatus: "Pass",
    //     is_unique: 1,
    //     searchString: searchText
    //   };
    //   fetchListwithFilter(updatedFilterData);
    // }
    // OLD
  }

  const CandidateDetails = (info, btntype) => {
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }
    let sendInfo = {
      "candidate_id": info?.id,
      "email": info?.userEmail, //info?.email,
      "profile_img": info?.profileImg,//info?.profileImage,
      "candidate_name": info?.displayName,//info?.name,
      "login_token": token
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
    navigate(`/user-detail?query=${encodeURIComponent(encryptedQuery)}`);
  }

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination


  return (
    <div className='interviewList-page dashboard-home py-3'>
      <div className="container-fluid">
        <div className='filter-sec mb-2'>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            <div className="filter-label fw-medium pointer"></div>

            {candidateList?.length > 0 ?
              <ExportToExcel data={candidateList} filename="my_data" /> :
              null
            }
          </div>

          {/* <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2 ${isActive ? 'active' : 'd-none'}`}> */}
          <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
            <div className="searchfield-wrapper flex-fill">
              <input type="search" className="form-control rounded-2" id="search" value={searchVal} placeholder="Search Here..." onChange={searchFunction} />
              <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
            </div>
            {user?.userType == 'SSA' ?
              <div className="Result-filter flex-fill">
                <select className="form-select" aria-label="Default select example" value={values.getHRSixtyPlus} onChange={HRstatuschange}>
                  <option value="">Select HR</option>
                  <option value="1">Inhouse</option>
                  <option value="2">Outsourced</option>
                </select>
              </div> : ''}

            <div className="status-filter flex-fill">
              <select className="form-select" value={workingStatus} aria-label="Default select example" onChange={workStatusChange} required>
                <option value="">Working Status</option>
                <option value="In Job">In Job</option>
                <option value="On Notice Period">On Notice Period</option>
                <option value="No Where Working">No Where Working</option>
                <option value="Fresher">Fresher</option>
              </select>
            </div>

            <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3">
              <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
              <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
              <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => handleApplyClick()}>Apply</button>
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>

        <div className='candidates-wrapper'>
          {/* When loader visible */}
          {isLoading ? (
            <Loader />
          ) : ''}

          <div className='candidate-list'>
            {candidateList.map((info, id) => {
              const originalMoment = moment(info?.testStarttime).utc();
              const formattedDate = originalMoment.format('YYYY-MM-DD HH:mm:ss');

              let profilePic = "";
              let startMoment = moment(info?.testStarttime).utc();
              let endMoment = moment(info?.test_endtime).utc();
              let duration = moment.duration(endMoment.diff(startMoment));
              let testStartDatetime = startMoment.format("YYYY-MM-DD HH:mm:ss");
              profilePic = info?.user_photo != null && info?.user_photo != '' ? info?.user_photo : imgDefault;

              return (
                <div className="info-cards card small bg-white rounded overflow-auto p-3 mt-2">
                  <div className="row g-2">
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="img-wrapper mb-2">
                          <img src={profilePic} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                        </div>
                        <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info.displayName}</div>
                        {info.userEmail && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info.userEmail}</div>}
                        {info.contactNo && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill"></i> {info.contactNo}</div>}
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Current Company</div>
                        <div className="cand-company text-capitalize">{info.currentCompany}</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Start Time</div>
                        <div className="date text-capitalize">{formattedDate}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Experience</div>
                        <div className="cand-exp text-capitalize">{info.totalExp}  {info?.totalExp >= 2 ? "Years" : "Year"}</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Duration</div>
                        {/* <div className="test-duration">{isNaN(duration.minutes()) ? "end time not saved" : `${duration.minutes()} ${duration.minutes() >=2 ? "minutes" : "minute"}`} </div> */}
                        <div className="test-duration">{info?.testDuration == "NaN minutes" ? "--" : info?.testDuration} </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Language</div>
                        <div className="cand-lang text-capitalize">{info.selectedLanguage}</div>
                      </div>
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Result</div>
                        <div className={`${info.result === 'Pass' ? 'btn-success' : 'btn-danger'}`}>{info.result}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Working Status</div>
                        <span className={`fs-12 badge rounded-pill px-3 ${info.jobStatus === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.jobStatus === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info.jobStatus === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info?.jobStatus == "Currently On Notice Period" ? "Notice Period" : info?.jobStatus}</span>
                      </div>
                      <div className='action-wrapper d-flex gap-2 mt-2'>
                        <button type='button' className='btn btn-sm btn-light border px-3' onClick={() => CandidateDetails(info, 'viewDetail')}>View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )}
            {/* Start: Pagination code */}
            <div className="pagination-wrapper text-center mt-3">
              {candidateList?.length >= 1 ?
                // <div className='bg-white d-inline-flex align-items-center border rounded overflow-hidden'>
                //   <button type="button" className="btn btn-secondary border-0 rounded-0 py-2" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
                //   <span className='px-3'>Page <span className="fw-bold text-primary">{currentPage}</span> of <span className="fw-bold text-primary">{totalPages}</span></span>
                //   <button type="button" className="btn btn-primary border-0 rounded-0 py-2" onClick={() => handleClick('next')} disabled={currentPage === totalPages}>Next</button>
                // </div> 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                : ''}
            </div>
            {/* End: Pagination code */}
          </div>


          {/* No data available */}
          {candidateList?.length == 0 ? (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
              <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
              </div>
            </div>) : null
          }
        </div>
      </div>
    </div >

  )
}
