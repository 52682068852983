import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Data = [
    {
        date: '29/03/2023',
        questions: "20",
        time: '59:00 Min',
        correct: '15',
        inCorrect: '5',
        status: "Pass",
        skills: ['html', 'css', 'PHP', 'React']
    },
    {
        date: '28/03/2023',
        questions: "20",
        time: '50:00 Min',
        correct: '5',
        inCorrect: '15',
        status: "Fail",
        skills: ['html', 'css', 'PHP', 'React']
    },
    {
        date: '27/03/2023',
        questions: "20",
        correct: '14',
        inCorrect: '6',
        time: '01:00 Hrs',
        status: "Pass",
        skills: ['html', 'css', 'PHP', 'React']
    },
    {
        date: '26/03/2023',
        questions: "20",
        time: '40:00 Min',
        correct: '18',
        inCorrect: '2',
        status: "Pass",
        skills: ['html', 'css', 'PHP', 'React']
    },
    {
        date: '25/03/2023',
        questions: "20",
        time: '35:00 Min',
        correct: '6',
        inCorrect: '14',
        status: "Fail",
        skills: ['html', 'css', 'PHP', 'React']
    },
    {
        date: '20/03/2023',
        questions: "20",
        time: '01:00 Hrs',
        correct: '20',
        inCorrect: '0',
        status: "Pass",
        skills: ['html', 'css', 'PHP', 'React']
    },
    
]

export const CandidateAllTest = () => {
    const location = useLocation()
    const name = location.state;

    const navigate = useNavigate();
    const TestDetailsScreen = (item) => {
        navigate('/test-details', { state: item });
    }
    

    return (
        <div className='cand-all-tests py-4'>
            <div className='container-fluid'>
                {/* Candidate Profile Details */}
                <div className='card p-3 p-lg-4'>
                    <div className="candidate-details">
                        <div className="row align-items-center">
                            <div className='col-xxl-2 col-lg-3 col-md-4'>
                                <img src={name.img} alt="Candidate" className="img-fluid border rounded-2" />
                            </div>

                            <div className="col-xxl-10 col-lg-9 col-md-8 mt-2 mt-md-0">
                                <div className="fs-4 cand-name fw-medium text-capitalize lh-sm mb-1">{name.name}</div>
                                <div className="cand-profile text-muted mb-2">UI/UX Designer</div>
                                <div className='d-flex flex-column flex-md-row gap-2 gap-md-4'>
                                    <div className="cand-email d-flex align-items-center gap-2"><i className="bi bi-envelope bg-primary text-light rounded-1 px-1"></i>{name.email}</div>
                                    <div className='candidate-contact d-flex align-items-center gap-2'><i className="bi bi-telephone bg-primary text-light rounded-1 px-1"></i><span><span className='fw-medium'>+91</span> 9946546565</span></div>
                                </div>
                                <div className='candidate-desc text-muted border-top border-opacity-50 pt-2 mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of specimen book.</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* All Tests Listing */}
                <div className='previous-test-wrapper bg-white rounded p-4 mt-4'>
                    <div className="heading-title fs-5 fw-medium mb-3"><i className="bi bi-journal-code"></i> Candidate All Tests</div>
                    <div className='table-responsive border rounded-3' style={{ maxHeight: '70vh' }}>
                        <table className="table align-middle text-nowrap mb-0">
                            <thead className="bg-primary text-light sticky-top">
                                <tr>
                                    <th scope="col">
                                        SR No.
                                    </th>
                                    <th scope="col">Test Date</th>
                                    <th scope="col">Test Language</th>
                                    <th scope="col">Total Questions</th>
                                    <th scope="col">Correct</th>
                                    <th scope="col">Incorrect</th>
                                    <th scope="col">Total Time</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Data.map((item, index) => (
                                    <tr className='pointer' key={index} onClick={() => TestDetailsScreen(item)}>
                                        <td className='sr'>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <div className="test-date">{item.date}</div>
                                        </td>
                                        <td>
                                            <div className="test-lang d-flex flex-wrap gap-2">
                                            {item.skills.map((name, id) => (
                                                <span className="skills" key={id}>{name}</span>
                                            ))}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="total-ques text-muted">{item.questions}</div>
                                        </td>
                                        <td>
                                            <div className="right-ans btn btn-success lh-sm py-1 px-2 fw-bold" style={{minWidth: '40px',fontSize: '14px'}}>{item.correct}</div>
                                        </td>
                                        <td>
                                            <div className="wrong-ans btn btn-danger lh-sm py-1 px-2 fw-bold" style={{minWidth: '40px',fontSize: '14px'}}>{item.inCorrect}</div>
                                        </td>
                                        <td>
                                            <div className="total-time text-muted">{item.time}</div>
                                        </td>
                                        <td>
                                            <div className={`badge rounded-pill fw-medium px-3 ${item.status === 'Pass' ? 'text-bg-success' : 'text-bg-warning' && item.status === 'Fail' ? 'text-bg-danger' : 'text-bg-warning'}`} style={{minWidth: '60px'}}>{item.status}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
