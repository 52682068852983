import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Editor, { loader } from '@monaco-editor/react';
import OutputWindow from "./OutputWindow";
import OutputDetails from "./OutputDetails";
import CryptoJS from 'crypto-js';
import { useLocation } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';
import { Loader } from '../components/loader';
const Instructions = () => {
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('query');
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log(user)
    console.log(decryptedData)
    const [code, setCode] = useState("");
    const [value, setValue] = useState(code || "");
    const editorRef = useRef(null);
    const [outputDetails, setOutputDetails] = useState(null);
    // functions

    useEffect(() => {
        const getCode = decryptedData.code
        setCode(getCode)
    }, [decryptedData])

    const handleEditorDidMount = (editor, monaco) => {
        editor.updateOptions({
            contextmenu: false
        });
    };
    const onChange = (action, data) => {
        switch (action) {
            case "code": {
                setCode(data);
                break;
            }
            default: {
                console.warn("case not handled!", action, data);
            }
        }
    };
    const handleCompile = () => {
        setLoading(true)
        const formData = {
            language_id: user.langId,
            source_code: btoa(decryptedData.code),
            // stdin: btoa(customInput),
        };
        const options = {
            method: "POST",
            url: process.env.REACT_APP_RAPID_API_URL,
            params: { base64_encoded: "true", fields: "*" },
            headers: {
                "content-type": "application/json",
                "Content-Type": "application/json",
                "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
                "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
            },
            data: formData,
        };
        axios
            .request(options)
            .then(function (response) {
                console.log(response)
                console.log("responseData")
                const token = response.data.token;
                checkStatus(token);
                console.log(token)
                console.log("tokenStatus")
            })
            .catch((err) => {
                console.log("Err data");
                // Sentry.captureException(err);
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                if (status === 429) {
                    console.log("too many requests", status);
                }
                // setProcessing(false);
                console.log("catch block...", error);
            });
    }

    const handleEditorChange = (value) => {
        setValue(value);
        onChange("code", value);
    };

    const editorOptions = {
        wordWrap: 'on', // Enable word wrapping
        automaticLayout: true, // Adjust editor layout automatically
    };

    const checkStatus = async (token) => {
        setLoading(true);
        const options = {
            method: "GET",
            url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
            params: { base64_encoded: "true", fields: "*" },
            headers: {
                "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
                "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
            },
        };
        try {
            let response = await axios.request(options);
            console.log(response)
            console.log("responseData!")
            let statusId = response.data.status?.id;
            // Processed - we have a result
            if (statusId === 1 || statusId === 2) {
                // still processing
                setTimeout(() => {
                    checkStatus(token);
                }, 2000);
                return;
            } else {
                setLoading(false);
                setOutputDetails(response.data);
                return;
            }
        } catch (err) {
            console.log("err for compilation");
            console.log(err);
            // Sentry.captureException(err);
            // setProcessing(false);
        }
    };
    // functions

    return (
        <div className='compile-code-page py-3'>
            {isLoading ? <Loader></Loader> : ""}
            <div className='container-fluid'>
                {/* <div className="fs-18 fw-medium mb-2">Compile Code</div> */}
                <div className='card rounded-3 p-3'>
                    <div className='fs-18 fw-semibold mb-0'>Question</div>
                    <p className='fs-14 text-muted mb-2'>{decryptedData.question}</p>
                    <Editor
                        onMount={(editor, monaco) => {
                            editorRef.current = editor; // Save editor instance to ref
                            handleEditorDidMount(editor, monaco); // Call your custom onMount logic
                        }}
                        ref={editorRef}
                        height={`50vh`}
                        width={`100%`}
                        // language={profile?.prefered_lang}
                        value={value}
                        theme="vs-dark"
                        defaultValue={decryptedData?.code}
                        // options={editorOptions}
                        options={{
                            ...editorOptions, // Assuming editorOptions is defined elsewhere
                            readOnly: false, // Make sure this is set to false if you want to allow editing
                            contextmenu: true, // Enable context menu
                            selectionClipboard: true, // Enable selection and copying to clipboard
                        }}
                        onChange={(value) => {
                            handleEditorChange(value);
                        }}
                    />
                    <OutputWindow outputDetails={outputDetails} />
                    {outputDetails && <OutputDetails outputDetails={outputDetails} />}
                    <div className='btn-wrapper mt-2 mb-2'>
                        <button className='btn btn-primary py-2 fw-medium px-4' onClick={handleCompile}>Compile</button>
                    </div>
                </div>
            </div>
        </div >

    )
};

export default Instructions;
