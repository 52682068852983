import axios from "axios";
import { UserAuth } from "../context/AuthContext";
import { connect } from "twilio-video";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const AdminRoomPage = (props) => {
  let navigate = useNavigate();
  const { user, userInfoLoaded } = UserAuth();

  const [room, setRoom] = useState(null);
  const search = useLocation().search;

  const name = new URLSearchParams(search).get("name");
  const roomIdUrl = new URLSearchParams(search).get("id");

  const roomId = atob(roomIdUrl);
  // // console.log(name, roomId);

  // // console.log(JSON.stringify(user));
  const container = document.getElementById("player");

  useEffect(() => {
   
    if (!user) navigate("/dashboard");
   
  }, [user]);

  async function startRoom(roomId) {
    // document.getElementById('form2').style.display = "block";
    // // console.log("roomName: ", roomId);
    const roomName = roomId;

    const token = await generateToken();
    //// console.log("Token:", token);

    async function generateToken() {
      const randomId = makeid(30);

      //// console.log(randomId);
      const response = await axios.get(
        `https://live-work-3589-dev.twil.io/token-service?identity=${randomId}`
      );
      const data = response.data;

      return data.accessToken;
    }
    function makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return "Admin_"+result;
    }
    // join the video room with the token
    const room = await joinVideoRoom(roomName, token);
    if(room){
      setRoom(room)
    // render the local and remote participants' video and audio tracks
    handleConnectedParticipant(room.localParticipant);

    room.participants.forEach(handleConnectedParticipant);
    room.on("participantConnected", handleConnectedParticipant);

    room.on('disconnected', room => {
      // Detach the local media elements
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      });
    });

    // handle cleanup when a participant disconnects
    // room.on("participantDisconnected", handleDisconnectedParticipant);
    // window.addEventListener("pagehide", () => room.disconnect());
    // window.addEventListener("beforeunload", () => room.disconnect());
    } else {
      <h3>Room has been disconnected</h3>
    }
    
  }

  async function handleConnectedParticipant(participant) {
    // create a div for this participant's tracks
    const participantDiv = document.createElement("div");
    participantDiv.setAttribute("id", participant.identity);
    if (container) container.appendChild(participantDiv);

    // iterate through the participant's published tracks and
    // call `handleTrackPublication` on them
    participant.tracks.forEach((trackPublication) => {
      handleTrackPublication(trackPublication, participant);
    });

    // listen for any new track publications
    participant.on("trackPublished", handleTrackPublication);
  }

  async function handleTrackPublication(trackPublication, participant) {
    function displayTrack(track) {
      try {
        // // console.log(participant.sid, track.name);
        // append this track to the participant's div and render it on the page
        //screen-share-track
        const screenTrackDiv= document.getElementById("screen-track-div");
        const restTrackDiv = document.getElementById("sub-screen-tracks");
        if(track.name === "screen-share-track"){
          screenTrackDiv.append(track.attach());
        } else{
          restTrackDiv.append(track.attach());
        }
       
        // track.attach creates an HTMLVideoElement or HTMLAudioElement
        // (depending on the type of track) and adds the video or audio stream
       
      } catch (err) {
        // console.log(err);
      }
    }

    // check if the trackPublication contains a `track` attribute. If it does,
    // we are subscribed to this track. If not, we are not subscribed.
    if (trackPublication.track) {
     // // console.log(trackPublication.track);
      displayTrack(trackPublication.track);
    }

    // listen for any new subscriptions to this track publication
    trackPublication.on("subscribed", displayTrack);
  }

  async function handleDisconnectedParticipant(participant) {
    
    // // console.log("participant remved !!!!!!!!!!!!",participant)
    participant.disconnect();
    // remove this participant's div from the page
    // const participantDiv = document.getElementById(participant.identity);
    // participantDiv.remove();
  }

  async function joinVideoRoom(roomName, token) {
    const room = await connect(token, {
      name: roomName,
      video: false,
      audio: false,
    });

    return room;
  }

  useEffect(()=>{
    const roomInfo = startRoom(roomId)
    
    setRoom(roomInfo)

    // // console.log("room info:::", roomInfo)
  },[])
  //  // console.log(room?.localParticipant?.identity)
  //  if(room){
  //   room.participants.map((item)=>{
  //     // console.log(item)
  //    })
  //  }
 
  const handleDisconnect = async(roomSid,participantIdentity)=>{
    await axios.post(process.env.REACT_APP_ADMIN_API+ "/v3.0/twilio/disconnect-participant",{roomSid:roomSid,participantIdentity:participantIdentity});
      navigate("/ongoing-interviews")
  }

  return (
    <div className="hr-calling-screen py-3">

      <div className="container">
     {room?
     <>
     <div className="text-end mb-3">
          <button className="btn btn-danger" onClick={()=>{handleDisconnect(room?.sid,room?.localParticipant?.identity)}}>Disconnect</button>
        </div>

        
        <div className="videos-boxes bg-white p-2 p-md-4 rounded">
          <div className="row g-2 g-md-3 g-lg-4">
            <div className="col-md-8">
              <div className="video-wrapper h-100 left-sec ratio ratio-16x9 overflow-hidden" id="screen-track-div">
                
              </div>
            </div>
            <div className="col-md-4">
              <div className="video-wrapper h-100 right-sec d-flex flex-md-column gap-2 gap-lg-3" id="sub-screen-tracks">
              </div>
            </div>
          </div>
        </div>
     </>
        
        :
      <h5>Candidate has given interview</h5>
     }
      
    </div>
    </div>
    
  );
};
