import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const InterviewMediumReport = ({data}) => {
    const allData = data
    useEffect(() => {
        // Create chart instance
        let chart = am4core.create('InterviewMediumReport', am4charts.PieChart);
    
        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Add data
        chart.data = allData
    
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'count';
        pieSeries.dataFields.category = 'interview_medium';
    
        // Customize slice labels
        pieSeries.labels.template.text = '{interview_medium}';
        pieSeries.slices.template.tooltipText = "[bold]{interview_medium}[/]: {count}";
        pieSeries.labels.template.disabled = true; // Disable default labels

        // colors
        pieSeries.colors.list = [
            new am4core.color('#009efa'),
            new am4core.color('#ed1f17'),
            new am4core.color('#f4bc2b'),
            new am4core.color('#b0b0eb'),
            new am4core.color('#1a1d45'),
        ]
        // colors

        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Clean up on unmount
        return () => {
          chart.dispose();
        };
      }, [allData]); // empty dependency array means this effect will only run once after initial render

  return <div id="InterviewMediumReport" style={{ width: '100%', height: '500px' }} />;
};

export default InterviewMediumReport;