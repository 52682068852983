import React from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import imgDefault from '../../assets/Avatar.png';
const CandidateStatus = ({ CandidateData }) => {
    const navigate = useNavigate();
    const convertDate = (dateValue) => {
        const parsedDatetime = new Date(dateValue);
        const date = parsedDatetime.toLocaleDateString();
        const time = parsedDatetime.toLocaleTimeString();
        let date2 = date + ' ' + time
        return date2
    }
    const CompleteRegistration = <div className="info-header fs-6 text-white text-capitalize text-md-end px-3 py-2">Candidate Verification Completed</div>
    const IncompleteRegistration = <div className="info-header fs-6 text-white text-capitalize text-md-end px-3 py-2">Verification Not Completed</div>

    // redirect
    const InterviewerDetails = (info) => {
        console.log(info)
        console.log("information!!")
        let token = "";
        if (info?.user_photo != "") {
            token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
        }
        let sendInfo = {
            "candidate_id": info?.id,
            "email": info?.userEmail,
            "profile_img": info?.profileImg,
            "candidate_name": info?.displayName,
            "login_token": token
        }
        const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
        navigate(`/user-detail?query=${encodeURIComponent(encryptedQuery)}`);
    }
    // redirect

    return (
        <>
            {/* Verification Completed Card */}
            {CandidateData.map((info, id) => (
                <div className={`${info?.is_profile_completed == 1 ? "status-completed" : "status-incompleted"} card small rounded pointer overflow-auto mb-2`} onClick={() => InterviewerDetails(info)}>
                    {info?.is_profile_completed == 1 ? CompleteRegistration : info?.is_profile_completed == 0 ? IncompleteRegistration : IncompleteRegistration}
                    <div className="info-body p-3">
                        <div className="row g-3">
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="d-flex gap-2 mb-2 pb-1">
                                    <div className="profile-img">
                                        <img src={info?.user_photo ? info?.user_photo : imgDefault} alt="Candidate Profile" className="img-fluid rounded object-fit-cover" style={{ width: "65px", minWidth: '65px', height: "65px" }} />
                                    </div>
                                    <div className="profile-info overflow-auto ps-1">
                                        <div className="cand-name fs-6 fw-semibold text-capitalize text-truncate">{info?.displayName}</div>
                                        <div className="cand-role text-muted text-capitalize text-truncate">{info?.displayName}</div>
                                        <div className="cand-company text-body text-capitalize text-truncate">{info?.currentCompany}</div>
                                    </div>
                                </div>

                                <div className="hr-info text-capitalize">
                                    {info?.refered_by != '' ? <div className="added-by d-flex flex-wrap gap-2 text-muted">Added By HR: <span className="fw-semibold text-body">{info?.refered_by}</span></div> : ''}
                                    <div className="created-on d-flex flex-wrap gap-2 text-muted">Created On: <span className="text-body">{info?.createdAt != '' ? convertDate(info?.createdAt) : info?.createdAt}</span></div>
                                </div>

                                <div className="cand-status mt-2">
                                    <span className={`fs-14 fw-medium badge rounded-pill px-3 ${info.jobStatus === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.jobStatus === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info.jobStatus === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info.jobStatus}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-6">
                                <div className="middle-sec row g-2">
                                    <div className="col-xxl-6">
                                        <div className="info-wrapper small">
                                            <div className="cand-mob d-flex gap-2 mb-2">
                                                <span className="icon bg-success rounded-circle d-flex align-items-center justify-content-center" style={{ width: "20px", minWidth: "20px", height: "20px" }}>
                                                    <i className="bi bi-telephone-fill text-white" style={{ fontSize: '10px' }}></i>
                                                </span>
                                                <span className="text-body">{info?.contactNo}</span>
                                            </div>
                                            <div className="cand-email d-flex gap-2 mb-2">
                                                <span className="icon bg-success rounded-circle d-flex align-items-center justify-content-center" style={{ width: "20px", minWidth: "20px", height: "20px" }}>
                                                    <i className="bi bi-envelope-paper-fill text-warning" style={{ fontSize: '10px' }}></i>
                                                </span>
                                                <span className="text-body text-truncate text-lowercase">{info?.userEmail}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xxl-6">
                                        <div className="info-wrapper small">
                                            <div className="d-flex gap-2 mb-2">
                                                <div className="col-xxl-8 text-muted text-md-end">Experience:</div>
                                                <div className="col-xxl-4 text-body">{info?.totalExp} {info?.totalExp > 1 ? "Years" : "Year"}</div>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <div className="col-xxl-8 text-muted text-md-end">Total Tests Attempted:</div>
                                                <div className="col-xxl-4 text-body">{info?.interview_count}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-xxl-3">
                                <div className="row g-1 g-md-2 info-wrapper small">
                                    <div className="col-md-6 col-xl-12 d-flex gap-2">
                                        <div className="col-xl text-muted text-md-end">Email Verified at:</div>
                                        <div className="col-xl text-body">
                                            {
                                                info?.email_varified_at != '' ? convertDate(info?.email_varified_at) :
                                                    <span className="cross-icon d-flex align-items-center justify-content-center rounded-circle">
                                                        <i className="bi bi-x text-white"></i>
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xl-12 d-flex align-items-center gap-2">
                                        <div className="col-xl text-muted text-md-end">Mobile Verified:</div>
                                        <div className="col-xl">
                                            <span className={`${info?.is_number_verified == 1 ? "check-icon" : "cross-icon"} d-flex align-items-center justify-content-center rounded-circle`}>
                                                {info?.is_number_verified == 1 ? <i className="bi bi-check2 text-white"></i> : <i className="bi bi-x text-white"></i>}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xl-12 d-flex align-items-center gap-2">
                                        <div className="col-xl text-muted text-md-end">Video KYC:</div>
                                        <div className="col-xl">
                                            <span className={`${info?.is_video_verified == 1 ? "check-icon" : "cross-icon"} d-flex align-items-center justify-content-center rounded-circle`}>
                                                {info?.is_video_verified == 1 ? <i className="bi bi-check2 text-white"></i> : <i className="bi bi-x text-white"></i>}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-12 d-flex gap-2">
                                        <div className="col-xl text-muted text-md-end">Complete Verification at:</div>
                                        <div className="col-xl text-body">{info?.complete_varified_at != '' ? convertDate(info?.complete_varified_at) : info?.complete_varified_at}</div>
                                    </div>
                                    <div className="col-md-6 col-xl-12 d-flex gap-2">
                                        <div className="col-xl text-muted text-md-end">Last Active at:</div>
                                        <div className="col-xl text-body">{info?.last_login_datetime != '' ? convertDate(info?.last_login_datetime) : info?.last_login_datetime}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            info?.refered_by != null && info?.refered_by != ""
                                ? ""
                                :
                                <div className='desc small border-top text-muted text-xl-end mt-3 pt-3'>
                                    This information is provided directly by the resource and it is not input by HR.
                                </div>
                        }

                    </div>
                </div>
            ))}
        </>
    )
}
export default CandidateStatus