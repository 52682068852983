import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import ExportToExcel from '../components/ExportToExcel';
import moment from 'moment';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import { useMyContext } from '../context/MyContext';
import Pagination from '../components/Pagination';
import CryptoJS from 'crypto-js';

export const InterviewList = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [candidateList, setCandidate] = useState([]);
  const [workingStatus, setWorkingStatus] = useState("");
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [error, setError] = useState(null);
  const [Imgerror, setImgError] = useState('Error: Failed to load image. Please check the URL and try again.');
  const [isDateSet, setDateVal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchValData, setSearchValdata] = useState("");
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchtestResult",
    "queryType": [],
    "isSearch": false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const { values, handleChange } = useMyContext()
  const [changehrtype, setChangeHRtype] = useState('')
  const [dashboardData, setDashboardData] = useState({
    inhouseHrCount: 0,
    outsourcedHrCount: 0,
    uniqueCandidateCount: 0,
    passCount: 0,
    failCount: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
  const [isStartDayToday, setStartDay] = useState(true);

  const openFilter = (event) => {
    setIsActive(current => !current);
  };

  const isStartDateToday = () => {
    if (moment(startDate).isSame(moment(), 'day'))
      setStartDay(true);
    else
      setStartDay(false);
  };

  const fetchDashboardBlock = async () => {
    setIsLoading(true);

    if (user?.accessToken) {
      try {
        const fetchobj = {
          currentJobStatus: workingStatus,
          startDate: startDate,
          endDate: endDate,
          searchString: "",
          test_type: "",
          ...(user?.userType === "HR" && { eMailId: user?.email }),
        };

        const refreshedToken = await currentUserVal?.getIdToken(true);
        let getToken = user?.accessToken;
        var data = JSON.stringify(fetchobj);
        var headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-count`, data, { headers });
        const responseData = response ? response : '';
        console.log("Dashboard Data");
        console.log(responseData?.data);
        setDashboardData(responseData?.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };


  // GenerateOverallResult
  const overallData = async () => {
    try {
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const filterObj = {
        dataVal: ""
      };
      const data = JSON.stringify(filterObj);
      const headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/generate-overall-result-all`,
        data,
        { headers });
    } catch (error) {
      // setError(error.message);
      console.log(error.message)
    } finally {
      // setLoading(false);
    }
  };
  // GenerateOverallResult

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchVal(searchVal);
    }, 500);

    return () => {
      clearTimeout(handler); // Clear the timeout if searchVal changes before 500ms
    };
  }, [searchVal]);

  useEffect(() => {
    const fetchList = async () => {
      console.log("currentPage***");
      console.log(currentPage);
      setIsLoading(true);
      var getSearchedValue = debouncedSearchVal.includes('VEREQ')
      var getremovedVal = ''
      var is_searchByRequisitionValue = ''
      if (getSearchedValue == true) {
        getremovedVal = debouncedSearchVal.replace('VEREQ', '');
        is_searchByRequisitionValue = 1
      } else {
        getremovedVal = debouncedSearchVal
      }
      const filterObj = {
        currentJobStatus: workingStatus,
        startDate: startDate,
        endDate: endDate,
        searchString: getremovedVal,
        is_searchByRequisition: is_searchByRequisitionValue,
        test_type: "",
        page: currentPage,
        pageSize: itemsPerPage,
        ...(user?.userType === "HR" && { eMailId: user?.email }),
        ...(changehrtype && { hrType: changehrtype })
      };

      try {
        const refreshedToken = await currentUserVal?.getIdToken(true);
        const data = JSON.stringify(filterObj);
        const headers = {
          'Authorization': `Bearer ${refreshedToken}`,
          'Content-Type': 'application/json'
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_API}/v3.0/interviews/get-list`,
          data,
          { headers }
        );

        let userDatares = response.data;
        console.log("Fetch data *********");
        console.log(userDatares);
        let dataVal = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
        setCandidate(dataVal);
        setTotalPages(userDatares?.totalPages);
        setItemsPerPage(userDatares?.pageSize);
        //setCurrentPage(userDatares?.currentPage);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching list:", error);
        setError("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };
    isStartDateToday();
    fetchDashboardBlock();
    fetchList();
    overallData()
  }, [currentUserVal, workingStatus, debouncedSearchVal, changehrtype, startDate, endDate, currentPage, user]);


  //On Work Status Change
  const workStatusChange = (event) => {
    setCurrentPage(1);
    setError(null);
    setWorkingStatus(event.target.value);
  }


  const HRstatuschange = (e) => {
    setCurrentPage(1);
    setError(null);
    setChangeHRtype(e.target.value);
  }


  const handleStartDateChange = (event) => {
    setCurrentPage(1);
    setError(null);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setError(null);
    setCurrentPage(1);
    if (!startDate && event.target.value != "") {
      setError("Please select start Date before end Date.");
      setDateVal(false);
      return;
    }
    if (startDate > event.target.value && event.target.value != "") {
      setError("End Date cannot be less than the start Date.");
      setDateVal(false);
      return;
    }
    setEndDate(event.target.value);
    setDateVal(event.target.value != "" ? true : false);
  };

  // const searchFunction = (event) => {
  //   setCurrentPage(1);
  //   let searchText = event.target.value;
  //   setSearchVal(searchText);
  // }

  const searchFunction = (event) => {
    setCurrentPage(1);
    let searchText = event.target.value;
    const getremovedVal = searchText.replace('VEREQ', '');
    // setSearchVal(getremovedVal);
    setSearchVal(searchText);
    setSearchValdata(searchText)
  }


  const InterviewerDetails = (info) => {
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }

    let sendInfo = {
      "userId": info?.userId,
      "email": info?.userEmail,
      "displayName": info?.displayName,
      "login_token": token,
      "currentCompany": info?.currentCompany,
      "totalExp": info?.totalExp,
      "jobStatus": info?.jobStatus,
      "contactNo": info?.contactNo
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();

    navigate(`/total-test-list?query=${encodeURIComponent(encryptedQuery)}`);
    console.log("Interview page*********");
    console.log(info);
  }

  const clickBox = (data) => {
    navigate(`/${data}`);
    // console.log(data)
  }

  const handleImageError = () => {
    setImgError('');
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // pagination
  // Reset date filters function
  const resetDateFilters = () => {
    setStartDate('');
    setEndDate('');
  };

  // truncatedText
  const TruncatedText = ({ text }) => {
    const maxLength = 50;
    return (
      <>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </>
    );
  };
  // truncatedText

  return (
    <div className='interviewList-page dashboard-home py-3'>
      <div className="container-fluid">

        <div className='filter-sec mb-2'>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            <div className="filter-label fw-medium pointer"></div>

            {/* {candidateList?.length > 0 ?
              <ExportToExcel data={candidateList} filename="my_data" /> :
              null
            } */}
          </div>
          <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
            <div className="searchfield-wrapper flex-fill">
              <input type="search" className="form-control rounded-2" id="search" value={searchValData} placeholder="Search Here..." onChange={searchFunction} />
              <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
            </div>
            {user?.userType == 'SSA' ?
              <div className="Result-filter flex-fill">
                <select className="form-select" aria-label="Default select example" value={values.getHRtype} onChange={HRstatuschange}>
                  <option value="">Select HR</option>
                  <option value="1">Inhouse</option>
                  <option value="2">Outsourced</option>
                </select>
              </div> : ''}

            <div className="status-filter flex-fill">
              <select className="form-select" value={workingStatus} aria-label="Default select example" onChange={workStatusChange} required>
                <option value="">Working Status</option>
                <option value="In Job">In Job</option>
                <option value="On Notice Period">On Notice Period</option>
                <option value="No Where Working">No Where Working</option>
                <option value="Fresher">Fresher</option>
              </select>
            </div>

            <div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3">
              <input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={handleStartDateChange} required />
              <input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={handleEndDateChange} required />
              <button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => resetDateFilters()}>Reset</button>
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>
        {/* boxes */}
        <div className='info-card-sec mb-3'>
          <div className='row g-2 g-md-3'>
            <div className='col-md-4 col-sm-6'>
              <div className='bg-primary bg-opacity-25 border-bottom border-4 border-primary rounded-3 pointer h-100 p-3 p-xl-4' onClick={() => clickBox('referred-candidate')}>
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <div className='card-details'>
                    <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.inhouseHrCount}</div>
                    <div className="label-title small text-muted lh-sm">{isStartDayToday ? "Today's Interviews (Scheduled by Internal HR)" : 'Total Interviews (Scheduled by Internal HR)'}</div>
                  </div>
                  <div className='card-iconn text-primary lh-1'>
                    <i className="bi bi-people-fill fs-1"></i>
                  </div>
                </div>
              </div>
            </div>
            {user?.userType == 'SA' || user?.userType == 'SSA' ?
              <div className='col-md-4 col-sm-6'>
                <div className='bg-warning bg-opacity-25 border-bottom border-4 border-warning rounded-3 pointer h-100 p-3 p-xl-4' onClick={() => clickBox('external-candidate')}>
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className='card-details'>
                      <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.outsourcedHrCount}</div>
                      <div className="label-title small text-muted lh-sm">{isStartDayToday ? "Today's Interviews (Scheduled by External HR)" : 'Total Interviews (Scheduled by External HR)'}</div>
                    </div>
                    <div className='card-iconn text-warning lh-1'>
                      <i className="bi bi-person-fill-up fs-1"></i>
                    </div>
                  </div>
                </div>
              </div> : null}
            <div className='col-md-4 col-sm-6'>
              <div className='bg-info bg-opacity-25 border-bottom border-4 border-info rounded-3 pointer h-100 p-3 p-xl-4' onClick={() => clickBox('unique-candidate')}>
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <div className='card-details'>
                    <div className="label-value fs-3 fw-bold lh-1 mb-1">{dashboardData?.inhouseHrCount + dashboardData?.outsourcedHrCount}</div>
                    <div className="label-title small text-muted lh-sm">{isStartDayToday ? "Total Today's Interviews" : 'Total Interviews'}</div>
                  </div>
                  <div className='card-iconn text-info lh-1'>
                    <i className="bi bi-person-fill-check fs-1"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* boxes */}


        <div className='candidates-wrapper'>
          {/* When loader visible */}
          {isLoading ? (
            <Loader />
          ) : ''}

          <div className='candidate-list'>
            {candidateList.map((info, id) => {
              const originalMoment = moment(info?.testStarttime).utc();
              const formattedDate = originalMoment.format('YYYY-MM-DD HH:mm:ss');

              let profilePic = "";
              let startMoment = moment(info?.testStarttime).utc();
              let endMoment = moment(info?.testEndtime).utc();
              let duration = moment.duration(endMoment.diff(startMoment));
              let testStartDatetime = startMoment.format("YYYY-MM-DD HH:mm:ss");
              profilePic = info?.profileImg;

              return (
                <div className={`workingStatusCard card small rounded pointer overflow-auto mt-2 ${info.jobStatus === 'In Job' ? 'in-job' : 'no-status' && info.jobStatus === 'Currently On Notice Period' ? 'on-notice-period' : 'no-status' && info.jobStatus === 'No Where Working' ? 'no-where-working' : 'no-status'}`} key={id}>
                  {/* <div className="info-header fs-6 text-white d-flex flex-wrap justify-content-between column-gap-2 text-capitalize text-end px-3 py-2">
                    <div className="workinStatuslabel text-black">Working Status</div>
                    <div className="workinStatus text-black fw-medium">{info.jobStatus}</div>
                  </div> */}
                  {/* Newadded */}
                  <div class='info-header d-flex flex-wrap justify-content-between column-gap-3 text-capitalize px-3 py-2'>
                    <div className="candidate-info d-flex gap-2">
                      <div className="workinStatuslabel text-muted fw-medium">Working Status -</div>
                      <div className="workinStatus text-black fw-semibold">{info.jobStatus}</div>
                    </div>
                    {info?.requisitionDetails?.jobTitle ?
                      <div className="candidate-info d-flex gap-1 text-end">
                        <div className="info-label text-muted fw-medium">Requisition Name -</div>
                        <div className="cand-requisition-id text-black fw-semibold text-capitalize">
                          <TruncatedText text={info?.requisitionDetails?.jobTitle} />
                        </div>
                      </div>
                      : ""}
                    <div className="candidate-info d-flex gap-2 text-end">
                      <div className="info-label text-muted fw-medium">Requisition Id -</div>
                      <div className="cand-requisition-id text-black fw-semibold text-capitalize">VEREQ{info.requisition_id}</div>
                    </div>
                  </div>
                  {/* Newadded */}
                  <div className="info-cards overflow-auto p-3">
                    <div className="row g-2">
                      <div className="col-sm-6 col-md-5 col-xl-2 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="img-wrapper mb-2 mb-xl-0">
                            {Imgerror}
                            <img src={profilePic} referrerPolicy="no-referrer" onLoad={handleImageError} alt="Candidate" className="img-fluid w-100 object-fit-cover rounded-4" style={{ maxWidth: '140px' }} />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-7 col-xl-2 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="cand-name fw-medium text-truncate text-capitalize">{info.displayName}</div>
                          {info.userEmail && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info.userEmail}</div>}
                          {info.contactNo && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill text-body"></i> {info.contactNo}</div>}
                        </div>

                        <div className="info-box">
                          <div className="info-label fw-medium text-body">Current Company</div>
                          <div className="info-value text-muted text-capitalize">{info.currentCompany}</div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-5 col-xl-3 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="info-label fw-medium text-body">Experience</div>
                          <div className="info-value text-muted text-capitalize">{info.totalExp} Year</div>
                        </div>

                        <div className="info-box">
                          <div className="info-label fw-medium text-body">Who Schedule the Interview</div>
                          <div className="info-value text-muted text-capitalize">{info.refered_by_email}</div>
                        </div>

                        <div className="info-box">
                          <div className="info-label fw-medium text-body">Original Interview Schedule Time</div>
                          <div className="info-value text-muted text-capitalize">{formattedDate}</div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-5 col-xl-3 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="info-label fw-medium text-body">Total Rounds of Interviews Scheduled</div>
                          <div className="info-value text-muted text-capitalize">{info.interview_schedule_count} Rounds</div>
                        </div>
                        <div className="info-box action-wrapper">
                          <div className="info-label fw-medium text-body">Total Rounds Attempted by Candidate</div>
                          <div className="info-value text-muted text-capitalize">{info.interview_count} Rounds</div>
                        </div>
                        <div className="info-box action-wrapper">
                          <div className="info-label fw-medium text-body">Any Pending Rounds</div>
                          <div className="info-value text-muted text-capitalize">{info.interview_pending_count} Rounds</div>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2 item-wrapper">
                        <div className='action-wrapper d-md-flex align-items-md-end justify-content-md-end gap-2 h-100 mt-2 mt-md-0'>
                          <button type='button' className='btn btn-light text-nowrap border px-3' onClick={() => InterviewerDetails(info)}>View Details</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )}
          </div>
          {/* new pagination */}
          {candidateList?.length >= 1 ?
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            : ''}
          {/* new pagination */}

          {/* No data available */}
          {candidateList?.length == 0 ? (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
              <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
              </div>
            </div>) : null
          }
        </div>
      </div>
    </div >

  )
}
