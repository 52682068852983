import axios from 'axios';

import { UserAuth } from '../context/AuthContext';
import { getAuth } from 'firebase/auth';
export const callAxios = async (url, data, reqType) => {
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const auth = getAuth();
  const refreshedToken = await currentUserVal?.getIdToken(true);
  const headers = {
    'authorization': `Bearer ${refreshedToken}`
  }
  if (reqType === 'post') {
    const postData = await axios.post(url, data, { headers: headers })
      // const postData = await axios.post( url, data)
      .catch((error) => {
        // console.log(error);
      });
    return postData;
  }

  if (reqType === 'get') {

    const getData = await axios.get(url, { headers: headers })
      .catch((error) => {
        // console.log(error);
      });
    return getData;
  }

}
export const callAxiosFile = async (url, data) => {
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const auth = getAuth();
  const refreshedToken = await currentUserVal?.getIdToken(true);
  const headers = {
    'Authorization': `Bearer ${refreshedToken}`,
    'Content-Type': 'multipart/form-data'
  }

  const postData = await axios.post(url, data, { headers: headers })

    .catch((error) => {
      console.log(error);
    });
  return postData;

}