import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class RegisteredCandidateChart extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    if(chart._super) {
      chart.dispose();
    }
    this.initChart();
    
  }
  
  initChart(){
    // console.log(this.props.data)
    am4core.useTheme(am4themes_animated);
   
    chart = am4core.create("RegisteredCandidateChart", am4charts.XYChart);
    
   
    // Add data
   
    //// console.log("chart dAta:",fullData);
    let fullData= [];
    this.props.data.forEach((item)=>{
        if(item.candidate_count > 0){
          const stuList = item?.candidate_list!=null?item.candidate_list:''
          fullData.push({name:item.name, number:item.candidate_count, studentName:stuList})
        }  
    })
    // console.log(fullData)
    chart.data = fullData;
    
    // chart.data = this.state.data
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.labels.template.fontSize = 14;
    
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 0;
    valueAxis.renderer.labels.template.fontSize = 14;
    valueAxis.integersOnly = true;
    valueAxis.maxPrecision = 0;
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "number";
    series.dataFields.categoryX = "name";
    series.dataFields.categoryY = "studentName";
    series.tooltipText = "[{categoryX}: bold]{categoryX} \n Number Of Candidates: {valueY} \n Candidate Names: {categoryY}[/]";
    // series.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY}):({categoryY})[/]";
    // series.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY})[/]";
    series.columns.template.strokeWidth = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

   
    series.columns.template.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return (
    <>
      <h5 className="text-center">Applicant Self-Registered on NM-Interview Following HR Engagement</h5>
      <h6 className="fw-normal text-muted text-center mb-3">Total Candidates: {this.props.totalcount}</h6>
      <div id="RegisteredCandidateChart" style={{ width: "100%", height: "400px", margin: "0 auto" }} />
    </>);
  }
}

export default RegisteredCandidateChart;
