import React, {useEffect,useState} from "react";
import { Link, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Rating } from "react-simple-star-rating"
import { UserAuth } from '../context/AuthContext';
import { getAuth } from '../firebaseConfig';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import ExportToExcel from '../components/ExportToExcel';

export const RequisitionCandidateDetails = () => {
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [loading, setIsLoading] = useState(false);
    const [candidateData, setCandidateDetails] = useState([])
    const [userData, setUsers] = useState([]);

    const auth = getAuth();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('query');
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));      

    const candidateDetails = async() => {
        const requisitionEmail = decryptedData
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                filterBy: "byEmail",
                data: requisitionEmail
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/interview-schecule-search`, postData, { headers })
            // console.log("My Requisition count");
            // console.log(response.data);
            const ReqCount = response.data?.interviewScheculeData;
            setCandidateDetails(response.data?ReqCount:[]);
            setUsers(response.data?[ReqCount]:[])
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
            setCandidateDetails([]);
        }
    }

    useEffect(()=>{
        if(auth)
        candidateDetails();
    },[auth])

    const convertDate = (dateValue) => {
        let date2=new Date(dateValue).toLocaleDateString()
        return date2
    }

    return (
        <div className="requisition-candidate-details py-3">
            <div className="container-fluid">
                {/* Details Section */}
                <div className="row g-3 g-xxl-4">
                    <div className="col-md-5 col-xl-4 col-xxl-3">
                        <div className="left-section card border-0 p-3">
                            <div className="main-info text-center mt-2">
                                <div className="profile-img mb-2">
                                    <img src={require("../assets/dummy-user.jpeg")} alt="Candidate Profile" className="img-fluid rounded-pill" style={{ width: "100px", width: "100px" }} />
                                </div>
                                <div className="candidate-name fs-18 fw-semibold text-capitalize mb-1">{candidateData.user_name?candidateData.user_name:''}</div>
                                <div className="applied-date small text-muted mb-2">Applied on 10/12/2023</div>
                                <div className="social-wrapper">
                                    <ul className="list-group list-group-horizontal list-inline justify-content-center gap-2">
                                        <li className="">
                                            <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-envelope fs-18"></i></a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-calendar2"></i></a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-link d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-telephone"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rating small border-top border-bottom mx-n3 px-3 py-2 mt-4">
                                <div className="text-muted fw-medium">HR Rating</div>
                                <Rating size={28} tooltipClassName="bg-success text-light small fw-medium rounded-pill lh-1 ms-2 py-1" className="my-1"
                                    initialValue={3.5}
                                    allowFraction
                                    showTooltip
                                    readonly
                                    fillColorArray={["#f14f45", "#f16c45", "#f18845", "#f1b345", "#f1d045"]}
                                />
                            </div>
                            <div className="applied-jobs small py-3">
                                <div className="text-muted fw-medium mb-1">Applied Jobs</div>
                                <div className="bg-secondary bg-opacity-10 rounded-3 p-3">
                                    <div className="text-dark fw-medium">Senior Product Designer</div>
                                    <div className="d-flex align-items-center text-muted text-capitalize">
                                        <span>Full Time</span><span className="add-dot"><i className="bi bi-dot"></i></span>
                                        <span>Mumbai</span><span className="add-dot"><i className="bi bi-dot"></i></span>
                                        <span>India</span>
                                    </div>
                                </div>
                            </div>

                            <hr className="divider mx-n3 my-0 border-secondary border-opacity-75" />

                            <div className="contact-detail py-3">
                                <div className="fw-semibold mb-1">Contact Details</div>
                                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                                    <div className="icon">
                                        <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-envelope-fill"></i>
                                        </div>
                                    </div>
                                    <div className="info small overflow-hidden">
                                        <div className="label-title text-secondary">Email</div>
                                        <div className="label-value text-truncate">{candidateData.email?candidateData.email:''}</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                                    <div className="icon">
                                        <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-telephone-fill"></i>
                                        </div>
                                    </div>
                                    <div className="info small overflow-hidden">
                                        <div className="label-title text-secondary">Phone</div>
                                        <div className="label-value text-truncate">{candidateData.contact_no?candidateData.contact_no:''}</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                                    <div className="icon">
                                        <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-geo-alt-fill"></i>
                                        </div>
                                    </div>
                                    <div className="info small overflow-hidden">
                                        <div className="label-title text-secondary">Address</div>
                                        <div className="label-value text-truncate">KOLKATA</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 mt-2 pt-1">
                                    <div className="icon">
                                        <div className="icon d-flex align-items-center justify-content-center bg-secondary text-secondary bg-opacity-10 rounded-pill" style={{ height: "40px", width: "40px" }}><i className="bi bi-skype"></i>
                                        </div>
                                    </div>
                                    <div className="info small overflow-hidden">
                                        <div className="label-title text-secondary">Skype</div>
                                        <div className="label-value text-truncate">@ahmad786</div>
                                    </div>
                                </div>
                            </div>

                            <hr className="divider mx-n3 my-0 border-secondary border-opacity-75" />
                            
                            <div className="contact-detail py-3">
                                <div className="fw-semibold mb-1">Resume</div>
                                <div className="d-flex flex-wrap align-items-center gap-2 mt-2 pt-1">
                                    <span className="resume-file"><i className="bi bi-file-earmark-pdf display-1"></i></span>
                                    {userData?.length > 0 ?
                                        <ExportToExcel data={userData} filename="candidate_data" /> :
                                        null
                                    }
                                    {/* <Link to={require("../assets/Avatar.png")} type="button" className="btn btn-sm btn-success" download>Download Resume <i className="bi bi-arrow-down"></i></Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-xl-8 col-xxl-9">
                        <div className="right-section card border-0 p-3">
                            <div className="personal-info">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-file-text-fill text-muted"></i> Personal Details</div>
                                <div className="row g-3">
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Full Name</div>
                                        <div className="label-value text-capitalize">{candidateData.user_name?candidateData.user_name:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Current Company</div>
                                        <div className="label-value text-capitalize">{candidateData.current_company?candidateData.current_company:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Job Status</div>
                                        <div className="label-value text-capitalize">{candidateData.job_status?candidateData.job_status:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Current CTC</div>
                                        <div className="label-value text-capitalize">{candidateData.current_ctc?candidateData.current_ctc:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Expected CTC</div>
                                        <div className="label-value text-capitalize">{candidateData.expected_ctc?candidateData.expected_ctc:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Notice Period</div>
                                        <div className="label-value text-capitalize">{candidateData.notice_period?candidateData.notice_period:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Job Role</div>
                                        <div className="label-value text-capitalize">{candidateData.is_job_role_aware?candidateData.is_job_role_aware:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Date of Birth</div>
                                        <div className="label-value text-capitalize">Feb 18, 1996 <span className="text-secondary">(28 yrs old)</span></div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Possibility of Notice BuyOut</div>
                                        <div className="label-value text-capitalize">{candidateData.can_notice_buyout?candidateData.can_notice_buyout:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Possibility of Salary Negotiation</div>
                                        <div className="label-value text-capitalize">{candidateData.is_salary_negotiation?candidateData.is_salary_negotiation:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Interview Date</div>
                                        <div className="label-value text-capitalize">{candidateData.interview_date? convertDate(candidateData.interview_date):''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Interview Time</div>
                                        <div className="label-value text-capitalize">{candidateData.interview_time?candidateData.interview_time:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Interview Medium</div>
                                        <div className="label-value text-capitalize">{candidateData.interview_medium?candidateData.interview_medium:''}</div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Communication Skills</div>
                                        <div className="label-value text-capitalize">
                                            <Rating size={22} tooltipClassName="bg-secondary text-light fs-12 rounded-pill lh-1 ms-2 py-1" className="my-1"
                                                initialValue={candidateData.comm_skill_rating?candidateData.comm_skill_rating:''}
                                                allowFraction
                                                showTooltip
                                                readonly
                                                fillColorArray={["#f14f45", "#f16c45", "#f18845", "#f1b345", "#f1d045"]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-4 col-xxl-3">
                                        <div className="label-title text-secondary">Cultural Fit</div>
                                        <div className="label-value text-capitalize">
                                            <Rating size={22} tooltipClassName="bg-secondary text-light fs-12 rounded-pill lh-1 ms-2 py-1" className="my-1"
                                                initialValue={candidateData.cultural_fit_rating?candidateData.cultural_fit_rating:''}
                                                allowFraction
                                                showTooltip
                                                readonly
                                                fillColorArray={["#f14f45", "#f16c45", "#f18845", "#f1b345", "#f1d045"]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="label-title text-secondary">Reason for Cultural Fit</div>
                                        <div className="label-value">
                                            {candidateData.cultural_fit?candidateData.cultural_fit:''}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="label-title text-secondary">Reason For Job Change</div>
                                        <div className="label-value">
                                            {candidateData.reason_job_change?candidateData.reason_job_change:''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="divider border-secondary border-opacity-75" />

                            <div className="description-info">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-question-circle-fill text-muted"></i> Candidate Questions</div>
                                <div className="label-value text-muted">
                                    {candidateData.candidate_query?candidateData.candidate_query:''}
                                </div>
                            </div>

                            <hr className="divider border-secondary border-opacity-75" />

                            <div className="total-assigned-hr">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-person-vcard-fill text-muted"></i> HR Information</div>
                                <div className="hr-profile-wrapper d-flex align-items-center gap-2 mb-2 pb-xxl-1">
                                    <div className="hr-profiles d-flex">
                                        <img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" />
                                        <img src={require('../assets/Avatar.png')} alt="HR Profile" className="img-fluid border rounded-circle" />
                                        <img src={require('../assets/dummy-user.jpeg')} alt="HR Profile" className="img-fluid border rounded-circle" />
                                    </div>
                                    <div className="total-hr text-primary"><span className="total-hr">10+</span> HR working on this job</div>
                                </div>
                                <div className="d-flex flex-wrap gap-1 assigned-hrs text-muted">
                                    <span>Vasheem Ahmad</span>
                                    <span>Abishek Kumar</span>
                                    <span>DS Tripathi</span>
                                    <span>Varun Kumar</span>
                                    <span>Liam Livingston</span>
                                    <span>Vasheem Ahmad</span>
                                    <span>Abishek Kumar</span>
                                    <span>DS Tripathi</span>
                                    <span>Varun Kumar</span>
                                    <span>Liam Livingston</span>
                                </div>
                            </div>

                            <hr className="divider border-secondary border-opacity-75" />

                            <div className="skills-info">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-gear-fill text-muted"></i> Skills</div>
                                <div className="d-flex flex-wrap gap-2">
                                    <span className="badge rounded-pill text-bg-secondary">HTML</span>
                                    <span className="badge rounded-pill text-bg-secondary">CSS</span>
                                    <span className="badge rounded-pill text-bg-secondary">Javascript</span>
                                    <span className="badge rounded-pill text-bg-secondary">Jquery</span>
                                    <span className="badge rounded-pill text-bg-secondary">Bootstrap</span>
                                    <span className="badge rounded-pill text-bg-secondary">Figma</span>
                                    <span className="badge rounded-pill text-bg-secondary">React</span>
                                    <span className="badge rounded-pill text-bg-secondary">Angular</span>
                                    <span className="badge rounded-pill text-bg-secondary">Wordpress</span>
                                    <span className="badge rounded-pill text-bg-secondary">PHP</span>
                                </div>
                            </div>

                            <hr className="divider border-secondary border-opacity-75" />

                            <div className="experiences-info">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-briefcase-fill text-muted"></i> Experiences</div>
                                <div className="job-profile text-capitalize">Senior Executive Multimedia (Designated Role as Web Designer)</div>
                                <div className="working-since text-secondary"><span>April 2019 - June 2021</span> <span>(2 years 3 months)</span></div>
                            </div>

                            <hr className="divider border-secondary border-opacity-75" />

                            <div className="education-info">
                                <div className="fs-6 fw-semibold mb-2"><i className="bi bi-mortarboard-fill text-muted"></i> Education</div>
                                <div className="job-profile text-capitalize">Senior Executive Multimedia (Designated Role as Web Designer)</div>
                                <div className="working-since text-muted">2012 - 2016</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
