import React, { createContext, useState } from 'react'
const MyContext = createContext();

export const MyProvider = ({children}) => {
    const [values, setValues] = useState({})

    const handleChange = (name, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }))
    }

    return(
        <MyContext.Provider value={{ values, handleChange }}>
            {children}
        </MyContext.Provider>
    )
}

export const useMyContext = () => React.useContext(MyContext)