import {React, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SecondConsentModel from './SecondConsentModel';
import consentImage from '../assets/consentSteps.jpg'
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';

const LoadConsentModal = ({ isOpen, isHeader, isBody, pageNumber }) => {
    const [show, setShow] = useState(true);
    const [issecConsentModalOpen, setsecConsentModalOpen] = useState(false);
    const [headername, setHeadername] = useState('');
    const [bodyData, setBodyData] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [isLoading, setIsLoading] = useState(false);

    // test
    const [isRememberModal, setIsRememberModal] = useState(false);  
    const closeRememberModal = () => setIsRememberModal(false);
    const openRememberModal = () => setIsRememberModal(true);
    // test

    // btnDisabled
    useEffect(()=>{
      console.log('scroll')
      console.log(window.scrollY)
      const scrollPage = () => {
        if(window.scrollY>100){
          console.log(window.scrollY)
        }
        window.addEventListener('scroll', scrollPage)
      }
    },[])
    // btnDisabled

    if(pageNumber!=1){
    }
    const handleClose = async(getPagenumber) => 
    {
        let incpagenumber = getPagenumber+1
        try {
          const refreshedToken = await currentUserVal?.getIdToken(true);
          const user_id  = user?.userId
          const postData = {
            "user_id":user_id,
            "consent_id":getPagenumber
          }
          var headers = {
              // 'Authorization': `Bearer ${refreshedToken}`,
              // 'Content-Type': 'application/json'
          };
          const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/consent/add-hr-concern`, postData, { headers })
          setShow(false)
      } catch (error) {
          console.error('Error fetching requisitions:', error);
          setIsLoading(false);
      }
    }
    if (!isOpen) {
        return null;
    }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" size='lg' keyboard={false} centered scrollable contentClassName='border border-4 border-primary overflow-visible'>
        <Modal.Body className="text-center rounded p-2">
            <div className="img-wrapper">
            <img src={require(`../${isBody}`)} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-secondary border-opacity-75 rounded-1" />
              {/* <img src='../../assets/images/point-to-remember.png' alt='Point To Remember' className='img-fluid' /> */}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>handleClose(currentPage)}>Agree</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoadConsentModal;