import React from 'react';
import * as XLSX from 'xlsx';
function ExportToExcel({ data, filename, designType }) {
    const handleExportClick = () => {
        // Create a new Excel workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
    
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        // Generate the Excel file as an ArrayBuffer
        const excelArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        // Convert the ArrayBuffer to a Blob
        const blob = new Blob([excelArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
    
        // Create an anchor element for downloading
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.xlsx`;
    
        // Trigger a click event on the anchor element to start the download
        document.body.appendChild(a);
        a.click();
    
        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      };

  return (
    <>
    {designType == "onlyButton" 
    ?
    <button type="button" title="Export Requisition Data" class="px-3 focus-ring focus-ring-success btn btn-success" onClick={handleExportClick}><i className="bi bi-cloud-download text-white"></i></button>
    :<button onClick={handleExportClick} type='button' className='btn btn-sm btn-primary focus-ring focus-ring-primary rounded-pill text-nowrap'><i className="bi bi-cloud-download me-1"></i>Export Data</button>}
    
    </>
  );
}

export default ExportToExcel;
