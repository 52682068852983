import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { json, Link, useNavigate } from 'react-router-dom';
import { getAuth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import '../common/custom.css'
import { Loader } from '../components/loader';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useMyContext } from '../context/MyContext';
import CryptoJS from 'crypto-js';
import { useNavigationContext } from "../context/NavContext";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import searchIcon from '../assets/search.svg'
import moment from 'moment';
// pagination
import Pagination from '../components/Pagination';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// pagination

export const VersionList = () => {
	const { activepage, handlePageClick } = useNavigationContext()
	// stages
	const navigate = useNavigate();
	const auth = getAuth();
	const { user, userInfoLoaded, currentUserVal } = UserAuth();
	const { values, handleChange } = useMyContext()
	console.log("****************Values************");
	console.log(values);
	const [selectedItem, setSelectedItem] = useState(0);
	const [items, setItems] = useState([]);
	const [itemsview, setItemsView] = useState([]);
	const [userData, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingdata, setIsLoadingData] = useState(true);
	const [midCntr, setMidCntr] = useState(false)
	const [alertshow, setAlertShow] = useState(false)
	const [alertmsg, setAlertMsg] = useState('')
	const [pagedata, setPagedata] = useState('')
	const [countRequisition, setCountRequisition] = useState('')
	// stages
	const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
	const [searchVal, setSearchVal] = useState("");
	// pagination
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const itemsPerPage = 10;
	// DateSelection
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [DateError, setDateError] = useState("");
	// editorHtml
	const [editorHtml, setEditorHtml] = useState('');
	// editorHtml

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchVal(searchVal);
		}, 500); // Adjust debounce delay as needed
		return () => {
			clearTimeout(handler);
		};
	}, [searchVal]);

	const resetDateFilters = () => {
		setStartDate('');
		setEndDate('');
		handleChange('selectStartDate', "");
		handleChange('selectEndDate', "");
	};

	const handleStartDate = async (dateData) => {
		const startDateValue = dateData;
		const endDateValue = endDate;
		if (startDateValue === "") {
			setStartDate("");
			setEndDate("");
			return;
		}
		if (endDateValue && startDateValue > endDateValue) {
			setDateError("Start date cannot be after the end date");
			return;
		}
		else {
			setStartDate(startDateValue);
			setDateError("");
		}
	};
	const handleEndDate = async (dateData) => {
		const endDateValue = dateData;
		const startDateValue = startDate; // Get the current start date value
		if (endDateValue === "") {
			setEndDate("");
			setStartDate("");
			return;
		}
		if (startDateValue && endDateValue < startDateValue) {
			setDateError("End date cannot be before the start date");
			return;
		}
		else {
			setEndDate(endDateValue);
			setDateError("");
		}
	};
	// DateSelection

	// Handle job filter in mobile device
	const optionChange = (e, value2) => {

		console.log(value2);
		setCurrentPage(1);

		// Extract value from the event
		const selectedValue = e.target.value;
		// Update context values
	};

	// modalClose
	const handleClose = () => {
		console.log("handleClose")
	}
	// modalClose

	// pagination
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// version Data
	const [statusType, setStatustype] = useState('')
	const [apptype, setApptype] = useState('')
	const [formData, setFormData] = useState({
		type: '',
		details: '',
		version_code: '',
		inputstatustype: ''
	});
	const [selectedId, setSelectedId] = useState(null);
	const [verlist, setverlist] = useState([])
	const [saveverlist, setSaveverlist] = useState([])
	const [statusModal, setStatusModal] = useState(false)
	const statusShow = () => setStatusModal(true)
	const [show, setShow] = useState(false)
	const closeversionmodal = () => setShow(false);
	const closestatusmodal = () => setStatusModal(false)
	const handleShow = () => {
		resetForm()
		setShow(true);
	}
	const [isEditing, setIsEditing] = useState(false);
	const [isStatus, setIsStatus] = useState(false);
	const [statusVal, setStatusVal] = useState('');
	const [isSetId, setId] = useState("");
	const modules = {
		toolbar: [
			[{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'header': '4' }, { 'header': '5' }, { 'header': '6' }], // Heading options
			[{ 'font': [] }], // Font family
			[{ 'size': ['small', false, 'large', 'huge'] }], // Font size
			[{ 'bold': true }, { 'italic': true }, { 'underline': true }], // Text formatting
			[{ 'color': [] }, { 'background': [] }], // Text color and background color
			[{ 'align': [] }], // Text alignment
			[{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
		],
	};

	const handlehtmlChange = (value) => {
		setEditorHtml(value);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleversionSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		const dataToSend = {
			...formData,
			details: editorHtml,
			userType: user?.userType
		};
		const refreshedToken = await currentUserVal?.getIdToken(true);
		try {
			let response;
			if (isEditing) {
				// update
				dataToSend.id = isSetId
				response = await fetch(`${process.env.REACT_APP_ADMIN_API}/v3.0/appVersion/update`, {
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${refreshedToken}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(dataToSend),
				});
			}
			else if (isStatus) {
				dataToSend.id = isSetId
				dataToSend.current_status = formData.inputstatustype
				response = await fetch(`${process.env.REACT_APP_ADMIN_API}/v3.0/appVersion/change-status`, {
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${refreshedToken}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(dataToSend),
				});
			}
			else {
				// add
				response = await fetch(`${process.env.REACT_APP_ADMIN_API}/v3.0/appVersion/add`, {
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${refreshedToken}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(dataToSend),
				});
			}
			const result = await response.json();
			if (result?.statusCode != 200) {
				toast.error(result?.errorMessage, {
					position: toast.POSITION.BOTTOM_CENTER
				});
			} else {
				if (isStatus) {
					toast.success(`Status has been updated successfully.`, {
						position: toast.POSITION.BOTTOM_CENTER
					});
				} else {
					toast.success(`Data has been ${isEditing ? "updated" : "added"} successfully.`, {
						position: toast.POSITION.BOTTOM_CENTER
					});
				}
			}
			closeversionmodal()
			getversionList()
			setIsLoading(false);
			resetForm()
			setIsEditing(false)
			console.log('Success:', result);
			// Handle success (e.g., show a success message or close the modal)
		} catch (error) {
			setIsLoading(false);
			console.error('Error:', error);
			// Handle error (e.g., show an error message)
		}
	};

	const getversionList = async () => {
		setIsLoading(true);
		const refreshedToken = await currentUserVal?.getIdToken(true);
		const postData = {
			// searchString:2.0,
			type: "",
			fromDate: startDate || '',
			toDate: endDate || '',
			page: currentPage,
			pageSize: itemsPerPage,
		};
		const headers = {
			'Authorization': `Bearer ${refreshedToken}`,
			'Content-Type': 'application/json',
		};
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_ADMIN_API}/v3.0/appVersion/get-list`,
				postData,
				{ headers }
			);
			const userDatares = response.data;
			const versionList = userDatares?.list;
			console.log(versionList)
			console.log("versionList")
			if (versionList.length >= 1) {
				// const info = requisitionsList[0];
				setSelectedId(versionList[0])
				setverlist(versionList)
				setSaveverlist(versionList)
				const index = 0;
				// handleItemClick(info, index);
				setIsLoadingData(false);
				setTotalPages(userDatares?.totalPage);
			} else {
				//setJobstatus(!getValue);
				setMidCntr(true);
				setPagedata('No data to show.');
				setItemsView(null);
				setIsLoadingData(false);
			}
			setItems(versionList);
			const data = userDatares.data?.length > 0 ? userDatares.data : [];
			setUsers(data);
			const totalPages = Math.ceil(data.length / itemsPerPage);
			setTotalPages(totalPages);
			setTotalPages(userDatares?.totalPages);
			setCountRequisition(userDatares?.totalCount);

		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setIsLoadingData(false);
		}
	}

	useEffect(() => {
		getversionList()
	}, [])

	const optionApp = async (event, valuetype) => {
		setIsLoading(true);
		const getValue = event.target.value
		const item = getItemById(getValue, valuetype);
		setSelectedId(item[0])
		setSelectedItem(0)
        setverlist(item);
		setIsLoading(false);
	}

	const getItemById = (values, valuetype) => {
		if(statusType != '' && apptype != ''){
			if(valuetype == 'apptype'){
				setApptype(values)
			}
			if(valuetype == 'statusType'){
				setStatustype(values)
			}
			return saveverlist.filter(item => item.type == values && item.current_status == values);
		}
		if(valuetype == 'apptype'){
			if(values == ''){
				setApptype(values)
				return saveverlist
			}else{
				setApptype(values)
				return saveverlist.filter(item => item.type == values);
			}
		}
        if(valuetype == 'statusType'){
			if(values == ''){
				setStatustype(values)
				return saveverlist
			}else{
				setStatustype(values)
				return saveverlist.filter(item => item.current_status == values);
			}
		}
    };

	// resetForm
	const resetForm = () => {
		setApptype('');
		setEditorHtml('');
		setFormData('')
		// Optionally, reset other form-related states or components here
	};

	const handleItemClick = async (info, index) => {
		setSelectedItem(index)
		setSelectedId(info)
	}

	const updatedata = async (info, type) => {
		console.log(info)
		if (type == 'edit') {
			setIsEditing(true)
			handleShow(true);
		}
		if (type == 'status') {
			setIsStatus(true)
			statusShow(true)
			setStatusVal(info.current_status)
		}
		setIsLoading(false)
		setIsLoadingData(false)
		setApptype(info?.type);
		setEditorHtml(info?.details || '');
		setFormData({
			type: info.type || '',
			version_code: info.version_code || '',
			details: info.details || '',
			inputstatustype: info.current_status || ''
		});
		setId(info?.id)
	}

	const htmlToText = (html) => {
		// Create a new DOM element
		const doc = new DOMParser().parseFromString(html, 'text/html');
		// Extract the text content
		return doc.body.textContent || "";
	};

	const style = {
        selected: {
            backgroundColor: '#9ec5fe',
			borderBottom: '2px solid #0d6efd'
        },
    };
	// version Data

	return (
		<div className='recruitment-list-page py-3'>
			{/* alert */}
			<Alert variant="success" show={alertshow} dismissible className="alertMsg">
				<Alert.Heading>{alertmsg}</Alert.Heading>
			</Alert>
			{/* alert */}

			<div className='container-fluid'>
				<div className='d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between gap-2 mb-2'>
					<div className='left-sec'>
						<div className='fs-4 lh-sm'>Version</div>
					</div>
				</div>

				{/* Filter Section */}
				<div className='filter-sec mb-2'>
					<div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
						<div className="status-filter flex-fill">
							<select className="form-select" aria-label="Default select example" value={apptype} onChange={(event) => optionApp(event, 'apptype')} required>
								<option value="" >App Type</option>
								<option value="admin">Admin</option>
								<option value="web">Web</option>
								<option value="desktop">Desktop</option>
							</select>
						</div>

						<div className="status-filter flex-fill">
							<select className="form-select" aria-label="Default select example" value={statusType} onChange={(event) => optionApp(event, 'statusType')} required>
								<option value="" >Select Status</option>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
							</select>
						</div>

						<div className="date-filter d-flex align-items-center gap-1 bg-white rounded-2 px-3">
							<input type="date" className="form-control border-0 ps-0" id="startDate" value={startDate} onChange={(e) => optionChange(e, 'selectStartDate')} required />
							<input type="date" className="form-control border-0 ps-0" id="endDate" value={endDate} onChange={(e) => optionChange(e, 'selectEndDate')} required />
							<button type='button' className='btn btn-sm btn-primary px-3 lh-1' onClick={() => resetDateFilters()}>Reset</button>
						</div>

						<div className="add-status flex-fill">
							<Button variant="success" className="px-3 focus-ring focus-ring-success" onClick={handleShow}>
								<i className="bi bi-person-plus-fill"></i> Add Details
							</Button>
						</div>

					</div>
					{DateError && <div className="small text-danger text-md-end mt-1">{DateError}</div>}
				</div>
				{/* Filter Section */}

				{/* Cards Listing Section */}
				<div className="recruitment-dtl-wrapper">
					<div className="bg-white rounded-3 overflow-hidden position-relative pb-4" style={{ minHeight: '60vh' }}>
						<>
							<div className="row g-0">
								<div className="col-md-5 col-xl-4 col-xxl-3">
									{/* {isLoading ? <Loader /> : */}
									<div className="version-card-wrapper border-end">
										{verlist?.length > 0 ?
											verlist.map((info, index) => (
												<div className={`version-card job-card pointer p-3`} style={index === selectedItem ? style.selected : {}} key={index} onClick={() => handleItemClick(info, index)}>
													<Button onClick={() => updatedata(info, 'edit')}>Edit</Button>
													<Button onClick={() => updatedata(info, 'status')}>UpdateStatus</Button>
													<div className="version-type fs-18 text-truncate text-capitalize">{info.type}</div>
													<div className="version-code text-truncate text-secondary">({info.version_code})</div>
													<div className="version-posted text-truncate text-secondary">{info.timeAgo}</div>
												</div>
											)) : <p className="fs-6 fw-medium text-danger text-center p-3 ">No Record Found</p>}
									</div>
									{/* } */}
								</div>
								<div className="col-md-7 col-xl-8 col-xxl-9">
									<div className="version-details-wrapper position-relative">
										{isLoadingdata ?
											<Loader /> :
											selectedId ?
												<div className="info-wrapper p-3 px-xxl-5">
													<div className="version-card-highlighter border border-2 rounded-3 p-3">
														<div className="row">
															<div className="col-xxl-9">
																<div className="info-section">
																	<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-2">
																		<div className="versionDetails fs-4 fw-bold text-truncate text-capitalize lh-sm mb-1">Version Details</div>
																		<div>
																			{selectedId?.current_status == 'inactive' ? (<span className="badge bg-danger">Inactive</span>) : selectedId?.current_status == 'active' ? (<span className="badge bg-success">active</span>) : ''}
																		</div>
																	</div>
																	<div className="d-flex flex-wrap mb-2 pb-xxl-1">
																		<div className="version-type add-dot text-capitalize">{selectedId.type ? selectedId.type : ''}</div>
																		<div className="version-code add-dot text-capitalize">Version {selectedId?.version_code}</div>
																		<div className="version-posted add-dot">{selectedId.timeAgo ? selectedId.timeAgo : ''}</div>
																	</div>
																	<div className="col-12">
																		<div className="info_wrapper">
																			<div className="title_name text-secondary">Version Summary</div>
																			<div className="value">
																				<div style={{ textAlign: 'justify' }}>{selectedId.details ? htmlToText(selectedId.details) : ''}</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div> :
												<div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
													<div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
														<img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
														<div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
														<div className='small text-muted'>Whoops... this information is not available for a moment</div>
													</div>
												</div>
										}
									</div>
								</div>
							</div>
							{items?.length >= 1 ?
								<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
								: ''}
						</>
					</div>
				</div>
			</div>

			{/* Version details modal */}
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
				<Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
					<Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Version Details</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-md-4 py-4">
					<form onSubmit={handleversionSubmit}>
						{/* {isLoading == true ? <Loader /> : ""} */}
						<div className="requisitionStatus">
							<label>Select Type</label>
							<select
								className="form-select bg-light py-2"
								name="type"
								onChange={handleInputChange}
								value={formData.type}
							>
								<option value="">Select Type</option>
								<option value="admin">Admin</option>
								<option value="web">Web</option>
								<option value="desktop">Desktop</option>
							</select>
							<div className="my-3" style={{ height: '210px' }}>
								<label>Details</label>
								<ReactQuill
									value={editorHtml}
									onChange={handlehtmlChange}
									theme="snow"
									style={{ height: '150px' }}
								/>
							</div>
							<div className="my-3">
								<label>Version</label>
								<input
									type="text"
									className="form-control"
									onChange={handleInputChange}
									name="version_code"
									value={formData.version_code}
								/>
							</div>
						</div>
						<div className="d-flex py-3 px-2 justify-content-center">
							<Button
								type="button"
								variant="secondary"
								className="focus-ring focus-ring-secondary px-4"
								onClick={closeversionmodal}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="danger"
								className="focus-ring focus-ring-danger px-4 mx-2"
							>
								Submit
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* Version details modal */}

			{/* Status modal */}
			<Modal show={statusModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
				<Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
					<Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Update Status</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-md-4 py-4">
					<form onSubmit={handleversionSubmit}>
						{isLoading ? <Loader /> : ""}
						<div className="requisitionStatus">
							<label>Select Status</label>
							<select
								className="form-select bg-light py-2"
								name="inputstatustype"
								onChange={handleInputChange}
								value={formData.inputstatustype}
							>
								<option value="">Select Status</option>
								<option value="inactive">Inactive</option>
								<option value="active">Active</option>
							</select>
						</div>
						<div className="d-flex py-3 px-2 justify-content-center">
							<Button
								type="button"
								variant="secondary"
								className="focus-ring focus-ring-secondary px-4"
								onClick={closestatusmodal}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="danger"
								className="focus-ring focus-ring-danger px-4 mx-2"
							>
								Submit
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* Status modal */}
			<ToastContainer />
		</div>
	)
}