const InterviewStartedCard = ({startedData}) =>{
    const convertDate = (dateValue) => {
        let date2=new Date(dateValue).toLocaleDateString()
        return date2
    }
    // // console.log(props.otherData)
    // const intTime = parseInt(profileData?.interview_time)> 12 && parseInt(profileData?.interview_time) < 24 ? profileData?.interview_time + " PM" : profileData?.interview_time + " AM";
    return(
        <div>
            <div className="info-cards int-result small rounded pointer overflow-auto mt-2">
                                    <div className="info-header fs-6 text-black text-end px-3 py-2 intStarted">Interview Started</div>
                                    <div className="info-body p-3">
                                        <div className="row g-3 mb-3">
                                            <div className="col-sm-6">
                                                <div className="cand-name text-primary fs-6 fw-medium text-capitalize">{startedData?.display_name}</div>
                                                {/* <div className="cand-role text-muted text-capitalize">{startedData?.job_title}</div> */}
                                                <div className="cand-company text-black text-capitalize">{startedData?.sel_language}</div>
                                            </div>
                                            <div className="col-sm-6 text-xl-end">
                                                {/* <div className="cand-date text-muted text-capitalize">Thu Mar 02 2023 13:31:16</div>
                                                <div className="cand-status fw-medium text-danger text-capitalize">{profileData?.job_status}</div> */}
                                            </div>
                                        </div>
    
                                        <div className="row g-3">
                                            <div className="col-sm-6 col-xl-3">
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Total Experience:</div>
                                                    <div className="label-value text-danger">{startedData?.total_exp} Years</div>
                                                </div>
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Test Start Time:</div>
                                                    <div className="label-value text-danger">{convertDate(startedData?.test_starttime)}</div>
                                                </div>
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                                                    <div className="label-title text-black">Test End Time:</div>
                                                    <div className="label-value text-danger">{startedData?.test_endtime ? convertDate(startedData?.test_endtime) : ""}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3">
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Questions Attempt:</div>
                                                    <div className="label-value text-danger">{startedData?.ques_attempt}</div>
                                                </div>
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                                                    <div className="label-title text-black">Correct:</div>
                                                    <div className="label-value text-danger">{startedData?.right_count}</div>
                                                </div>
                                                <div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
                                                    <div className="label-title text-black">Incorrect:</div>
                                                    <div className="label-value text-danger">{startedData?.wrong_count}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xl-3">
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                                                    <div className="label-title text-black">Test Duration:</div>
                                                    <div className="label-value text-danger">{startedData?.wrong_count}</div>
                                                    {/* {intTime?intTime:''} */}
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-6 col-xl-3">
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Notice Period:</div>
                                                    <div className="label-value text-danger">{otherData?.notice_period}</div>
                                                </div>
                                                <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                                                    <div className="label-title text-black">Key Skills:</div>
                                                    <div className="label-value text-danger">{otherData?.key_skill}</div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div></div>
        )
    }
    
    export default InterviewStartedCard;