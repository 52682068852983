import { useEffect, usestate } from "react"

const Requisitionhistory = ({ reqhistorydata }) => {

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            {reqhistorydata?.map((reqData, index) => (
                <div className='tab-content-wrapper bg-white fs-14 rounded-3 p-3 mb-3'>
                    <div className="row g-3 mb-4">
                        <div className="col-12 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="fs-18 fw-bold text-body text-capitalize text-truncate lh-sm mb-1">{reqData?.jobTitle}</div>
                                <div className="fw-bold text-danger text-uppercase">VEREQ{reqData?.id}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Requested By</div>
                                <div className="text-capitalize">{reqData?.requested_by}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Department</div>
                                <div className="department_name text-capitalize">{reqData?.department}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Minimum Experience</div>
                                <div className="min_exp text-capitalize">{reqData?.minimumExp}</div>
                            </div>

                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Job Location</div>
                                <div className="location text-capitalize">{reqData?.location}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Created By</div>
                                <div className="created_date text-capitalize">{reqData?.approvalDate ? formatDate(reqData?.approvalDate) : ""}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Required By</div>
                                <div className="Required_date text-capitalize">{reqData?.reqiredBy || ""}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Closed On</div>
                                <div className="closed_on text-capitalize">{reqData?.closedDate ? formatDate(reqData?.closedDate) : ""}</div>
                            </div>
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Result</div>
                                <div className={`result-status fw-semibold text-uppercase fw-bold ${reqData?.result == "Pass" || reqData?.result == "pass" ? "text-success" : "text-danger"} text-uppercase`}>{reqData?.result}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Requisitionhistory