import React from 'react'



export const Loader = ({showingText}) => {
    const updatedText = showingText?showingText:"Loading Data...";
    return (

        <div className="loader-wrapper">

            <div className="loader"></div>

            <div className="loader-title">{updatedText}</div>

        </div>

    )

}