import React,{useEffect, useState} from 'react';
import { Route, Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Loader } from '../components/loader';



const Protected = ({ children,requiredRoles}) => {
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  if (!userInfoLoaded) {
    // You might want to display a loading component while user information is being loaded
    return <Loader />;
  }
  const isEmptyObject = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };
  const isUserEmpty = isEmptyObject(user);

  if (!user || isUserEmpty) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/" />;
  }

  if (requiredRoles && user!=null && !requiredRoles.includes(user.userType)) {
    // Redirect to an unauthorized page if the user doesn't have the required roles
    return <Navigate to="/unauthorized" />;
  }

  // Render the original element if the user is authenticated and has the required roles
  return children;
};

export default Protected;


