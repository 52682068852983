import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { getAuth } from "firebase/auth";

// loader
import { Loader } from '../../components/loader';

// react-quill editor
import ReactQuill, { quill } from 'react-quill';
import  "react-quill/dist/quill.snow.css";


// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/util";
import axios from "axios";

export const ReplyListing = () => {
    const navigate = useNavigate();

    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    // // console.log(user)
    const { state } = useLocation();
    const [reply, setReply] = useState("");
    const [replies, setReplies] = useState([]);
    // for issue listing

    const [description, setDescription] = useState("");
   
    const [isLoading, setIsLoading] = useState(true);
    // end issue listing
    const [show, setShow] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [noResults, setNoResults]= useState(true)

    // For Add Candidate Model
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    

    const  modules  = { 
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
            // ["link", "image", "video"],
            ["clean"],
        ],
      };

      const getIssues = async () => {

        // await callAxios(
        //   process.env.REACT_APP_ADMIN_API + "api/v3.0/issues",
        //   {},
        //   "get"
        // ).then((issues) => {
        //   setIssues(issues);
        // });
        const refreshedToken = await currentUserVal?.getIdToken(true);
var headers = {
    'Authorization': `Bearer ${refreshedToken}`,
    'Content-Type': 'application/json'
};
    axios.post(process.env.REACT_APP_ADMIN_API +"/v3.0/issues/get-reply", { issueId: state?.issueId },{headers}).then((replies)=>{
        setReplies(replies);
        setNoResults(false)
        setIsLoading(false)
    })
      };
      if(reply){
        const editorBorderStyle = document.getElementById("editorBorder");
        if(editorBorderStyle)
        editorBorderStyle.style.border = "none"
      }
      useEffect(() => {
        
        //   const getUserRole = async () => {
        //     if (user) {
        //       const q = query(
        //         collection(database, environment.REACT_APP_USERS_DB),
        //         where("email", "==", user?.email),
        //         orderBy("timeStamp","asc"),
        //         limit(1)
        //       );
        //       onSnapshot(q, (querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //           const id = doc.id;
        //           const data = doc.data();
        //           data["id"] = doc.id;
        //           setUserRole(data);
        //           setIsloading(false);
        //         });
        //       });
        //     }
        //   };
        //   getUserRole();
    
         
          getIssues();
        
      }, []);
     
      
    
      const formatAMPM = (date) => {
        //// console.log("LOCAL TIME:",date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
      };
    
    
    
      if(description){
        const descStyle = document.getElementById("issue-description");
        if(descStyle)
        descStyle.style.border = "none";
      }
      const handleFormSubmit = async (e) => {
        e.preventDefault();
       
        if (reply === "") {
          const editorSytle = document.getElementById("editorBorder");
          if(editorSytle)
          editorSytle.style.border = "1px solid red";
          return false;
        } else {
          setIsSaving(true)
        //   document.getElementById("replies-preloader").classList.remove("d-none");
          const formData = {
            issue_id: state?.issueId,
            uid: user?.uid,
            reply: reply,
            name: user?.displayName,
            email: user?.email,
            userType: user?.userType,
          };
          //// console.log(formData)
    
        //   const response = await callAxios(
        //     process.env.REACT_APP_ADMIN_API + "api/v1/issues/save-reply",
        //     formData,
        //     "post"
        //   );
        const refreshedToken = await currentUserVal?.getIdToken(true);
          var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(process.env.REACT_APP_ADMIN_API +"/v3.0/issues/save-reply", formData,{headers});
          if (response?.data?.status === 200) {
            resetForm();
            setIsSaving(false)
            toast.success("Reply Submitted!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            // document.getElementById("replies-preloader").classList.add("d-none");
            toast.error("Network Issue!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      };
    // // console.log(issues)
    const resetForm = async () => {
        setReply("");
        const element = document.getElementsByClassName("ql-editor");
        if(element)
        element[0].innerHTML = "";
        getIssues()
       const resetReplyform =  document.getElementById("ReplyForm");
       if(resetReplyform)
       resetReplyform.reset();
        const coloseReply = document.getElementById("closeReplyForm");
      if(coloseReply)
      coloseReply.click();
        // document.getElementById("replies-preloader").classList.add("d-none");
      };
    
     
    
    
    
      let htmlContent;
  if (replies?.data?.length > 0) {
    htmlContent = replies?.data?.map((item, index) => {
      return (
        <div className="bg-white rounded-3 p-3 mt-2" id="list-container" key={"replylisting--" +index}>
                <div className="description small text-muted mb-2" dangerouslySetInnerHTML={{__html:item?.reply}}></div>
                <div className="issue-date fw-medium small text-primary mb-1">{new Date(item.createdAt).getDate() +
              " " +
              new Date(item.createdAt).toLocaleString("default", {
                month: "long",
              }) +
              " " +
              new Date(item.createdAt).getFullYear() +
              " " +
              formatAMPM(new Date(item.createdAt))}</div>
                <div className="replied-by badge fw-medium rounded-1 text-bg-warning text-capitalize">
                {user?.userType === "SA" || user?.userType === "SSA" ? (
              item.userType === "HR"  ? (
                
                   "Replied By "+item.name
                
              ) : (
                
                  "Replied By Admin"
             
              )
            ) : item.userType == "SA" || item.userType == "SSA" ? (
             
                "Replied By Admin"
              
            ) : (
             
                "Replied By User"
             
            )}
                </div>
              </div>
       
      );
    });
  } else {
    htmlContent = (
      <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
      <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
          <img src={require('../../assets/no-data.jpg')} alt="No data" className="img-fluid" />
          <div className='fs-4 fw-semibold text-danger mt-3'>No replies found yet!</div>
          <div className='small text-muted'>Whoops... this information is not available for a moment</div>
      </div>
  </div>
    );
  }
    return (
        <>
        <ToastContainer />
         <div className="issue-listing-page py-3">
            <div className="container-xl">
                {/* Button trigger modal */}
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-1 pb-1">
                    <h5 className="fw-semibold m-0">Recent Replies -{" "} <span
                className="fw-medium text-capitalize"
                style={{ color: "var(--theme-blue1)" }}
              >
                {state.subject}
              </span></h5>
                    <button type="button" className="btn btn-primary px-3" onClick={handleShow}>
                        <i className="bi bi-plus-circle"></i> Submit Your Reply
                    </button>
                </div>

                <div className="issues-list-wrapper position-relative">
                {isLoading ? (
              <Loader />
            ) : htmlContent}
                   
                </div>
            </div>

            {/* Add Requisition Status Model */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
                <Modal.Header  closeVariant="white" className="bg-primary text-light px-md-4">
                    <Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white"> Submit Your Reply</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-md-4 py-4">
                    <form id="ReplyForm">
                        
                        <div className="mb-3" id="editorBorder">
                            <label for="exampleFormControlTextarea1" className="form-label">Issue Description</label>
                            <ReactQuill   theme="snow" placeholder="Please write reply here..." onChange={setReply} />
                        </div>
                        <div className="d-flex flex-wrap justify-content-end gap-2">
                            <Button variant="secondary"  id="closeReplyForm" className="focus-ring focus-ring-secondary px-3" onClick={handleClose}>Close</Button>
                            <Button variant="primary" className="focus-ring focus-ring-primary px-3" onClick={handleFormSubmit}>Save Reply</Button>
                            {isSaving?
                            <Loader showingText={"Reply is Saving Please Wait..."}/>
                            :null}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

           
        </div>
        </>
       
    )
}
