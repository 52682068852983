import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { getAuth } from "firebase/auth";
import { useNavigationContext } from "../../context/NavContext";
import Pagination from "../../components/Pagination";

// loader
import { Loader } from '../../components/loader';

// react-quill editor
import ReactQuill, { quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/util";
import axios from "axios";

export const IssuesListing = () => {
  console.log("in issue listing")
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('');
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const { activepage, handlePageClick } = useNavigationContext()
  // console.log(user)
  // for issue listing
  const [subject, setSubject] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [issues, setIssues] = useState([]);
  // end issue listing
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);

  const [noResults, setNoResults] = useState(true)

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // pagination

  // For Add Candidate Model
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      // ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleSelectChange = (event) => {
    setSelectedStatus(event.target.value);
    const pageNum = 1
    getIssues(event.target.value, pageNum)
    setCurrentPage(1)
  };

  // useEffect(() => {
  //   getIssues(selectedStatus)
  // },[selectedStatus])

  // NewIntegration
  const getIssues = async (getStatus, pageNum) => {
    const refreshedToken = await currentUserVal?.getIdToken(true);
    const sendData = {
      // "fromDate": "2024-05-01", // optional
      // "toDate": "2024-07-01", // optional getStatus == "" ? selectedStatus : getStatus
      "status": getStatus, // closed, In Progress, open optional
      "page": pageNum == 1 ? pageNum : currentPage,
      "pageSize": itemsPerPage
    }
    const headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/issues/list`, sendData, { headers }).then((issues) => {
      const getallIssues = issues.data.list
      setIssues(getallIssues);
      setTotalPages(issues?.data.totalPage)
      setNoResults(false)
      setIsLoading(false)
    })
  };
  // NewIntegration

  useEffect(() => {
    //   const getUserRole = async () => {
    //     if (user) {
    //       const q = query(
    //         collection(database, environment.REACT_APP_USERS_DB),
    //         where("email", "==", user?.email),
    //         orderBy("timeStamp","asc"),
    //         limit(1)
    //       );
    //       onSnapshot(q, (querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //           const id = doc.id;
    //           const data = doc.data();
    //           data["id"] = doc.id;
    //           setUserRole(data);
    //           setIsloading(false);
    //         });
    //       });
    //     }
    //   };
    //   getUserRole();
    getIssues(selectedStatus);
    handlePageClick('activepage', 'issues-list')
  }, [currentUserVal, currentPage, selectedStatus]);

  const formatAMPM = (date) => {
    //console.log("LOCAL TIME:",date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const checkValidation = (key, val) => {
    if (key === "subject" && val !== "") {
      const subjectStyle = document.getElementById("subject");
      if (subjectStyle)
        subjectStyle.style.border = "none";
    }
    if (key === "priority" && val !== "") {
      const priorityStyle = document.getElementById("priority");
      if (priorityStyle)
        priorityStyle.style.border = "none";

    }
    if (key === "description" && val !== "") {
      const issueDescStyle = document.getElementById("issue-description")
      if (issueDescStyle)
        issueDescStyle.style.border = "none";
    }

    if (key === "changeStatus" && val !== "") {
      const changeStatusStyle = document.getElementById("changeStatus");
      if (changeStatusStyle)
        changeStatusStyle.style.border = "none";
    }
  };

  if (description) {
    const issueDescStyle = document.getElementById("issue-description");
    if (issueDescStyle)
      issueDescStyle.style.border = "none";
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (subject === "") {
      const subjectStyle = document.getElementById("subject");
      if (subjectStyle)
        subjectStyle.style.border = "1px solid red";
      return false;
    } else if (priority === "") {
      const priorityStyle = document.getElementById("priority");
      if (priorityStyle)
        priorityStyle.style.border = "1px solid red";
      return false;
    } else if (description === "") {
      const descStyle = document.getElementById("issue-description");
      if (descStyle)
        descStyle.style.border = "1px solid red";
      return false;
    } else {
      setIsSaving(true)
      const formData = {
        uid: user?.uid,
        subject: subject,
        priority: priority,
        status: "open",
        description: description,
        name: user?.displayName,
        email: user?.email,
        userType: user?.userType,
      };

      const refreshedToken = await currentUserVal?.getIdToken(true);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v3.0/issues", formData, { headers });
      // console.log(response)


      if (response?.data?.status === 200) {
        resetForm();
        setIsSaving(false)
        toast.success("Ticket Submitted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {

        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  // console.log(issues)
  const resetForm = async () => {

    setSubject("");
    setPriority("");
    setDescription("");
    const element = document.getElementsByClassName("ql-editor");
    if (element)
      element[0].innerHTML = "";

    getIssues();
    const formReset = document.getElementById("issueForm");
    if (formReset)
      formReset.reset()

    const cancelForm = document.getElementById("closeIssueForm");
    if (cancelForm)
      cancelForm.click()

  };

  const ReplyListingPage = (issueId, subject) => {
    navigate("/replies-list", {
      state: { issueId: issueId, subject: subject },
    });
  };

  const updateStatus = async (status, id) => {
    setIsSaving(true)
    const formData = {
      status: status,
      id: id,
    };

    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v3.0/issues/update-status", formData, { headers });
    if (response?.data?.status === 200) {
      //   resetForm();
      setIsSaving(false)
      getIssues();
      toast.success("Status updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      //document.getElementById("replies-preloader").classList.add('d-none');
      toast.error("Network Issue!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  let htmlContent;

  // if (issues?.data?.length > 0) {
  if (issues?.length > 0) {
    // htmlContent = issues?.data?.map((item, index) => {
    htmlContent = issues?.map((item, index) => {
      // const isSelected = item.status === "" ?"selected" : null;
      const raisedBy = item.name
        ? "- By: " + item.name + " [ " + item.userType + " ]"
        : null;

      return (
        <div className="bg-white rounded-3 p-3 mt-2" key={index}>
          <div className="d-flex flex-column flex-md-row align-items-start justify-content-between gap-2 mb-2">
            <div className="issue-raised fw-medium">{item?.subject}


              {user?.userType === "SA" || user?.userType === "SSA" ? (
                item.userType === "HR" ? (
                  <span className="badge fw-medium rounded-1 text-bg-warning text-capitalize ms-1">{raisedBy}</span>
                ) : (
                  <span className="badge fw-medium rounded-1 text-bg-warning text-capitalize ms-1">By Admin</span>
                )
              ) : item.userType == "SA" || item.userType == "SSA" ? (
                <span className="badge fw-medium rounded-1 text-bg-warning text-capitalize ms-1">By Admin</span>
              ) : (
                <span className="badge fw-medium rounded-1 text-bg-warning text-capitalize ms-1">By User</span>
              )}

            </div>

            {item?.priority === "normal" ? (
              <div className="issue-priority badge rounded-pill text-bg-secondary fw-medium text-capitalize px-3">{item?.priority}</div>

            ) : null}

            {item?.priority === "medium" ? (
              <div className="issue-priority badge rounded-pill text-bg-warning fw-medium text-capitalize px-3">{item?.priority}</div>
            ) : null}

            {item?.priority === "high" ? (
              <div className="issue-priority badge rounded-pill text-bg-danger fw-medium text-capitalize px-3">{item?.priority}</div>
            ) : null}


          </div>
          <div className="small text-muted mb-2 issueShow" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
          <div className="issue-date fw-medium small text-primary mb-2">{new Date(item.createdAt).getDate() +
            " " +
            new Date(item.createdAt).toLocaleString("default", {
              month: "long",
            }) +
            " " +
            new Date(item.createdAt).getFullYear() +
            " " +
            formatAMPM(new Date(item.createdAt))}</div>
          <div className="d-flex flex-column-reverse flex-md-row align-items-md-center justify-content-between gap-2">
            <div className="btn-wrapper d-flex flex-wrap gap-2">
              {/* <Button variant="danger" size="sm" className="px-3">Closed</Button> */}
              {item?.status === "open" ? (
                <div className="issue-status d-flex flex-column flex-md-row gap-2">
                  <button
                    className="btn btn-sm btn-warning text-capitalize"
                    style={{ minWidth: "100px" }}
                  >
                    {item?.status}
                  </button>
                  <button
                    className="btn btn-sm btn-primary text-capitalize"
                    onClick={() => {
                      ReplyListingPage(item.id, item.subject);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    View Updates
                  </button>
                </div>
              ) : item?.status === "In Progress" ? (
                <div className="issue-status d-flex flex-column flex-md-row gap-2">
                  <button
                    className="btn btn-sm btn-info text-capitalize"
                    style={{ minWidth: "100px" }}
                  >
                    {item?.status}
                  </button>
                  <button
                    className="btn btn-sm btn-primary text-capitalize"
                    onClick={() => {
                      ReplyListingPage(item.id, item.subject);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    View Updates
                  </button>
                </div>
              ) : item?.status === "In Discussion" ? (
                <div className="issue-status d-flex flex-column flex-md-row gap-2">
                  <button
                    className="btn btn-sm btn-secondary text-capitalize"
                    style={{ minWidth: "100px" }}
                  >
                    {item?.status}
                  </button>
                  <button
                    className="btn btn-sm btn-primary text-capitalize"
                    onClick={() => {
                      ReplyListingPage(item.id, item.subject);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    View Updates
                  </button>
                </div>
              ) : (
                <div className="issue-status d-flex flex-column flex-md-row gap-2">
                  <button
                    className="btn btn-sm btn-success text-capitalize"
                    style={{ minWidth: "100px" }}
                  >
                    {item?.status}
                  </button>
                  <button
                    className="btn btn-sm btn-primary text-capitalize"
                    onClick={() => {
                      ReplyListingPage(item.id, item.subject);
                    }}
                    style={{ minWidth: "100px" }}
                  >
                    View Updates
                  </button>
                </div>
              )}
              {user?.userType === "SSA" ? (
                <div className="issue-status d-flex flex-column flex-md-row gap-2">
                  <select
                    value={item.status}
                    id="changeStatus"
                    className="form-control"
                    onChange={(e) => {
                      updateStatus(e.target.value, item.id);
                    }}
                  >
                    <option value="open">Open</option>
                    <option value="In Progress">In Progress</option>
                    <option value="In Discussion">In Discussion</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              ) : null}

            </div>
            <div className="total-updated fw-medium">Total Updates: <span className="fw-bold text-primary">{item.totalUpdates}</span></div>
          </div>
          {item.lastUpdate ? (
            <div className="chat-replies-sec bg-secondary bg-opacity-25 border border-secondary rounded-2 p-3 mt-3 ">
              {" "}
              <div className="label-text text-info mb-2">Latest Update</div>
              <div className="reply-description small" dangerouslySetInnerHTML={{ __html: item?.lastUpdate }}></div>
            </div>
          ) : null}
        </div>

      );
    });
  } else {
    htmlContent = (
      <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
        <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
          <img src={require('../../assets/no-data.jpg')} alt="No data" className="img-fluid" />
          <div className='fs-4 fw-semibold text-danger mt-3'>No issues found yet!</div>
          <div className='small text-muted'>Whoops... this information is not available for a moment</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="issue-listing-page py-3">
        <div className="container-xl">
          {/* Button trigger modal */}
          <div className="container">
            <div className="row align-items-center justify-content-between mb-1 pb-1">
              <div className="col-auto">
                <h5 className="fw-semibold m-0">Recent Issues</h5>
              </div>
              {/* <div className="col-auto">
                <select className="form-select" aria-label="Default select example" value={selectedStatus} onChange={handleSelectChange}>
                  <option value="">Select Status</option>
                  <option value="closed">Closed</option>
                  <option value="In Discussion">In Discussion</option>
                  <option value="In Progress">In Progress</option>
                  <option value="open">Open</option>
                </select>
              </div> */}
              <div className="col-auto">
                <button type="button" className="btn btn-primary px-3" onClick={handleShow}>
                  <i className="bi bi-plus-circle"></i> Raise your issue
                </button>
              </div>
            </div>
          </div>
          <div className="issues-list-wrapper position-relative">
            {isLoading ? (
              <Loader />
            ) : htmlContent}
            {/* {htmlContent} */}


            {/* When no issues list there */}
            {isSaving ?
              <Loader showingText={"Status updating Please Wait..."} />
              : null}
          </div>
        </div>
        {/* pagination */}
        {issues?.length > 0 ?
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          : ''}
        {/* pagination */}
        {/* Add Requisition Status Model */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
          <Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
            <Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Create Ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-md-4 py-4">
            <form id="issueForm">
              <div className="mb-3">
                <label for="subject" className="form-label">Subject</label>

                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  aria-describedby="subjectlHelp"
                  placeholder="Enter your subject"
                  onChange={(e) => {
                    setSubject(e.target.value);
                    checkValidation("subject", e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label for="" className="form-label">Priority</label>
                <select
                  className="form-select"
                  id="priority"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setPriority(e.target.value);
                    checkValidation("priority", e.target.value);
                  }}
                >
                  <option value="">Please Select</option>
                  <option value="normal">Normal</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
              <div className="mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">Issue Description</label>
                <ReactQuill id="issue-description" theme="snow" placeholder="Please enter issue here.." onChange={setDescription} />
              </div>
              <div className="d-flex flex-wrap justify-content-end gap-2">
                <Button variant="secondary" id="closeIssueForm" className="focus-ring focus-ring-secondary px-3" onClick={handleClose}>Close</Button>
                <Button variant="primary" className="focus-ring focus-ring-primary px-3" onClick={handleFormSubmit}>Save Changes</Button>
                {isSaving ?
                  <Loader showingText={"Issue Saving Please Wait..."} />
                  : null}
              </div>
            </form>
          </Modal.Body>
        </Modal>


      </div>
    </>

  )
}