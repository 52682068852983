import React from "react";

const OutputDetails = ({ outputDetails }) => {
  return (
    <div className="metrics-container mt-4 flex flex-col space-y-3">
      <p className="small">
        Status:{" "}
        <span className="fw-semibold">
          {outputDetails?.status?.description}
        </span>
      </p>
    </div>
  );
};

export default OutputDetails;
