import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const HRInterview = ({data}) => {
    const allData = data
    useEffect(() => {
        // Create chart instance
        let chart = am4core.create('HRInterview', am4charts.PieChart);
    
        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Add data
        chart.data = allData

         // added
         var  valueAxis = chart.series.push(new am4charts.PieSeries());
        valueAxis.min = 0;
        valueAxis.max = 100;

         if (!chart.data || chart.data.length === 0){
          const noDataMessagecontainer = chart.chartContainer.createChild(am4core.Container);
          noDataMessagecontainer.align = 'center';
          noDataMessagecontainer.isMeasured = false;
          noDataMessagecontainer.x = am4core.percent(50);
          noDataMessagecontainer.horizontalCenter = 'middle';
          noDataMessagecontainer.y = am4core.percent(50);
          noDataMessagecontainer.verticalCenter = 'middle';
          noDataMessagecontainer.layout = 'vertical';
         
          const messageLabel = noDataMessagecontainer.createChild(am4core.Label);
          messageLabel.text = 'There is no data to show on this chart.';
          messageLabel.textAlign = 'middle';
          messageLabel.maxWidth = 300;
          messageLabel.wrap = true;
        }
        // added
    
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'total_count';
        pieSeries.dataFields.category = 'name';
    
        // Customize slice labels
        pieSeries.labels.template.text = '{total_count}';
        pieSeries.slices.template.tooltipText = "[bold]Interviews[/]: {interview_not_schedule_count} \n \n [bold]Candidates Name[/]: {interview_not_schedule_list}";
        pieSeries.labels.template.disabled = true; // Disable default labels

        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Clean up on unmount
        return () => {
          chart.dispose();
        };
      }, [allData]); // empty dependency array means this effect will only run once after initial render

  return <div id="HRInterview" style={{ width: '100%', height: '500px' }} />;
};

export default HRInterview;