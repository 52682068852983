import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const InterviewWithoutHR = ({data}) => {
    const allData = data
    useEffect(() => {
        // Create chart instance
        let chart = am4core.create('InterviewWithoutHR', am4charts.PieChart);
    
        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Add data
        chart.data = allData
    
        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'canditate_count';
        pieSeries.dataFields.category = 'canditate_count';
    
        // Customize slice labels
        pieSeries.labels.template.text = '{canditate_count}';
        pieSeries.slices.template.tooltipText = "[bold]{name}[/]: {canditate_count}";
        pieSeries.labels.template.disabled = true; // Disable default labels

        // Add theme
        am4core.useTheme(am4themes_animated);
    
        // Clean up on unmount
        return () => {
          chart.dispose();
        };
      }, [allData]); // empty dependency array means this effect will only run once after initial render

  return <div id="InterviewWithoutHR" style={{ width: '100%', height: '500px' }} />;
};

export default InterviewWithoutHR;