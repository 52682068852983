import React,{useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Loader } from '../components/loader';

const Protected = ({ children, page }) => {
  const { user, userType, userInfoLoaded, getLogin } = UserAuth();
  const [reauthenticating, setReauthenticating] = useState(true);

  const dashAccess = ["SSA","SA","HR"];
  const interviewAccess = ["HR","SA","SSA"];
  
  useEffect(() => {
    if (!user && !userInfoLoaded) {
      // User is not authenticated, try reauthentication
      getLogin().then(() => {
        setReauthenticating(false);
      });
    } else {
      setReauthenticating(false);
    }
  }, []);

  if (reauthenticating) {
    // Show a loading indicator while reauthentication is in progress
    return <Loader />;
  }
  if (!user || !userType) {
    // User is still not authenticated after reauthentication, redirect to the login page
    return <Navigate to="/" />;
  }
 if(page == "dashboard" && dashAccess.includes(userType)) 
 {
   return children;
 }
 else if(page == "interview" && interviewAccess.includes(userType)) 
 {
   return children;
 }
 else if(page == "testdetails" && interviewAccess.includes(userType)) 
 {
   return children;
 }
 else
 {
  return <Navigate to="/unauthorized"/>
 }
};

export default Protected;