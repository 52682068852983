import React, { useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios } from '../../utils/util';
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { environment } from '../../environment';

export const ReopenIssueModal = ({ show, close, replyIdClosed, currentItem, getIssues, setShowReopenIssueModal,getCount}) => {
    // console.log(replyIdClosed,currentItem)
    const [reason, setReason] = useState("");
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    

    const handleReopen = async(e) =>{
        e.preventDefault();
        // setIsloading(true)
        
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        const formData = {
            issueId:currentItem.id, 
            replyId:replyIdClosed, 
            uid: user?.uid, //optional
            name: user?.displayName, //optional
            email: user?.email, //optional
            userType: user?.userType, //optional
            reason:reason 
        }
        const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/reopen-issue", formData, { headers });
        // setShowReopenIssueModal(false)
        // console.log(response);
        if (response?.data?.statusCode === 200) {
            
            setShowReopenIssueModal(false)
            getIssues();
            getCount()
            //   resetForm();
            // setIsSaving(false)
            //   getIssues();
            toast.success("Status updated!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            //document.getElementById("replies-preloader").classList.add('d-none');
            toast.error("Network Issue!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    return (
        <Modal show={show} centered onHide={close} size='md' scrollable backdrop="static" keyboard={false} dialogClassName="reopenIssueModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-3">
                    <div className="heading-wrapper text-dark text-center mb-4 pb-2">
                        <div className="fs-3 fw-bold lh-sm mb-4">Reopen the ticket</div>
                        <div className="fs-6 fw-medium">Are you certain you want to reopen this issue?<br/> If yes, please provide the reason here</div>
                    </div>

                    <Form className="user-form-details d-flex flex-column h-100" onSubmit={handleReopen}>
                        <Form.Group className="mb-3" controlId="addSubject">
                            <Form.Control as="textarea" rows={3} className="custom-input fw-medium border shadow-none py-2" placeholder="Write note" autoComplete="off" required onChange={(e)=>{setReason(e.target.value)}}/>
                        </Form.Group>
                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-3">
                            <Button variant="secondary" onClick={close} className='btn-custom px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="success" className='btn-custom px-5 py-2'>Reopen</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
