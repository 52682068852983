import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';
import { useNavigationContext } from "../context/NavContext";
import IssueList from '../assets/notebook-pen.svg'

const Sidebar = (props) => {
    const { activepage, handlePageClick } = useNavigationContext()
    const { user, userInfoLoaded } = UserAuth();
    const [userType, setuserType] = useState("");
    const [activePagedata, setActivepage] = useState('')
    useEffect(() => {
        setuserType(user?.userType)
    }, [user]);

    const closeSidebar = (value) => {
        handlePageClick('activepage', value)
        if (window.innerWidth >= '1024') {
            handlePageClick(value)
        } else {
            handlePageClick(value)
            props.closeOffcanvas()
        }
    }

    return (
        <aside className="sidebar d-flex flex-column flex-shrink-0">
            <NavLink to="/dashboard" className='sidebar-logo text-decoration-none btn-link p-3'>
                <div className="d-flex align-items-center">
                    <img className="img-fluid" src={require('../assets/logo-icon.png')} alt="Logo" style={{ maxWidth: '40px' }} />
                    <span className="fs-3 fw-bold text-white ms-2 lh-sm">NM-Interview</span>
                </div>
                <div className="app-version d-flex gap-1 text-light fs-12 justify-content-center">
                    <div>BETA</div>
                    {/* <div>Version {currentAppVersion}</div> */}
                    <div>Version 1.2.3</div>
                </div>
            </NavLink>
            <ul className="sidebar-item-cover list-inline m-0">
                {/* <li className="nav-item"> 
                    <NavLink to="/testing" className="nav-link bg-primary text-white d-flex align-items-center gap-2 mx-4 my-2"><i className="bi bi-house-door opacity-100"></i><span>Testing</span></NavLink>
                </li> */}
                <li className="nav-item">
                    <NavLink to="/dashboard" className={`${activepage == '' || activepage === 'dashboard' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('dashboard')}><i className="bi bi-house-door opacity-100"></i><span>Dashboard</span></NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/recruitment-list" className={`${activepage === 'recruitment-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('recruitment-list')}><i className="bi bi-laptop"></i><span>Global Requisition</span></NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/interview-list" className={`${activepage === 'interview-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('interview-list')}><i className="bi bi-laptop"></i><span>{userType == "HR" ? "Interviews" : "Scheduled Interviews"}</span></NavLink>
                </li>


                {userType == "HR" ?
                    <li className="nav-item">
                        <NavLink to="/my-recruitment-list" className={`${activepage === 'my-recruitment-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('my-recruitment-list')}><i className="bi bi-laptop"></i><span>Active Requisition</span></NavLink>
                    </li> : null
                }

                {userType == "SA" || userType == "SSA" || userType == "HR" ?
                    <li className="nav-item">
                        <NavLink to="/hrcandidates" className={`${activepage === 'hrcandidates' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('hrcandidates')}><i className="bi bi-download"></i><span>{userType == "HR" ? "Candidate Scheduled" : "Candidate Scheduled"}</span></NavLink>
                    </li> : null}
                {/* <li className="nav-item">
                    <NavLink to="/users" className={`${activepage === 'users' ? 'active' : ' '} nav-link d-flex align-items-center gap-2`} title="Users List" onClick={() => closeSidebar('users')}><i className="bi bi-person-circle"></i><span>Registered Candidates</span></NavLink>
                </li> */}
                <li className="nav-item">
                    <NavLink to="/candidate-registration-status" className={`${activepage === 'candidate-registration-status' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} title="candidate-registration-status" onClick={() => closeSidebar('candidate-registration-status')}><i className="bi bi-person-circle"></i><span>User Portal Registration</span></NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/account" className={`${activepage === 'account' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} title="My Account" onClick={() => closeSidebar('account')}><i className="bi bi-person-plus fs-5 lh-1"></i><span>My Account</span></NavLink>
                </li>
                {userType == "SA" || userType == "SSA" ?
                    <li className="nav-item">
                        <NavLink to="/members-list" className={`${activepage === 'members-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} title="View member list" onClick={() => closeSidebar('members-list')}><i className="bi bi-person-vcard fs-5"></i><span>Member Lists</span></NavLink>
                    </li> :
                    null}
                {/* {userType == "HR" || userType == "SA" ?
                    <li className="nav-item">
                        <NavLink to="/mycandidates" className={`${activepage === 'mycandidates' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('mycandidates')}><i className="bi bi-download"></i><span>{userType == "HR" ? "My Candidates" : "HR Added Candidates"}</span></NavLink>
                    </li> : null} */}



                {/* {userType == "SSA" || userType == "SA" || userType == "HR" ?
                    <li className="nav-item">
                        <NavLink to="/issues-list" className={`${activepage === 'issues-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('issues-list')}>
                            <img src={IssueList} alt="Issue Listing" /><span>Issue</span>
                        </NavLink>
                    </li>
                    : null} */}
                     <li className="nav-item">
                        <NavLink to="/issues" className={`${activepage === 'issues' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('issues-list')}>
                            <img src={IssueList} alt="Issues" /><span>Issue</span>
                        </NavLink>
                    </li>
                 
                {userType == "SSA" || userType == "SA" || userType == "HR" ?
                    <li className="nav-item">
                        <NavLink to="/ongoing-interviews" className={`${activepage === 'ongoing-interviews' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('ongoing-interviews')}><i className="bi bi-briefcase" ></i><span>Live Interviews</span></NavLink>
                    </li>
                    : null}
                {userType == "SSA" || userType == "SA" ?
                    <li className="nav-item">
                        <NavLink to="/others-interview-list" className={`${activepage === 'others-interview-list' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('others-interview-list')}><i className="bi bi-laptop"></i><span>Others Interviews</span></NavLink>
                    </li> : null}
                <li className="nav-item">
                    <NavLink to="/reports" className={`${activepage === 'reports' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('reports')}><i className="bi bi-clipboard-data fs-18"></i><span>Reports</span></NavLink>
                </li>

                {/* {userType == "SSA" ?
                    <li className="nav-item">
                        <NavLink to="/version" className={`${activepage === 'version' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('version')}><i className="bi bi-download"></i><span>Version</span></NavLink>
                    </li> : null} */}
                {/* <li className="nav-item">
                    <NavLink to="/consentModal" className={`${activepage === 'consentModal' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('consentModal')}><i className="bi bi-clipboard-data fs-18"></i><span>Consent Modal</span></NavLink>
                </li> */}

                {/* {userType == "SSA" || userType == "SA" || userType == "HR" ?
                    <li className="nav-item">
                        <NavLink to="/pdfviewer" className={`${activepage === 'pdfviewer' ? 'active bg-primary text-white' : ' '} nav-link d-flex align-items-center gap-2`} onClick={() => closeSidebar('pdfviewer')}><i className="bi bi-briefcase" ></i><span>How to Use</span></NavLink>
                    </li>
                : null} */}
               
            </ul>
        </aside>
    );
};

export default Sidebar;