import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png'
import { UserAuth } from '../context/AuthContext';
import { Loader } from '../components/loader';
import { getAuth, database, secondDatabase, secauth } from '../firebaseConfig';
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { Button } from 'react-bootstrap';
import { useNavigationContext } from "../context/NavContext";
import LoadConsentModal from '../consentModal/LoadConsentModal'
// pagination
import Pagination from '../components/Pagination';
// pagination

export const Dashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [loading, setIsLoading] = useState(false);
  const [completedProfiles, setCompletedProfiles] = useState(0);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [totalHR, setTotalHR] = useState(0);
  const [totalInterviewCount, setTotalInterviewCount] = useState(0);
  const [usersWith60PercentAccuracy, setUsersWith60PercentAccuracy] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDateSet, setDateVal] = useState(false);
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchCandidate",
    "queryType": [],
    "isSearch": false,
  });
  // consent
  const { activepage, handlePageClick } = useNavigationContext()
  const [bodyData, setBodyData] = useState('');
  const [isConsentModalOpen, setConsentModalOpen] = useState(false);
  const [headername, setHeadername] = useState('');
  // consent
  const [userData, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Perform any action related to page change, e.g., fetching new data
  };
  // pagination

  // today's date
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  let formattedDate = year + '-' + month + '-' + day;
  // today's date
  const dashboardCount = async () => {
    setIsLoading(true);
    var filterObj = {
      "eMailId": ""
    }
    if (user?.userType == "HR") {
      filterObj = {
        // "email": user?.email
        "eMailId": user?.email
      };
    }
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/dashboard/get-count`, filterObj, { headers })
      .then(function (response) {
        const responseData = response?.data
        setCompletedProfiles(responseData?.completedProfiles);
        setTotalCandidates(responseData?.totalCandidates);
        setTotalInterviewCount(responseData?.totalInterviewCount);
        setUsersWith60PercentAccuracy(responseData?.usersWith60PercentAccuracy);
        setTotalHR(responseData?.hrCount)
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }

  const dashboardData = async (filterObj, getcurpage) => {
    const setPagenumber = getcurpage == '' ? currentPage : getcurpage
    setIsLoading(true);
    filterObj.page = currentPage;
    filterObj.pageSize = itemsPerPage;
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/get-list?page=${setPagenumber}&pageSize=${itemsPerPage}`, filterObj, { headers })
      .then(function (response) {
        const responseData = response?.data
        let userDatares = response.data;
        let data = userDatares.interviews?.length > 0 ? userDatares.interviews : [];
        setUsers(data);
        setTotalPages(response?.data.totalPages)
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }

  // buttonClick
  const handleClick = (type) => {

    var filterObj = {
      "startDate": startDate,
      "endDate": startDate,
      // "endDate": "2024-03-18", "startDate": "2024-03-18"
    }
    if (user?.userType == "HR") {
      var filterObj = {
        "startDate": startDate,
        "endDate": startDate,
        "eMailId": user?.email
      }
    }
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const getcurpage = currentPage - 1
      dashboardData(filterObj, getcurpage)
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      const getcurpage = currentPage + 1
      dashboardData(filterObj, getcurpage)
    }
  }
  // buttonClick

  //Fetch Data from Api 
  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    // setStartDate(today);
    if (user?.accessToken) {
      // fetchDashboardBlock();
      // fetchCandidateList(today);
      // sendData
      var filterObj = {
        "startDate": startDate == '' ? formattedDate : startDate,
        "endDate": endDate == '' ? formattedDate : endDate,
        // "endDate": "2024-03-18", "startDate": "2024-03-18"
      }
      if (user?.userType == "HR") {
        var filterObj = {
          // "startDate":formattedDate,
          // "endDate":formattedDate,
          "startDate": startDate == '' ? formattedDate : startDate,
          "endDate": endDate == '' ? formattedDate : endDate,
          "eMailId": user?.email
        }
      }
      // sendData
      dashboardCount()

      if (currentPage == 1) {
        const getcurpage = ''
        dashboardData(filterObj, getcurpage)
      }
    }
  }, [user, currentUserVal, startDate, currentPage]);

  useEffect(() => {
    // consent
    setConsentModalOpen(true);
    setHeadername('First Step')
    async function fetchData() {
      const response = await getImage();
      setBodyData(response)
      // if(response==''){
      //   navigate('/')
      //   handlePageClick('dashboard')
      // }
    }
    fetchData();
    // consent
  }, [])


  const InterviewerDetails = (info) => {
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }
    let sendInfo = {
      "candidate_id": info?.id,//info?.candidate_id,
      "email": info?.userEmail,//info?.email,
      "profile_img": info?.profileImg,//info?.profile_img,
      "candidate_name": info?.displayName,//info?.candidate_name,
      "login_token": token
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
    navigate(`/user-detail?query=${encodeURIComponent(encryptedQuery)}`);
  }

  //Select Date  
  const handleStartDateChange = (event) => {
    setCurrentPage(1)
    setStartDate(event.target.value);
    setEndDate(event.target.value)
    if (event.target.value != "")
      // fetchCandidateList(event.target.value);
      var filterObj = {
        "startDate": event.target.value,
        "endDate": event.target.value,
        // "endDate": "2024-03-18", "startDate": "2024-03-18"
      }
    if (user?.userType == "HR") {
      var filterObj = {
        "startDate": event.target.value,
        "endDate": event.target.value,
        "eMailId": user?.email
      }
    }
    const getcurpage = ''
    dashboardData(filterObj, getcurpage)
  }

  const convertDate = (dateValue) => {
    const parsedDatetime = new Date(dateValue);
    const date = parsedDatetime.toLocaleDateString();
    const time = parsedDatetime.toLocaleTimeString();
    let date2 = date + ' ' + time
    return date2
    // let date2 = new Date(dateValue).toLocaleDateString()
    // return date2
  }

  const clickBox = (data) => {
    navigate(`/${data}`);
    // console.log(data)
  }

  // consentModal
  const getImage = async () => {
    try {
      setIsLoading(true);
      const refreshedToken = await currentUserVal?.getIdToken(true);
      const user_id = user?.userId
      const postData = {
        "user_id": user_id
      }
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/consent/get-list`, postData, { headers })
      const countData = response?.data.data.length
      const getDataVal = countData == 1 ? response?.data.data[0].image_url : ''
      setBodyData(getDataVal)
      setIsLoading(false);
      let getImgUrl = getDataVal
      return getImgUrl
    } catch (error) {
      console.error('Error fetching requisitions:', error);
      setIsLoading(false);
    }
  };
  // consentModal

  return (
    <div className='dashboard-home py-3'>
      <div className='container-fluid'>
        <div className='welcome-card d-flex gap-1 bg-white rounded p-3 mb-3'>
          <div className='img-wrapper'>
            <img src={require('../assets/waiving.gif')} alt="Waveing Hand" className='img-fluid' style={{ width: '45px', minWidth: '40px' }} />
          </div>
          <div className='welcome-desc'>
            <div className='fs-18 mb-0 lh-sm'><span className='text-capitalize fw-medium text-primary'>Hi {user?.displayName},</span> Welcome back!</div>
            <div className='text-muted'>This page provide information regarding Interview data!</div>
          </div>
        </div>

        {/* Start: Info Boxes */}
        <div className='info-card-sec row g-2 g-md-3 mb-3'>
          {user?.userType != 'HR' ?
            <div className='col-sm-6 col-xl-4'>
              <div className='card h-100 border border-3 border-primary rounded-3 pointer p-3 px-xxl-4'>
                <div className="d-flex gap-3 pointer" onClick={() => clickBox('total-HR')}>
                  <div className='card-icon bg-primary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-primary'>
                    <i className="bi bi-person-badge fs-4 text-primary"></i>
                  </div>
                  <div className='card-details'>
                    <div className="label-value fs-2 fw-bold lh-1">{totalHR}</div>
                    <div className="label-title text-muted lh-sm mt-1">Total HR</div>
                  </div>
                </div>
              </div>
            </div> : ''}
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100 border border-3 border-primary rounded-3 pointer p-3 px-xxl-4'>
              <div className="d-flex gap-3 pointer" onClick={() => clickBox('total-Candidate')}>
                <div className='card-icon bg-primary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-primary'>
                  <i className="bi bi-people-fill fs-4 text-primary"></i>
                </div>
                <div className='card-details'>
                  <div className="label-value fs-2 fw-bold lh-1">{totalCandidates}</div>
                  <div className="label-title text-muted lh-sm mt-1">Total Candidate</div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100 border border-3 border-success rounded-3 pointer p-3 px-xxl-4'>
              <div className="d-flex gap-3 pointer" onClick={() => clickBox('completed-profile-user')}>
                <div className='card-icon bg-success bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-success'>
                  <i className="bi bi-person-fill-check fs-4 text-success"></i>
                </div>
                <div className='card-details'>
                  <div className="label-value fs-2 fw-bold lh-1">{completedProfiles}</div>
                  <div className="label-title text-muted lh-sm mt-1">User with completed profile</div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100 border border-3 border-danger rounded-3 pointer p-3 px-xxl-4'>
              <div className="d-flex gap-3 pointer" onClick={() => clickBox('total-interview')}>
                <div className='card-icon bg-danger bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-danger'>
                  <i className="bi bi-person-fill-x fs-4 text-danger"></i>
                </div>
                <div className='card-details'>
                  <div className="label-value fs-2 fw-bold lh-1">{totalInterviewCount}</div>
                  <div className="label-title text-muted lh-sm mt-1">Total Interview</div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-xl-4'>
            <div className='card h-100 border border-3 border-warning rounded-3 pointer p-3 px-xxl-4'>
              <div className="d-flex gap-3 pointer" onClick={() => clickBox('candidate-sixty-plus')}>
                <div className='card-icon bg-warning bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center border border-2 border-warning'>
                  <i className="bi bi-person-fill-add fs-4 text-warning"></i>
                </div>
                <div className='card-details'>
                  <div className="label-value fs-2 fw-bold lh-1">{usersWith60PercentAccuracy}</div>
                  <div className="label-title text-muted lh-sm mt-1">Candidate who opt more than 60%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End: Info Boxes */}

        <div className='calender-sec bg-white rounded-3 mb-2 p-2'>
          <div className='row align-items-center'>
            <div className='col-xl-8 col-md-7'>
              <div className="fs-5 fw-medium">Registered Candidates On Date: <span className='text-success'>{userData?.length > 0 ? userData?.length : 0}</span></div>
            </div>
            <div className='col-xl-4 col-md-5 mt-2 mt-md-0'>
              <div className="d-md-flex align-items-center gap-md-3">
                <label className="form-label text-muted text-nowrap fw-medium m-md-0" htmlFor='calender'>Select Date</label>
                <input type="date" value={startDate != '' ? startDate : formattedDate} onChange={handleStartDateChange} className="form-control py-2" id="calender" name="calender" />
              </div>
            </div>
          </div>
        </div>

        <div className='candidate-listing-sec position-relative'>
          <div className='candidates-wrapper'>
            {loading ? <Loader /> : ''}
            <div className='candidate-list'>
              {userData && userData?.length > 0 ? userData?.map((info, id) => (
                <>
                  <div className="info-cards card small bg-white rounded overflow-auto pointer p-3 mt-2" onClick={() => InterviewerDetails(info)} key={id}>
                    <div className="row g-2">
                      <div className="col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box mb-1 mb-md-0">
                          <div className="img-wrapper mb-1">
                            <img src={info?.profileImg} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-secondary border-opacity-75 rounded-1" style={{ width: '50px', height: '45px' }} />
                          </div>
                          <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info?.displayName}</div>
                          {info?.userEmail && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info?.userEmail}</div>}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-3 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="info-label text-secondary">Current Company</div>
                          <div className="cand-company text-capitalize">{info?.currentCompany}</div>
                        </div>

                        <div className="info-box">
                          <div className="info-label text-secondary">Total Experience</div>
                          <div className="cand-exp text-capitalize">{info?.totalExp} Year</div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="info-label text-secondary">Prefered Language</div>
                          <div className="info-box test-lang d-flex flex-wrap gap-2">
                            <span className="skills" key={info?.selectedLanguage}>{info?.selectedLanguage}</span>
                          </div>
                        </div>

                        <div className="info-box">
                          <div className="info-label text-secondary">Interview Date</div>
                          <div className="test-duration text-capitalize">{info?.testStarttime ? convertDate(info.testStarttime) : ''}</div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box">
                          <div className="info-label text-secondary">Total Test</div>
                          <div className="cand-lang text-capitalize">{info?.interview_count}</div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                        <div className="info-box action-wrapper">
                          <div className="info-label text-secondary">Job Status</div>
                          <span className={`fs-12 badge rounded-pill px-3 ${info.jobStatus === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.jobStatus === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info.jobStatus === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info.jobStatus}</span>
                        </div>

                        <div className='action-wrapper d-flex gap-2 mt-2'>
                          <Button variant='success' size='sm' className='border px-3' onClick={() => InterviewerDetails(info)}>View Details</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )) :
                <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
                  <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                    <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                    <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                    <div className='small text-muted'>No candidates registered on the selected date</div>
                  </div>
                </div>
              }
              {userData?.length >= 1 ?
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
