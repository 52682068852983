import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FileViewer from 'react-file-viewer';

const DocumentViewer = ({ documentUrl, fileType}) => {
   const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const downloadFile = () => {
    const fileName = documentUrl.split('/').pop();
    const link = document.createElement('a');
    link.href = documentUrl;
    link.download = fileName; 
    link.target = '_blank'; // Optional: Open in a new tab

    // Trigger a click event on the anchor to initiate the download
    document.body.appendChild(link);
    link.click();

    // Remove the anchor element after the download starts
    document.body.removeChild(link);
  };
  return (
    <>
    {/* <button onClick={downloadFile}>Download</button>
      <button onClick={() => setShowModal(true)}>Open Resume</button>
       */}
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
        <button onClick={downloadFile}>Download</button>
        <button onClick={() => setShowModal(true)}>Open Resume</button>
      </div>
      
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileViewer fileType = {fileType}
        filePath={documentUrl}/>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentViewer;
