import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";
import Modal from 'react-bootstrap/Modal';
import { Button, Tab, Tabs } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { getAuth, database, secondDatabase } from '../firebaseConfig';
import axios from 'axios';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';


export const OneToOneDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();
    const [userDetails, setUserDetails] = useState(null);
    const [quesList, setQuesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [imageKeys, setImageKeys] = useState([]);
    const [documentData, setDocumentData] = useState(null);
    const [isCompositionDone, setComposition] = useState(false);
    const { user, userInfoLoaded, currentUserVal } = UserAuth();

    // For Video Fullscreen Model 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // For Nav & Tabs For Details
    const [key, setKey] = useState('home');

    const detail = location.state;
     console.log("One to One single question Details ** ");
     console.log(detail);

    let startMoment = moment(detail?.test_starttime).utc();
    let endMoment = moment(detail?.test_endtime).utc();
    let duration = moment.duration(endMoment.diff(startMoment));

    const formattedDate = startMoment.format('YYYY-MM-DD HH:mm:ss');


    return (
        <div className='test-result-page py-3'>
            <div className='container-fluid'>
                <div className="fs-18 fw-medium mb-2">One To One Details ---</div>
                <div className='ques-ans-item card rounded-3 p-3'>
                    <div className='question-wrapper fs-14'>
                        <div className='info-box'>
                            <div className='title-label fs-6 fw-semibold mb-1'>Question:</div>
                            <div className='question text-muted mb-2'>{detail?.questions}</div>
                            <div className='title-label text-muted fw-semibold'>Ques Group:</div>
                            <div className='example text-muted'>{detail?.question_title}</div>
                        </div>
                        <div className={`info-box ques-ans-item ${detail.iscorrect === '1' ? 'status-pass' : 'no-status' && detail.iscorrect === '0' ? 'status-fail' : 'no-status'} rounded mt-3 p-3`}>
                            <div className={`title-label fs-6 fw-semibold ${detail.iscorrect === '1' ? 'text-success' : 'no-status' && detail.iscorrect === '0' ? 'text-danger' : 'no-status'} mb-1`}>Answer:</div>
                            <div className="answer fs-14 text-body">{detail?.answers}</div>
                        </div>
                    </div>

                    <div className="section bg-secondary bg-opacity-10 mt-3 p-3">
                        <div className="row">
                            <div className="col-md-6">

                                <div className="videoWrapper">
                                    <div className="fs-6 fw-medium text-capitalize mb-2"><i className="bi bi-film small text-primary"></i> Recorded Answer -</div>
                                    <div className="zoom-wrapper ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '500px' }}>
                                        {/* <video id="videoSrc" className="border rounded w-100 d-block object-fit-cover" height="240" controls="" src={detail?.video_url} type="video/mp4"></video> */}
                            <video controls width="250" className="border rounded w-100 d-block object-fit-cover">
                               <source src={detail?.video_url} type="video/mp4" />
                            </video>
                                    </div>
                                    {/* <div className="text-danger fw-medium mt-2">Completed composition percentage - <span className="text-success fw-semibold">100%</span></div> */}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="fs-18 fw-medium text-capitalize mb-2 hidden">&nbsp;</div>
                                <div className='row g-3 ques-timing fs-14'>
                                    <div className='info-box'>
                                        <div className='label-name'>Visible to Candidate on:</div>
                                        <div className='label-value fs-6 fw-semibold text-uppercase text-nowrap'>{ moment(detail?.ques_time).format('YYYY-MM-DD HH:mm:ss a')}</div>
                                    </div>
                                    <div className='info-box'>
                                        <div className='label-name'>Candidate Submitted the Solution At:</div>
                                        <div className='label-value fs-6 fw-semibold text-uppercase text-nowrap'>{moment(detail?.ans_time).format('YYYY-MM-DD HH:mm:ss a')}</div>
                                    </div>
                                    <div className='info-box'>
                                        <div className='label-name'>Total Time Taken by Candidate to Complete the Solution:</div>
                                        <div className='label-value fs-6 fw-semibold text-nowrap'>{detail?.timeTaken}</div>
                                    </div>
                                </div>
                                <div className='info-box d-flex gap-2 mt-3'>
                                    <div className='label-name fw-medium'>Result:</div>
                                    <div className={`label-value fw-semibold text-uppercase ${detail.iscorrect === '1' ? 'text-success' : 'text-warning' && detail.result === '0' ? 'text-danger' : 'text-warning'}`}>{detail?.iscorrect == "1" ? "Pass" : "Fail"}</div>
                                </div>

                        </div>
                        </div>
                    </div>

                    <div className='info-box fs-14 bg-warning bg-opacity-75 mt-3 p-3'>
                        <div className='label-name fs-6 fw-semibold'>Reason:</div>
                        <div className='label-value text-body'>{detail.reason}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
