import React from 'react';

const RequisitionPagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageRange = 3; // Range for showing pages
    const pageNumbers = [];

    // Generate page numbers array
    for (let i = 1; i <= totalPages; i++) {
        // Show first and last pages, and current page range
        if (i === 1 || i === totalPages || (i >= currentPage - pageRange && i <= currentPage + pageRange)) {
            pageNumbers.push(i);
        } else if (pageNumbers[pageNumbers.length - 1] !== "...") {
            pageNumbers.push("...");
        }
    }

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="pagination-wrapper text-center mt-3">
            <ul className="pagination justify-content-center m-0">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a 
                        className='page-link pointer' 
                        onClick={handlePrevious}
                    >
                        Prev
                    </a>
                </li>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <a 
                            className='page-link pointer' 
                            onClick={() => typeof number === 'number' ? onPageChange(number) : null}
                        >
                            {number}
                        </a>
                    </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a 
                        className='page-link pointer' 
                        onClick={handleNext}
                    >
                        Next
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default RequisitionPagination;
