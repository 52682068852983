import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import pdfFile from '../../assets/dummy.pdf'

const PdfViewer = () => {
  const pdfUrl = 'https://example.com/your-pdf-file.pdf'; // URL to your PDF file

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess() {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
};

export default PdfViewer;