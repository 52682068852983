import React,{useState} from 'react';
import { Routes, Route  } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProfileContext from './context/ProfileContext';
import { Login } from './pages/login';
import Layout from './pages/layout';
import { MyProvider } from './context/MyContext';
import { NavigationProvider } from './context/NavContext';
import { AdminRoomPage } from './RoomPage/AdminRoomPage';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
function App() {
  const [profile, setProfile] = useState({ currentCompany: '', totalExperience: 0 });

  return (
      <NavigationProvider>
        <MyProvider>
          <AuthContextProvider>
            <ProfileContext.Provider value={{profile, setProfile}}>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path="*" element={<Layout />} />     
                 
              </Routes>
            </ProfileContext.Provider>
          </AuthContextProvider>
        </MyProvider>
      </NavigationProvider>
  );
}

export default App;

