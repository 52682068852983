import React, { createContext, useContext, useState } from 'react'

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext)

export const NavigationProvider = ({ children }) => {
    const [activepage, setActivepage] = useState('')

    const handlePageClick = (page) => {
        setActivepage(page)
    }

    return(
        <NavigationContext.Provider value = {{activepage, handlePageClick}}>
            {children}
        </NavigationContext.Provider>
    )
}