import React, { useState } from "react";
import { Rating } from 'react-simple-star-rating';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Loader } from '../components/loader';
import { UserAuth } from "../context/AuthContext";
// import { convertReadable, convertReadableTime } from '../../utils/utils';

const InterviewScheduledcard = ({ scheduleData, RequisitionCandidates, ReqId }) => {
    const { user, userInfoLoaded, currentUserVal } = UserAuth();  
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const convertDate = (dateValue) => {
        let date2 = new Date(dateValue).toLocaleDateString()
        return date2
    }

    const intTime = parseInt(scheduleData?.interview_time) > 12 && parseInt(scheduleData?.interview_time) < 24 ? scheduleData?.interview_time + " PM" : scheduleData?.interview_time + " AM";

    // reschedule Interview
    const [formData, setFormData] = useState({
        interview_date: '',
        interview_time: '',
        technical_round: 0,
        one2one_round: 0
    });

    const openModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
        setFormData({ ...formData, [name]: newValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let rescheduleData = formData;
        setIsLoading(true);
        try {
            let sendData = {
                "email": scheduleData?.email,
                "candidate_id": scheduleData?.id,
                "technical_round": rescheduleData?.interview_round == 3 || rescheduleData?.interview_round == 1 ? 1 : scheduleData?.is_technical,
                "one2one_round": rescheduleData?.interview_round == 3 || rescheduleData?.interview_round == 2 ? 1 : scheduleData?.is_one2one,
                "reschdule_for": rescheduleData?.interview_round,
                "interview_date": rescheduleData?.interview_date,
                "interview_time": rescheduleData?.interview_time
            }
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/candidates/reschedule-candidate`, sendData, { headers })
            const responseData = response.data
            closeModal()
            setIsLoading(false);
            toast.success('Interview has been rescheduled.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            RequisitionCandidates(ReqId)
        }
        catch (error) {
            console.error('Error: ', error);
            setIsLoading(false);
        }
    }
    // reschedule Interview

    return (

        <div className="info-cards int-scheduled small rounded pointer overflow-auto mt-2">
            <div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">Interview Scheduled</div>
            <div className="info-body p-3">
                <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                        <div className="cand-name text-primary fs-6 fw-medium text-capitalize">{scheduleData?.user_name}</div>
                        <div className="cand-role text-muted text-capitalize">{scheduleData?.user_name}</div>
                        <div className="cand-company text-black text-capitalize">{scheduleData?.current_company}</div>
                    </div>
                    <div className="col-sm-6 text-xl-end">
                        {/* <div className="cand-date text-muted text-capitalize">{convertDate(scheduleData?.interview_date) + ' ' + intTime}</div> */}
                        <div className="cand-status fw-medium text-danger text-capitalize">{scheduleData?.job_status}</div>
                    </div>
                </div>

                <div className="row g-3">
                    <div className="col-sm-6 col-xl-3">
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                            <div className="label-title text-black">Current CTC:</div>
                            <div className="label-value text-danger">{scheduleData?.current_ctc} Lakh</div>
                        </div>
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                            <div className="label-title text-black">Interview Medium:</div>
                            <div className="label-value text-danger">{scheduleData?.interview_medium}</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                            <div className="label-title text-black">Expected CTC:</div>
                            <div className="label-value text-danger">{scheduleData?.expected_ctc} Lakh</div>
                        </div>
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                            <div className="label-title text-black">Interview Date:</div>
                            <div className="label-value text-danger">{convertDate(scheduleData?.interview_date)}</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
                            <div className="label-title text-black">Com Skills:</div>
                            <Rating size={18} style={{ marginTop: '-6px' }}
                                initialValue={parseFloat(scheduleData?.comm_skill_rating)}
                                allowFraction
                                // showTooltip
                                readonly
                                fillColor={"#E43545"}
                            />
                        </div>
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                            <div className="label-title text-black">Interview Time:</div>
                            <div className="label-value text-danger">{intTime}</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                            <div className="label-title text-black">Interview Scheduled By:</div>
                            <div className="label-value text-danger">{scheduleData?.added_by_name}</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                            <Button variant="primary" className="px-3 me-2" onClick={openModal}>Reschedule-Interview</Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Reschedule modal */}
            <Modal show={show} backdrop="static" keyboard={false} size="lg" centered scrollable contentClassName="border-0">
                <Modal.Header closeVariant="white" className="bg-primary text-light px-md-4">
                    <Modal.Title className="fs-6 fw-medium" closeVariant="btn-close-white">Reschedule Interview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-md-4 py-4">
                    {isLoading ? (
                        <Loader />
                    ) : ""}
                    <Form onSubmit={handleSubmit}>
                        {/* Start */}
                        <div id="interviewSchedule" className="row g-2 gx-sm-3 mt-1">
                            <div className="col-sm-6 col-lg-4">
                                <Form.Group>
                                    <Form.Label>Interview Date</Form.Label>
                                    <Form.Control type="date" name="interview_date" value={formData.interview_date} onChange={handleInputChange} required />
                                </Form.Group>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <Form.Group>
                                    <Form.Label>Interview Time</Form.Label>
                                    <Form.Control type="time" name="interview_time" value={formData.interview_time} onChange={handleInputChange} required />
                                </Form.Group>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <Form.Group>
                                    <Form.Label>Interview Round</Form.Label>
                                    <Form.Select name="interview_round" onChange={handleInputChange} required>
                                        <option value="">Select Round</option>
                                        <option value="1">Reschedule for Technical Round</option>
                                        <option value="2">Reschedule for One to One with Sheela</option>
                                        <option value="3">Reschedule for Both</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        {/* End */}
                        <div className="d-flex py-3 px-2 justify-content-center">
                            <Button type="button" variant="secondary" className='focus-ring focus-ring-secondary px-4' onClick={() => closeModal('outerModal')}>Cancel</Button>
                            <Button type="submit" variant="danger" className='focus-ring focus-ring-danger px-4 mx-2'>Submit</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Reschedule modal */}
        </div>
    )
}

export default InterviewScheduledcard;