import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { NavLink, Link, useNavigate, Navigate } from 'react-router-dom';
import { auth } from "../firebaseConfig";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Sidebar from './sidebar';
import imgDefault from '../assets/Avatar.png'

const Header = () => {
    const { logOut, user, currentUserVal } = UserAuth();
    const navigate = useNavigate();

     // Start: Mobile sidebar offcanvas code
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    
    const openOffcanvas = () => {
        setIsOffcanvasOpen(true);
    };

    const closeOffcanvas = () => {
        setIsOffcanvasOpen(false);
    };

    // const handleDocumentClick = (e) => {
    //     // Check if the click event target is not the Offcanvas or its trigger button
    //     if (
    //         isOffcanvasOpen &&
    //         !document.getElementById("offcanvasElement").contains(e.target) &&
    //         !document.getElementById("offcanvasButton").contains(e.target)
    //     ) {
    //         closeOffcanvas();
    //     }
    // };

    // Attach the click event listener to the document
    // useEffect(() => {
    //     document.addEventListener("click", handleDocumentClick);
    //     return () => {
    //         document.removeEventListener("click", handleDocumentClick);
    //     };
    // }, [isOffcanvasOpen]);

    // End: Mobile sidebar offcanvas cod
    const handleSignOut = async () => {
        try {
            await logOut();
            navigate('/');
        } catch (error) {
        }
    };

    const handleProfile = () => {
        navigate('/account');
    }

    const goBack = () => {
        navigate(-1);
    }

    // shorName method is calculating shortname
    const shortName = user?.displayName?.split(" ")?.map((shortName) => {
        return shortName[0];
    });

    return (
        <nav className="navbar navbar-light bg-white navbar-expand-lgg top-header sticky-top shadow-sm py-2" data-bs-theme="light" style={{ minHeight: '55px' }}>
            <div className="container-fluid gap-2">
                <div className="left-sec col d-flex align-items-center justify-content-start gap-3">
                    <button className="navbar-togglerr border-0 bg-transparent text-primary d-lg-none p-0" type="button" onClick={openOffcanvas}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </button>
                    <div className="icon text-primary d-none d-lg-block">
                        <button onClick={goBack} className='arrowBtn'><i className="bi bi-arrow-left-short"></i></button>
                    </div>
                    {/* <div className="d-none d-md-none d-lg-block">
                        <form className="d-flex align-items-center">
                            <input type="search" className="form-control py-2" placeholder="Search..." style={{ width: '220px' }} />
                        </form>
                    </div> */}
                </div>

                <div className="middle-sec col text-center">
                    <Link className="navbar-brand d-flex d-lg-none align-items-center p-0 px-2 m-0" to='/'>
                        <div className="d-flex align-items-center justify-content-center">
                            <img className="img-fluid" src={require('../assets/logo-icon.png')} alt="Logo" style={{ maxWidth: '40px' }} />
                            <span className="text-body ms-2 fs-3 fw-bold">NM-Interview</span>
                        </div>
                    </Link>
                </div>

                {/* Start: Mobile Sidebar Drawer */}
                <Offcanvas show={isOffcanvasOpen} onHide={closeOffcanvas} style={{maxWidth:'260px'}}>
                    <div className="sidebar-wrapper" style={{display:'block'}}>
                        <Sidebar closeOffcanvas={closeOffcanvas} />
                    </div>
                </Offcanvas>

                {/* {isOffcanvasOpen && (<div className="offcanvas-backdrop fade show"></div>)} */}
                {/* End: Mobile Sidebar Drawer */}

                {isOffcanvasOpen && (<div className="offcanvas-backdrop fade show"></div>)}
                {/* End: Mobile Sidebar Drawer */}

                <ul className="right-sec col d-flex justify-content-end m-0 p-0">
                    <li className="nav-item dropdown bell-notification position-relative me-3">
                        <button type="button" className="bell-icon border-0 rounded-circle bg-light p-0" id="dLabel" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="chip-wrapper">
                                {/* <div className="chip-img d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-25 overflow-hidden">
                                    <i className="bi bi-bell-fill text-primary"></i>
                                </div>
                                <span className="position-absolute p-1 bg-primary border border-light rounded-circle" style={{ top: '3px', right: 0 }}>
                                    <span className="visually-hidden">New alerts</span>
                                </span> */}
                            </div>
                        </button>

                        {/* <div className="dropdown-menu dropdown-menu-end notifications shadow p-0" aria-labelledby="dLabel">
                            <div className="bg-light border-bottom d-flex align-items-center justify-content-between gap-2 p-3 mb-2">
                                <div className="menu-title m-0">Notifications</div>
                                <div className="menu-title m-0"><i className="bi bi-gear"></i></div>
                            </div>
                            <div className="notifications-wrapper small overflow-auto px-3" style={{ maxHeight: '300px' }}>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                                <Link className="content d-block" to={'#'}>
                                    <div className="notification-item">
                                        <div className="item-title text-dark m-0">Evaluation Deadline <span className="text-danger">1 day ago</span></div>
                                        <p className="item-info m-0">Marketing 101, Video Assignment</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="bg-primary text-light small border-top rounded-bottom text-center p-3 mt-2">See all Notification <i className="bi bi-arrow-right-circle-fill"></i></div>
                        </div> */}
                    </li>
                    <li className="nav-item dropdown user-logout">
                        <button className="nav-link text-white text-center border-0 bg-transparent p-0" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="chip-wrapper">
                                <div className="chip-img bg-primary d-flex align-items-center justify-content-center rounded-circle border border-primary overflow-hidden">
                                    {/* <div className="user-shortname text-light">{shortName}</div> */}
                                    {/* <img className='w-100 h-100' src={require('../assets/dummy-user.jpeg')} alt='User' /> */}
                                    {/* <img className='w-100 h-100' src={user?.userImage} alt='User' /> */}
                                    <img src={user?.userImage != null ? user?.userImage : imgDefault} referrerPolicy="no-referrer" alt="Candidate" className="w-100 h-100" />
                                </div>
                            </div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end caret-indicator shadow-lg p-3 pb-4 mt-2" style={{ width: "300px" }} >
                            <div className="d-flex justify-content-between gap-2">
                                <div className="orgainization-name title-label fw-medium">Virtualemployee Pvt Ltd.</div>
                                <Link to="/" className="signout title-label text-primary fw-medium" onClick={handleSignOut}>Sign out</Link>
                            </div>
                            <div className="d-flex align-items-center gap-2 mt-3">
                                <div className="chip-wrapper">
                                    <div className="chip-img bg-primary d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden" style={{ height: "70px", width: "70px" }}>
                                        {/* <div className='user-shortname text-light fs-3'>VA</div> */}
                                        <img src={user?.userImage != null ? user?.userImage : imgDefault} referrerPolicy="no-referrer" alt="Candidate" className="w-100 h-100" />
                                    </div>
                                </div>
                                <div className="user-info overflow-hidden">
                                    <div className="user-name fw-medium text-capitalize text-truncate lh-sm">{user?.displayName}</div>
                                    <div className="user-id title-label text-truncate">{user?.email}</div>
                                    <div className="user-id title-label">
                                        <Link to={"#"} className="btn-link" onClick={() => { handleProfile() }}>View account</Link>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;