import Avatar from '../../assets/Avatar.png';

const CandidateAdded = ({profileData, candidateData, senddata,user}) => {

    const formatDateandtime = (isoString) => {
        const date = new Date(isoString);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    return (
        <>
            <div className="candActivityStatus status-candidate-added  bg-white small rounded-3 pointer overflow-auto">
                <div class="info-header text-white d-flex flex-wrap justify-content-between column-gap-2 px-3 py-2 py-md-1">
                    <div className="left-sec text-uppercase">
                        <span>REQ ID: VEREQ{profileData?.requisition_id}</span>
                    </div>
                    <div className="right-sec">
                        <div className="test-round fw-semibold">Candidate Added</div>
                    </div>
                </div>
                <div className="info-body p-3">
                    <div className="row g-3 g-xl-2">
                        <div className="col-12 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="d-flex align-items-center gap-2">
                                    <img src={senddata?.user_photo != "" && senddata?.user_photo != null ? senddata?.user_photo : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '40px', width: '40px' }} />
                                    <div className="cand-info text-capitalize text-truncate">
                                        <div className="candi-name fw-semibold text-truncate">{candidateData?.candidate_name}</div>
                                        <div className="candi-company text-truncate">{profileData?.current_company}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Added Date & Time</div>
                                <div className="cand-company text-capitalize">{profileData?.created_at ? formatDateandtime(profileData?.created_at) : ""}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Source of Candidate</div>
                                <div className="cand-company text-capitalize">{profileData?.source_of_candidate}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Added By</div>
                                <div className="d-flex align-items-center gap-2 mt-1">
                                    <img src={require('../../assets/Avatar.png')} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '30px', width: '30px' }} />
                                    {
                                        user?.userType == "HR" && user.hrType == '1' ?
                                            profileData.hrType != 1 ?
                                            <div className="recruiter-name text-capitalize text-truncate">External HR</div>
                                            :
                                            <div className="recruiter-name text-capitalize text-truncate">{profileData?.added_by_name}</div>
                                        :
                                        <div className="recruiter-name text-capitalize text-truncate">{profileData?.added_by_name}</div>
                                    }
                                    {/* <div className="recruiter-name text-capitalize text-truncate">{profileData?.added_by_name}</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateAdded