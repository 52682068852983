import Avatar from '../../assets/Avatar.png';

const InterviewCard = ({ startedData, senddata }) => {

    const formatDateandtime = (isoString) => {
        const date = new Date(isoString);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    const calculateTimeDifference = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInMs = endDate - startDate; // Difference in milliseconds
        const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60)); // Convert to minutes
        const hours = Math.floor(differenceInMinutes / 60); // Get hours
        const minutes = differenceInMinutes % 60; // Get remaining minutes
        var mergetime = ""
        if (hours >= 1) {
            mergetime = hours + `${hours > 1 ? " hours " : " hour "}` + minutes + `${minutes > 1 ? " minutes " : " minute "}`
        } else {
            mergetime = minutes + `${minutes > 1 ? " minutes " : " minute "}`
        }
        // return { hours, minutes };
        return mergetime
    };

    return (
        <>
            <div className="candActivityStatus status-interview bg-white small rounded-3 pointer overflow-auto">
                <div class="info-header text-white d-flex flex-wrap justify-content-between column-gap-2 px-3 py-2 py-md-1">
                    <div className="left-sec text-uppercase">
                        <span>REQ ID: {startedData?.requisition_id}</span>
                        <>
                            <span className='mx-2'>|</span>
                            <span>TEST ID: {startedData?.interview_schedule_id != "" && startedData?.interview_schedule_id != null ? 'VEIS' + startedData?.interview_schedule_id : 'VENIS' + startedData?.id}</span>
                        </>
                    </div>
                    <div className="right-sec">
                        <div className="test-round fw-semibold">Interview</div>
                    </div>
                </div>
                <div className="info-body p-3">
                    <div className="row g-3 g-xl-2">
                        <div className="col-12 col-xl-3 item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="d-flex align-items-center gap-2">
                                    <img src={senddata?.user_photo != "" && senddata?.user_photo != null ? senddata?.user_photo : Avatar} alt="Recruiter" className='img-fluid rounded-circle' style={{ height: '40px', width: '40px' }} />
                                    <div className="cand-info text-capitalize text-truncate">
                                        <div className="candi-name fw-semibold text-truncate">{senddata?.user_name}</div>
                                        <div className="candi-company text-truncate">{senddata?.current_company}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Started Date & Time</div>
                                <div className="cand-company text-capitalize">{startedData?.test_starttime ? formatDateandtime(startedData?.test_starttime) : ""}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Completed Date & Time</div>
                                <div className="cand-company text-capitalize">{startedData?.test_endtime ? formatDateandtime(startedData?.test_endtime) : "End time not saved"}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Total Test Duration</div>
                                <div className="text-capitalize">{startedData?.test_starttime != "" && startedData?.test_starttime != null && startedData?.test_endtime != "" && startedData?.test_endtime != null ? calculateTimeDifference(startedData?.test_starttime, startedData?.test_endtime) : "Time not saved"}</div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 col-xl item-wrapper d-flex flex-column gap-2">
                            <div className="info-box">
                                <div className="info-label text-clr1 fs-12">Status</div>
                                <div className={`activity-status completed fw-semibold text-uppercase ${startedData?.result == "Pass" ? "text-success" : "text-danger"}`}>
                                    {startedData?.test_endtime != "" && startedData?.test_endtime != null && startedData?.result != "" && startedData?.result != null ? startedData?.result : "Partial Completed"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InterviewCard;