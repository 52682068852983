import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { getAuth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';
import CryptoJS from 'crypto-js';
import '../common/custom.css'
import { Loader } from '../components/loader';

export const RequisitionCandidateStatus = () => {
    const auth = getAuth();
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    // React Bootstrap Model
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [alertshow, setAlertShow] = useState(false)
    const handleClose = () => setShow(false);
    const [candidateData, setCandidatestatus] = useState([])
    const [requisitionData, setRequisitionData] = useState([])
    const [selectedOption, setSelectedOption] = useState('option1')
    const [textareaValue, setTextareaValue] = useState(' ')
    const [isdataLength, setDataLength] = useState(false)
    // decrypt Data
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('query');
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // candidateStatuslisting
    const candidateStatuslisting = async() => {
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: decryptedData?.requisition_id,
                type: "candidate",
                page: 1,
                limit: 10,
                isFilter: true,
                filterData: decryptedData?.candidateEmail,
                userType: user?.userType
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-status`, postData, { headers })
            const requisitionStatusdata = response.data?.requisitionStatus
            const getLength = requisitionStatusdata?.length >= 1 ? setDataLength(true) :setDataLength(false);
            setCandidatestatus(response.data?response.data.requisitionStatus:[])
            setIsLoading(false);            
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    }

    // requisition details
    // const handleShow = () => setShow(true);
    const handleShow = async() => {
        setShow(true)
        try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: decryptedData?.requisition_id,
                userType: user?.userType
            };
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/requisition-details`, postData, { headers })
            const jobDetails = response.data?response.data.jobDetails:[]
            const requisitionDetails = jobDetails?jobDetails.requisition:[]
            setRequisitionData(requisitionDetails)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    }

    useEffect(()=>{
        if(auth)
        candidateStatuslisting()
    },[auth])

    const convertDate = (dateValue) => {
        let date2=new Date(dateValue).toLocaleDateString()
        return date2
    }

    // add status
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value)
    }
    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value)
    }

    const acceptData = async (e) => {
        e.preventDefault();
             try {
            setIsLoading(true);
            const refreshedToken = await currentUserVal?.getIdToken(true);
            let postData = {
                requisitionId: decryptedData?.requisition_id,
                name: decryptedData?.user_name,
                email: decryptedData?.candidateEmail,
                type: "candidate",
                status: selectedOption,
                note: textareaValue,
                addedBy:user?.displayName,
                addedByEmail:user?.email,
                userType: user?.userType
            };
            // console.log(postData)
            setSelectedOption('option1')
            setTextareaValue(' ')
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/add-status`, postData, { headers })
            // console.log("Requisition details");
            // console.log(response.data);
            setShow(false)
            setAlertShow(true)
            setTimeout(() => {
                setAlertShow(false)
                candidateStatuslisting()
            }, 4000)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching requisitions:', error);
            setIsLoading(false);
        }
    }

    return (
        <div className='Requisition-Candidate-Status py-3'>
            {isLoading ? (
                <Loader />
            ) : ''}
            {/* alert */}
            <Alert variant="success"  show={alertshow} dismissible className="alertMsg">
                <Alert.Heading>Requisition status has been added.</Alert.Heading>
            </Alert>
            {/* alert */}
            <div className='container-fluid'>

                {/* TitleHeading Section */}
                <div className='d-flex align-items-sm-center justify-content-sm-between flex-column flex-sm-row gap-2 mb-3'>
                    <div className='left-sec fs-5 fw-medium'>
                        <i className="bi bi-journal-album"></i> Candidate Status
                    </div>
                    <div className="col-7">
                        <span className="userName">{decryptedData.user_name}<span className="userEmail">[{decryptedData.candidateEmail}]</span></span>
                    </div>
                    <Button variant="success" className="rounded-pill px-4 py-2" onClick={handleShow}>
                        <i className="bi bi-plus-lg"></i> Add Status
                    </Button>
                </div>

                {/* Requisition Status Listing */}
                <div className="requisition-status-wrapper card border-0 p-3">
                    {isdataLength ?  
                    <div>{candidateData.map((elementInArray, index) => (
                        <div className="data-row border-bottom pb-3 mb-3" key={index}>
                            <div className="row g-2">
                                <div className="col-6 col-sm-4 col-xl-2">
                                    <div className="info_wrapper">
                                        <div className="title_name text-secondary mb-1">Date</div>
                                        <div className="value text-capitalize">{elementInArray.createdAt?convertDate(elementInArray.createdAt):''}</div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xl-2 order-2 order-sm-0">
                                    <div className="info_wrapper">
                                        <div className="title_name text-secondary mb-1">Current Status</div>
                                        <div className="value text-capitalize">{elementInArray.status?elementInArray.status:''}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 col-xl-2">
                                    <div className="info_wrapper">
                                        <div className="title_name text-secondary mb-1">By</div>
                                        <div className="value text-capitalize">{elementInArray.addedBy?elementInArray.addedBy:''}</div>
                                    </div>
                                </div>
                                <div className="col-xl-6 order-last order-sm-0">
                                    <div className="info_wrapper mt-2 mt-xl-0">
                                        <div className="title_name text-secondary mb-1">Notes</div>
                                        <div className="value">
                                        {elementInArray.note?elementInArray.note:''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}</div> : <div className="noData">No Data to show.</div>}
                </div>

                {/* Add Requisition Status Model */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered contentClassName="rounded-4">
                    <Modal.Body className="p-3 py-4 p-lg-5">
                    {isLoading ? (
                        <Loader />
                    ) : ''}
                        <div className="fs-3 fw-semibold text-center mb-2 mb-lg-4 mt-lg-n2">Add Candidate Status</div>
                        <div className="row g-3 g-xl-4">
                            <div className="col-sm-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Candidate Name</div>
                                    <div className="value text-capitalize">{decryptedData.user_name?decryptedData.user_name:''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Candidate Email</div>
                                    <div className="value text-capitalize">{decryptedData.candidateEmail?decryptedData.candidateEmail:''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Contact Number</div>
                                    <div className="value text-capitalize">{decryptedData.contactNumber?decryptedData.contactNumber:''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Current Company</div>
                                    <div className="value text-capitalize">{decryptedData.currentCompany?decryptedData.currentCompany:''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Interview Date</div>
                                    <div className="value text-capitalize">{decryptedData.interviewDate?convertDate(decryptedData.interviewDate):''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Interview Medium</div>
                                    <div className="value text-capitalize">{decryptedData.interviewMedium?decryptedData.interviewMedium:''}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="info_wrapper d-xl-flex gap-3 gap-md-4">
                                    <div className="col-xl-4 title_name text-secondary text-xl-end">Notice Period</div>
                                    <div className="value text-capitalize">{decryptedData.noticePeriod?decryptedData.noticePeriod:''}</div>
                                </div>
                            </div>

                            {/* Divider */}
                            <hr className="d-none d-xl-block divider border-secondary border-opacity-75 mt-4 mb-0" />

                            <div className="col-12">
                                <Form onSubmit={acceptData}>
                                    <Form.Select value={selectedOption} onChange={handleOptionChange} className="fs-6 mb-3" aria-label="Default select example" required>
                                        <option value="">Select Status</option>
                                        <option value="open">Open</option>
                                        <option value="closed">Closed</option>
                                        <option value="inprogress">In Progress</option>
                                    </Form.Select>
                                    <Form.Group value={textareaValue} onChange={handleTextareaChange} className="mb-4" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" className="fs-6" rows={4} placeholder="Note..." required />
                                    </Form.Group>
                                    <div className="btn_wrapper d-flex justify-content-center gap-3">
                                        <Button type="button" variant="secondary" className="py-2 px-5" onClick={handleClose}>Cancel</Button>
                                        <Button type="submit" variant="success" className="py-2 px-5">Submit</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
