import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import axios from 'axios';
import ExportToExcel from '../components/ExportToExcel';
import moment from 'moment';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import CryptoJS from 'crypto-js';
import Pagination from '../components/Pagination';

export const TotalRequisition = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [candidateList, setCandidate] = useState([]);
  const [error, setError] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchtestResult",
    "queryType": [],
    "isSearch": false,
  });
  const [users, setUsers] = useState([])
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [dashboardData, setDashboardData] = useState({
    inhouseHrCount: 0,
    outsourcedHrCount: 0,
    uniqueCandidateCount: 0,
    passCount: 0,
    failCount: 0
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;

  const openFilter = (event) => {
    setIsActive(current => !current);
  };

  useEffect(() => {
    fetchrequisitionsList()
  }, [currentPage, currentUserVal])

  const RequestionDetails = (info) => {
    // console.log('Myrequisition')
    // console.log(info.id)
    let requisitionId = info?.id;
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(requisitionId), 'secret_key').toString();
    navigate(`/my-requisition-details?query=${encodeURIComponent(encryptedQuery)}`);
  }

  // total Requisition
  const fetchrequisitionsList = async (searchValue) => {
    setIsLoading(true);
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var expValue = ''
    var jobValue = ''
    var deptValue = ''
    var shiftValue = ''
    var jobnatureValue = ''
    var reqByValue = ''
    const postData = {
      department: deptValue != undefined ? deptValue : '',
      minimumExp: expValue != undefined ? expValue : '',
      employmentType: jobValue != undefined ? jobValue : '',
      requestedBy: reqByValue != undefined ? reqByValue : '',
      shiftType: shiftValue != undefined ? shiftValue : '',
      jobNatureType: jobnatureValue != undefined ? jobnatureValue : '',
      myEMailId: user?.email,
      statusType: ' ',
      searchString: searchValue != undefined ? searchValue : '',
      page: currentPage,
      pageSize: itemsPerPage
    }
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/requisitions/get-requisitions?page=${currentPage}&pageSize=${itemsPerPage}`, postData, { headers })
      .then(function (response) {
        var userDatares = response.data;
        var requisitionsList = userDatares.requisitions
        setItems(requisitionsList);
        let data = userDatares.data?.length > 0 ? userDatares.data : [];
        setTotalPages(userDatares?.totalPages)
        setUsers(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);
      });
  }
  // total Requisition

  // searchFunction
  const searchFunction = (event) => {
    let searchText = event.target.value;
    fetchrequisitionsList(searchText)
    setSearchVal(searchText);
  }
  // searchFunction

  // buttonClick
  const handleClick = (type) => {
    if (type === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (type === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  // buttonClick

  return (
    <div className='interviewList-page dashboard-home py-3'>
      <div className="container-fluid">
        <div className='filter-sec mb-2'>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            <div className="filter-label fw-medium pointer"></div>

            {candidateList?.length > 0 ?
              <ExportToExcel data={candidateList} filename="my_data" /> :
              null
            }
          </div>
          <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
            <div className="searchfield-wrapper flex-fill">
              <input type="search" className="form-control rounded-2" id="search" value={searchVal} placeholder="Search Here..." onChange={searchFunction} />
              <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>

        <div className='candidates-wrapper'>
          {/* When loader visible */}
          {isLoading ? (
            <Loader />
          ) : ''}

          <div className='candidate-list'>
            {items.map((info, id) => {
              const originalMoment = moment(info?.test_starttime).utc();
              const formattedDate = originalMoment.format('YYYY-MM-DD HH:mm:ss');

              let profilePic = "";
              let startMoment = moment(info?.test_starttime).utc();
              let endMoment = moment(info?.test_endtime).utc();
              let duration = moment.duration(endMoment.diff(startMoment));
              let testStartDatetime = startMoment.format("YYYY-MM-DD HH:mm:ss");
              profilePic = info?.profile_img;

              return (
                <div className="info-cards card small bg-white rounded overflow-auto p-3 mt-2">
                  <div className="row g-2">
                    <div className="col-6 col-md-2 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        {/* <div className="img-wrapper mb-2">
                          <img src={profilePic} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                        </div> */}
                        <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info?.jobTitle}</div>
                        {info?.keySkills && <div className="cand-email text-muted text-truncate text-lowercase"> {info?.keySkills}</div>}
                        {info?.location && <div className="cand-contact text-muted text-truncate text-capitalize">{info?.location}</div>}
                      </div>
                    </div>
                    <div className="col-6 col-md-2 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Department</div>
                        <div className="cand-company text-capitalize">{info?.department}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Experience</div>
                        <div className="cand-company text-capitalize">{info?.minimumExp}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 col-xl-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Employment Type</div>
                        <div className="cand-exp text-capitalize">{info?.employmentType}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-2 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Posted</div>
                        <span className={`fs-12 badge rounded-pill px-3 ${info.job_status === 'In Job' ? 'text-bg-success' : 'text-bg-secondary' && info.job_status === 'On Notice Period' ? 'text-bg-danger' : 'text-bg-success' && info.job_status === 'No Where Working' ? 'text-bg-warning' : 'text-bg-secondary'}`}>{info?.time_ago}</span>
                      </div>
                      <div className='action-wrapper d-flex gap-2 mt-2'>
                        <button type='button' className='btn btn-sm btn-light border px-3' onClick={() => RequestionDetails(info)}>View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )}
            {/* pagination */}
            {items?.length >= 1 ?
              <div className='paginationBtn mt-4'>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('prev')} disabled={currentPage === 1}>Prev</button>
                <span className='px-2 p-2 backWhite'>Page {currentPage} of {totalPages}</span>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('next')} disabled={currentPage === totalPages}>Next</button>
              </div> : ''}
            {/* pagination */}
          </div>
          {/* No data available */}
          {items?.length == 0 ? (
            <div className="no-data-wrapper bg-white rounded px-3 py-5 mt-2">
              <div className="text-center mx-auto" style={{ maxWidth: '350px' }}>
                <img src={require('../assets/no-data.jpg')} alt="Candidate" className="img-fluid" />
                <div className='fs-4 fw-semibold text-danger mt-3'>Sorry no record Found !</div>
                <div className='small text-muted'>Whoops... this information is not available for a moment</div>
              </div>
            </div>) : null
          }
        </div>
      </div>
    </div >

  )
}