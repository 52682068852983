import React, { useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Modal, Form, Button } from 'react-bootstrap';

// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { callAxios, callAxiosFile } from '../../utils/util';
// import { environment } from '../../environment';
import { toast } from 'react-toastify';
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

const animatedComponents = makeAnimated();


export const AddNewIssueModal = ({ show, close, setIssues, userRole, setShowAddIssueModal, startDate, endDate, setStatusD, setIsloading, getIssues,getCount }) => {
    // React-slick npm style customisation
    const { user, userInfoLoaded, currentUserVal } = UserAuth();
    const [subject, setSubject] = useState("");
    const [ticketType, setTicketType] = useState("");
    const [priority, setPriority] = useState("");
    const [description, setDescription] = useState("");
    const [files, setFiles] = useState([]);
    const [submitButtonDisable,setSubmitButtonDisable] = useState(false);

    const [preview, setPreview] = useState([])
    
    const checkValidation = (key, val) => {
        if (key === "subject" && val !== "") {
            const subjectStyle = document.getElementById("subject");
            if (subjectStyle)
                subjectStyle.style.border = "none";
        }
        if (key === "priority" && val !== "") {
            const priorityStyle = document.getElementById("priority");
            if (priorityStyle)
                priorityStyle.style.border = "none";

        }
        if (key === "ticketType" && val !== "") {
            const typeStyle = document.getElementById("ticketType");
            if (typeStyle)
                typeStyle.style.border = "none";

        }
        if (key === "description" && val !== "") {
            const issueDescStyle = document.getElementById("issue-description")
            if (issueDescStyle)
                issueDescStyle.style.border = "none";
        }

        if (key === "changeStatus" && val !== "") {
            const changeStatusStyle = document.getElementById("changeStatus");
            if (changeStatusStyle)
                changeStatusStyle.style.border = "none";
        }
    };

    if (description) {
        const issueDescStyle = document.getElementById("issue-description");
        if (issueDescStyle)
            issueDescStyle.style.border = "none";
    }
      const handleFormSubmit = async (e) => {
        e.preventDefault();
       
        if (subject === "") {
          document.getElementById("subject").style.border = "1px solid red";
          return false;
        } else if (ticketType === "") {
            document.getElementById("ticketType").style.border = "1px solid red";
            return false;
        }  else if (priority === "") {
          document.getElementById("priority").style.border = "1px solid red";
          return false;
        } else if (description === "") {
          document.getElementById("issue-description").style.border =
            "1px solid red";
          return false;
        } else {
          setIsloading(true)
          const formData = {
            uid: user?.uid,
            subject: subject,
            priority: priority,
            status: "open",
            description: description,
            name: user?.displayName,
            email: user?.email,
            userType: user?.userType,
            ticketType:ticketType,
           files:preview,
          attachmentType:"issue"
          };
          // console.log(formData)
    
        //   const response = await callAxios(
        //     process.env.REACT_APP_ADMIN_API + "/v4.0/issues/save-issue",
        //     formData,
        //     "post"
        //   );
        setSubmitButtonDisable(true)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        var headers = {
            'Authorization': `Bearer ${refreshedToken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/save-issue", formData, { headers });
        // console.log(response)
          setSubmitButtonDisable(false)
          if (response?.data?.status === 200) {
            resetForm();
            setPreview([])
            setShowAddIssueModal(false)
            setIsloading(false)
            toast.success("Ticket Submitted!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
       
            toast.error("Network Issue!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      };
    
      const resetForm = async () => {
        //console.log("In reset form");
        setSubject("");
        setPriority("");
        setDescription("");
        setTicketType("")
        // const element = document.getElementsByClassName("ql-editor");
        // element[0].innerHTML = "";
        setSubmitButtonDisable(false)
        getIssues()
        getCount()
        // document.getElementById("issueForm").reset();
    
        // document.getElementById("closeIssueForm").click();
        
      };



    // Define the quill toolbar modules
    const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            ['link', 'video', 'formula'],
            ['blockquote', 'code-block'], // Quote
            ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    const uploadFiles = async (event) => {
      const refreshedToken = await currentUserVal?.getIdToken(true);
      setIsloading(true)
      console.log(event);
      const selectedFiles = event.target.files; // Get the selected files
      // console.log(selectedFiles);
    
    //   Check if there are any selected files
      if (selectedFiles.length > 0) {
        const formData = new FormData();
    
        // Append each selected file to the formData object
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('files', selectedFiles[i]); // 'files' should match the key in the backend
        }
    
        try {
            
            setSubmitButtonDisable(true)
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'multipart/form-data', // Set content type to form data
            };
            const response = await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/upload-to-s3", formData, { headers });
            // const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/v3.0/s3/upload`, formData, { headers });
            
            // setPreview(response.data)
            setPreview(prevData => (prevData ? [...prevData, ...(response.data || [])] : [...(response.data || [])]));

            setIsloading(false)
            setSubmitButtonDisable(false)
          // console.log('Files uploaded successfully:', response.data);
    
          // Clear the file input
          event.target.value = null; // Reset the file input
        } catch (error) {
            setSubmitButtonDisable(false)
          console.error('Error uploading files:', error);
        }
      }
    };
// console.log(preview)

    const handleDelete = async(itemUrl) =>{
        setSubmitButtonDisable(true)
        const refreshedToken = await currentUserVal?.getIdToken(true);
        // console.log(itemId, itemUrl)
        const updatedAttachments = preview.filter(item => item.upload !== itemUrl);
        setPreview(updatedAttachments)
        try {
            const headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'multipart/form-data', // Set content type to form data
            };
            setSubmitButtonDisable(false)
            const response =  await axios.post(process.env.REACT_APP_ADMIN_API + "/v4.0/issues/delete-attachment", {url:itemUrl}, { headers });
        } catch (error) {
            setSubmitButtonDisable(false)
            console.error('Error uploading files:', error);
        }

    }

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="addNewIssueModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-1">
                    <div className="fs-3 fw-bold text-dark text-center lh-sm mb-4">Create Ticket</div>
                    <Form className="user-form-details d-flex flex-column h-100"  id="issueForm">
                        <Form.Group className="mb-3" >
                            <Form.Control type="text" id="subject" className="custom-input shadow-none py-2" placeholder="Add subject" autoComplete="off" required
                                aria-describedby="subjectlHelp" onChange={(e) => {
                                    setSubject(e.target.value);
                                    checkValidation("subject", e.target.value);
                                }} />
                        </Form.Group>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <Form.Group>
                                    <div className="custom-select">
                                        <Form.Select className="custom-input form-select px-3 py-2" id="ticketType"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setTicketType(e.target.value);
                                                checkValidation("ticketType", e.target.value);
                                            }} required>
                                            <option value="" hidden >Select Ticket Type</option>
                                            <option value="Technical">Technical</option>
                                            <option value="Non Technical">Non Technical</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Bug/Issue Reported">Bug/Issue Reported</option>
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group>
                                    <div className="custom-select">
                                        <Form.Select className="custom-input form-select px-3 py-2" id="priority"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setPriority(e.target.value);
                                                checkValidation("priority", e.target.value);
                                            }} required>
                                            <option value="" hidden>Select Priority</option>
                                            <option value="0">Low</option>
                                            <option value="1">High</option>
                                            <option value="2">Medium</option>
                                            <option value="3">Normal</option>
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="editor-section mb-3">
                            <ReactQuill id="issue-description" className="custom-quill-editor rounded overflow-hidden" theme="snow" placeholder="Description.."
                                modules={toolbarModules}
                                formats={formats} onChange={setDescription} 
                            />
                        </div>

                        <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                {preview && preview.length >0 && preview.map((item,index)=>(
                                <div className="file-btn fs-14 text-dark text-opacity-75 d-flex gap-1 border border-secondary rounded-3 position-relative px-2 py-1 py-xl-2">
                                   <span className="deleteAttachment position-absolute z-3 bg-danger rounded-circle d-flex align-items-center justify-content-center pointer text-white" style={{height:"16px", width:"16px", top:"-8px", right:"-8px"}} onClick={()=>{handleDelete(item.upload)}}><i className="bi bi-x"></i></span>
                                    
                                    <span className="text-truncate">{decodeURIComponent(item.upload.split("_")[1])}</span>
                                    <a href={item.upload} target="_blank"><i className="bi bi-cloud-download ms-1"></i></a>
                                </div>
                                ))}
                               
                            </div>
                        <div className="file-upload-wrapper mb-3">
                            <Form.Control type="file" id="attachFile-2" className="d-none" onChange={(e) => uploadFiles(e)} multiple />
                            <label for="attachFile-2" className="attachFile-wrapper d-flex align-items-center justify-content-between gap-2 rounded-3 p-2" title='Upload File'>
                                <div className="d-flex align-items-center gap-1 text-muted pointer"><i className="bi bi-paperclip fs-5"></i> Attach file</div>
                            </label>
                        </div>

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 pt-3">
                            <Button variant="secondary" onClick={close} className='btn-custom px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="success" className='btn-custom px-5 py-2' onClick={handleFormSubmit}  disabled={submitButtonDisable}>
                                {
                                    submitButtonDisable?'Pleas Wait ...':'Submit'
                                }
                            </Button>
                            
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
