import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css';
import ModalImage from "react-modal-image";
import Modal from 'react-bootstrap/Modal';
import { Button, Tab, Tabs } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { getAuth, database, secondDatabase } from '../firebaseConfig';
import axios from 'axios';
import { Loader } from '../components/loader';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';

import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    onSnapshot,
    orderBy,
    updateDoc,
    limit,
    serverTimestamp,
    getDoc,
} from "firebase/firestore";

export const TestDetails = () => {
    const location = useLocation();
    const auth = getAuth();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState(null);
    const [quesList, setQuesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [imageKeys, setImageKeys] = useState([]);
    const [documentData, setDocumentData] = useState(null);
    const [isCompositionDone, setComposition] = useState(false);
    const { user, userInfoLoaded, currentUserVal } = UserAuth();

    // For Video Fullscreen Model 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // For Nav & Tabs For Details
    const [key, setKey] = useState('home');

    const detail = location.state;

    let startMoment = moment(detail?.test_starttime).utc();
    let endMoment = moment(detail?.test_endtime).utc();
    let duration = moment.duration(endMoment.diff(startMoment));

    const formattedDate = startMoment.format('YYYY-MM-DD HH:mm:ss');
    useEffect(() => {
        if (userDetails?.room_sid) {
            console.log("********Room ****");
            console.log(userDetails?.room_sid);
            // Reference to a specific document in the second Firestore database
            const documentRef = doc(secondDatabase, 'userDevices', userDetails.room_sid);
            console.log(documentRef);
            // Set up a real-time listener for changes to the document
            const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setDocumentData(docSnapshot.data());

                    let documentDataVal = docSnapshot.data();
                    if (documentDataVal?.compositionSid && documentDataVal?.compositionPercentageDone)
                        setComposition(false);
                    if (documentDataVal?.compositionSid && documentDataVal?.compositionPercentageDone == 100)
                        handleMeetingViewAndDownload(documentDataVal?.compositionSid);

                } else {
                    // Document doesn't exist
                    console.log('Document not found.');
                    setComposition(false);
                }
            });

            // Clean up the listener when the component is unmounted or when userDetails?.room_sid changes
            return () => {
                unsubscribe();
            };
        }
    }, [userDetails?.room_sid]);

    const handleMeetingViewAndDownload = async (compositionId) => {
        //// console.log("composition Id:", compositionId, " | type:", type)
        let mediaResponse;
        const url = 'https://dev.virtualemployee.com/node/api/v1/video-media/get-media-url';
        const requestBody = {
            compositionSid: compositionId,
        };
        axios
            .post(url, requestBody)
            .then((response) => {
                // console.log('Response:', response.data);
                let url = response?.data?.url;
                // console.log(url);
                var videoSrc = document.getElementById("videoSrc");

                const isSupp = videoSrc.canPlayType("video/mp4");
                if (isSupp == "") {
                    videoSrc.src = url;
                } else {
                    videoSrc.src = url;
                }
                videoSrc.load();
                videoSrc.play();
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }
    //Fetch Test Questions and Reason 
    // Fetch Test Questions and Reason
    const fetchTestInfo = async (user_id, test_id) => {
        setIsLoading(true);
        try {
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var data = JSON.stringify({
                "user_id": user_id,
                "test_id": test_id,
            });
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${process.env.REACT_APP_TESTSERVER}get-interview-details`, data, { headers });
            let testDatares = response.data;
            let dataRes = testDatares.data?.length > 0 ? testDatares.data : [];
            setIsLoading(false);
            setQuesList(dataRes);
        } catch (error) {
            // console.log(error);
            setIsLoading(false);
        }
    }



    const fetchAdditionQues = async (ques_id) => {
        try {
            setIsLoading(true);
            // let getToken = await auth.currentUser.getIdToken();
            //let getToken = user?.accessToken;
            const refreshedToken = await currentUserVal?.getIdToken(true);
            var data = JSON.stringify({
                "operation": "fetchadditionalanswer",
                "ques_id": ques_id,
            });
            var headers = {
                'Authorization': `Bearer ${refreshedToken}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(process.env.REACT_APP_API_URL, data, { headers });


            let testDatares = response.data;
            let resdata = testDatares.data?.length > 0 ? testDatares.data : [];
            return resdata;
        } catch (error) {
            // console.log(error);
            return [];
        } finally {
            setIsLoading(false);
        }
    }
    const TestResultDetails = (info) => {
        navigate('/test-result-details', { state: info });
    }


    useEffect(() => {
        console.log(detail)
        setUserDetails(detail);
        if (detail?.test_id) {
            fetchTestInfo(detail?.userId, detail?.test_id)
        }

    }, [detail])



    const createComposition = async (roomId) => {
        setComposition(true);
        const url = 'https://dev.virtualemployee.com/open-api/v1/composition/create-composition';
        const requestBody = {
            sid: roomId,
        };
        axios
            .post(url, requestBody)
            .then((response) => {
                console.log('ResponseComposition:', response.data);
                setComposition(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setComposition(false);
            });
    }

    return (
        <div className='interview-det-page py-3'>
            <div className="container-fluid">

                <div className="d-flex flex-wrap justify-content-between gap-2 mb-2">
                    <div className='test-date d-flex align-items-center gap-2 text-muted'>
                        <span>Round 1:</span>
                        <span className='fw-semibold'>Technical Round</span>
                    </div>
                    <div className='test-date d-flex align-items-center gap-2 text-muted'>
                        <span>Test Date:</span>
                        <span className='fw-semibold'>{formattedDate}</span>
                    </div>
                </div>

                {/* Candidate Profile Details */}
                <div className='card p-3 p-xxl-4 mb-3'>
                    <div className="candidate-details">
                        <div className="row align-items-center">
                            <div className="col-md-7 mt-2 mt-md-0">
                                <div className="profile-img mb-2">
                                    <img src={userDetails?.user_photo} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid border border-2 border-primary rounded-3 object-fit-cover" style={{ width: '80px', height: '80px' }} />
                                </div>

                                <div className="cand-name text-capitalize mb-1">
                                    <span className='fs-18 fw-medium'>{userDetails?.displayName}</span>
                                    <span className='small text-muted ms-1'></span>
                                </div>

                                <div className='d-flex flex-wrap row-gap-2 column-gap-3 mb-1'>
                                    <div className='cand-contact d-flex align-items-center gap-2 text-muted'>
                                        <i className="bi bi-telephone-fill text-secondary"></i> {userDetails?.contactNo}
                                    </div>
                                    <div className='cand-contact d-flex align-items-center gap-2 text-muted'>
                                    <i class="bi bi-envelope-fill text-secondary"></i> {userDetails?.userEmail}
                                    </div>
                                </div>
                                <div className="info-wrapper fs-14 text-secondary mt-2">
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Total Time Taken by Candidate Skipped:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.totalSkipTime}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Total Questions Skipped:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.skip_count}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Programming Language:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.selectedLanguage}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Total Questions Passed:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.right_count}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Total Questions Attempted:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.ques_attempt}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Total Questions Failed:</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.wrong_count}</div>
                                        </div>
                                    </div>
                                    <div className='row gx-2'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">{userDetails?.test_type == "2" ? "One To One Round By Sheela:" : "Technical Test Result:"}</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className={`label-value fw-semibold text-uppercase ${userDetails?.result == 'pass' ? 'text-success' : 'text-secondary' && userDetails?.result == 'fail' ? 'text-danger' : 'text-secondary'}`}>
                                                {userDetails?.result}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row gx-2 mb-1'>
                                        <div className="col-8 col-xl-6">
                                            <div className="label-name">Result Feedback :</div>
                                        </div>
                                        <div className="col-4 col-xl-6">
                                            <div className="label-value fw-semibold">{userDetails?.resultFeedback}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='col-md-5 mt-3 mt-md-0'>
                                <div className='compositionWrapper text-center' > {!documentData?.compositionSid ?
                                    <div>
                                        {isCompositionDone ? <div className="spinner-border text-info" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : null}

                                        {!documentData?.compositionPercentageDone ?
                                            <div className='w-100 text-center'>
                                                <button onClick={() => createComposition(userDetails?.room_sid)} className="btn btn-success btn-custom btn-next rounded-1 mt-1" data-toggle="modal" >
                                                    <i className="bi bi-file-earmark-plus-fill" ></i> Create Composition
                                                </button>
                                                <p className='mt-2'>Please click on button to create merged Interview video with screen sharing .</p>
                                            </div> :
                                            <p className='mt-2'>Please wait we are Processing your request Video will be displayed once completed .</p>

                                        }
                                    </div>
                                    : null}
                                    {/* {documentData?.compositionPercentageDone ? <p>{`Completed composition percentage ${documentData?.compositionPercentageDone} %`}</p> : null} */}
                                </div>
                                {documentData?.compositionSid && documentData?.compositionPercentageDone == 100 ? <div className='videoWrapper'>
                                    <div className="fs-18 fw-medium text-capitalize mb-2"><i className="bi bi-film small text-primary"></i> Interview video recording -</div>
                                    <div className="zoom-wrapper ratio ratio-16x9 overflow-hidden" style={{ maxWidth: '500px' }}>
                                        <video id="videoSrc" className='border rounded w-100 d-block object-fit-cover' height="240" controls></video>
                                        {/* <div className="relative-content bg-dark bg-opacity-75" onClick={handleShow}>
                                            <i className="bi bi-arrows-fullscreen me-1"></i> Inlarge View
                                        </div> */}
                                    </div>

                                </div>
                                    : null}
                                {documentData?.compositionPercentageDone ? <div className='text-danger fw-medium mt-2'>Completed composition percentage - <span className='text-success fw-semibold'>{`${documentData?.compositionPercentageDone}%`}</span></div> : null}

                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Fullscreen Model Popup */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" scrollable centered contentClassName="border-0 shadow">
                    <Modal.Header closeButton closeVariant="white" className='bg-primary bg-gradient fs-18 text-white text-capitalize lh-sm'>
                        <div className='text-white'><i className="bi bi-film small"></i> Inertview Video Recording</div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="ratio ratio-16x9 overflow-hidden">
                            <video className='border rounded w-100 d-block object-fit-cover' controls autoPlay>
                                <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Question/Answer Listing */}
                <div className='card rounded-3 p-3'>
                    <div className="fs-18 fw-medium mb-2">All Questions Listing ---</div>
                    <div className="ques-ans-wrapper d-flex flex-column gap-3">
                        {quesList.map((info, index) => {
                            let date = new Date(info?.ques_time);
                            let year = date.getUTCFullYear();
                            let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Add 1 to month because it's 0-indexed
                            let day = String(date.getUTCDate()).padStart(2, '0');

                            // Format the date as "yyyy-mm-dd"
                            const formattedDate = `${year}-${month}-${day}`;
                            const quesTime = moment(info?.ques_time).format('YYYY-MM-DD HH:mm:ss a');
                            const ansTime = moment(info?.ans_time).format('YYYY-MM-DD HH:mm:ss a');
                            return (
                                <div className={`ques-ans-item p-3 ${info.result === 'Pass' ? 'status-pass' : 'status-skipped' && info.result === 'Fail' ? 'status-fail' : 'status-skipped'}`} key={index}>
                                    <div className="question-wrapper fs-14">
                                        <div className='info-box'>
                                            <div className='title-label text-black text-opacity-75 fs-6 fw-medium mb-1'>Question {index + 1}:</div>
                                            <div className='question text-secondary mb-2'>{info?.question}</div>
                                            {info?.example ? <div><div className='title-label text-secondary'>Example:</div>
                                                <div className='example text-secondary'>{info?.example}</div></div> : null}

                                        </div>
                                    </div>

                                    <div className="row g-2 ques-timing fs-14 mt-3 mt-xl-4">
                                        <div className="col-xl-4 col-md-6">
                                            <div className='info-box d-flex gap-2 mb-2 pb-xl-1'>
                                                <div className='label-name'>Visible to Candidate on:</div>
                                                <div className='label-value fw-semibold text-uppercase'>{quesTime}</div>
                                            </div>
                                            <div className='info-box d-flex gap-2'>
                                                <div className='label-name'>Result:</div>
                                                <div className={`label-value fw-semibold text-uppercase ${info.result === 'Pass' ? 'text-success' : 'text-warning' && info.result === 'Fail' ? 'text-danger' : 'text-warning'}`}>{info?.result}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-md-6">
                                            <div className='info-box d-flex gap-2 mb-2 pb-xl-1'>
                                                <div className='label-name w-75'>Candidate Submitted the Solution At:</div>
                                                <div className='label-value w-25 fw-semibold text-uppercase'>{ansTime}</div>
                                            </div>
                                            <div className='info-box d-flex gap-2'>
                                                <div className='label-name w-75'>Total Time Taken by Candidate to Complete the Solution:</div>
                                                <div className='label-value w-25 fw-semibold text-nowrap'>{info?.timeTaken}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-12">
                                            <div className="btn-wrapper d-flex align-items-end justify-content-xl-end h-100 pb-xl-2">
                                                <button className='btn btn-light border-white d-flex align-items-center rounded-pill text-muted lh-sm ps-4 pe-1 py-1' onClick={() => TestResultDetails(info)}>View Result Details
                                                    <span className='icon bg-dark bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center ms-3 p-1' style={{ height: '30px', width: '30px' }}>
                                                        <i className="bi bi-arrow-down d-block text-light" style={{ transform: 'rotate(-45deg)' }}></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {info.result === 'Pass' && (
                                        <div className="passinfo bg-warning bg-opacity-50 fs-14 mt-3 p-2">
                                            If the result is pass, Sheela asked the candidate cross questions based on their submitted solution code to understand the candidate's deep knowledge of the submitted correct answer.
                                            In the detailed page, you can view all related questions and their answers with results.
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
