import {React, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SecondConsentModel = ({ isOpen, onClose, onConfirm }) => {
    const [show, setShow] = useState(true);
    const handleClose = () => 
    {
        setShow(false);
    }
    if (!isOpen) {
        return null;
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Second Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I will not close if you not click on Second consent button.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Agree</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SecondConsentModel;