import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { TabContent } from "react-bootstrap";
import CryptoJS from 'crypto-js';

export const RecruitmentDetails = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const encryptedQuery = queryParams.get('query');
    // React Bootstrap Model
    const [show, setShow] = useState(false);
    const [userParam, setUserParam] = useState([]);

    // For model
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // const CandidateDetails = (info) => {
    //     navigate('/user-detail', { state: info });
    // }

    const bytes = CryptoJS.AES.decrypt(encryptedQuery, 'secret_key');
    const decryptedQuery = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
    useEffect(() => {    
        // console.log("decryptedQuery");
        setUserParam(decryptedQuery);
    }, [])
    
    // For Nav & Tabs For Details
    const [key, setKey] = useState('jobDetail');

    return (
        <div className='dashboard-page py-3'>
            <div className='container-fluid'>

                <div className='d-flex align-items-sm-center justify-content-sm-between flex-column flex-sm-row gap-2 mb-3'>
                    <div className='left-sec fs-5 fw-medium'>
                        <i className="bi bi-journal-album"></i> Recruitment Detail
                    </div>

                    <Button variant="success" className="rounded-pill px-4 py-2" onClick={handleShow}>
                        <i className="bi bi-plus-lg"></i> Add Status
                    </Button>
                </div>

                {/* Tabs Selction Code For Details */}
                <div className="tab-sec-wrapper bg-white rounded-bottom rounded-bottom-3 mt-3">
                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} justify className="custom-tabs fw-medium border-bottom" variant="pills">
                        <Tab eventKey="jobDetail" title={<span className="text-capitalize"><i className="bi bi-briefcase me-1"></i> Job Detail</span>}>
                            <div className="container-xl my-3 px-0">
                                <div className="row gx-3">
                                    <div className="col-md-6">
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Job Title</div>
                                            <div className="value text-capitalize">{userParam.jobTitle?userParam.jobTitle:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Minimum Experience</div>
                                            <div className="value text-capitalize">{userParam.minimumExp?userParam.minimumExp:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Location</div>
                                            <div className="value text-capitalize">{userParam.location?userParam.location:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Employment Type</div>
                                            <div className="value text-capitalize">{userParam.employmentType?userParam.employmentType:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-lg-4 mb-2">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Key Skills</div>
                                            <div className="value text-capitalize">{userParam.keySkills?userParam.keySkills:''}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Primary Role</div>
                                            <div className="value text-capitalize">{userParam.primaryRole?userParam.primaryRole:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Minimum Qualification</div>
                                            <div className="value text-capitalize">{userParam.minimumQualification?userParam.minimumQualification:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Department</div>
                                            <div className="value text-capitalize">{userParam.department?userParam.department:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Date</div>
                                            <div className="value text-capitalize">{userParam.reqiredBy?userParam.reqiredBy:''}</div>
                                        </div>
                                        <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                            <div className="col-5 col-lg-4 title_name text-secondary text-end">Other HR Working</div>
                                            <div className="value text-capitalize"><span>Keshav</span>, <span>Rajni</span></div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3 mt-lg-0">
                                        <div className="info_wrapper d-lg-flex gap-4">
                                            <div className="col-lg-2 title_name text-secondary text-center text-lg-end mb-2">Job Description</div>
                                            <div className="value ms-lg-n1 text-center text-lg-start">
                                            {userParam.jobDescription?userParam.jobDescription:''}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Divider */}
                                    <hr className="divider border-secondary border-opacity-75 my-3 my-md-4" />

                                    <div className="col-12 mb-2">
                                        <div className="info_wrapper d-lg-flex gap-4">
                                            <div className="col-lg-2 title_name text-secondary text-center text-lg-end mb-1">Requested By</div>
                                            <div className="value text-capitalize ms-lg-n1 text-center text-lg-start">
                                                <span className="req_name me-2">Suresh Kumar,</span>
                                                <span className="req_position me-2">Manager,</span>
                                                <span className="req_department">PHP</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="jobStatus" title={<span className="text-capitalize"><i className="bi bi-images me-1"></i> Job Status</span>}>
                        {[...Array(10)].map((elementInArray, index) => ( 
                            <div className="data-row border-bottom pb-3 my-3 px-0" key={index}>
                                <div className="row g-2">
                                    <div className="col-6 col-sm-4 col-lg-2">
                                        <div className="info_wrapper">
                                            <div className="title_name text-secondary mb-1">Date</div>
                                            <div className="value text-capitalize">26/01/2024</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-lg-2 order-2 order-sm-0">
                                        <div className="info_wrapper">
                                            <div className="title_name text-secondary mb-1">Current Status</div>
                                            <div className="value text-capitalize">In progress</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-lg-2">
                                        <div className="info_wrapper">
                                            <div className="title_name text-secondary mb-1">By</div>
                                            <div className="value text-capitalize">Select</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-2 mt-lg-0 order-last order-sm-0">
                                        <div className="info_wrapper">
                                            <div className="title_name text-secondary mb-1">Notes</div>
                                            <div className="value">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </Tab>
                    </Tabs>
                </div>

                {/* Re-Assign Candidate Model Popup */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered scrollable contentClassName="rounded-4">
                    <Modal.Body className="p-3 py-4 p-lg-5">
                        <div className="fs-3 fw-semibold text-center mb-2 mb-lg-4 mt-lg-n2">Add Status</div>
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Job Title</div>
                                    <div className="value text-capitalize">Sr. Software Developer</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Minimum Experience</div>
                                    <div className="value text-capitalize">5y 6m</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Location</div>
                                    <div className="value text-capitalize">NSEZ, Noida</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Employment Type</div>
                                    <div className="value text-capitalize">Full Time</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-lg-4 mb-2">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Key Skills</div>
                                    <div className="value text-capitalize">PHP, JAVA, Visual Studio</div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Primary Role</div>
                                    <div className="value text-capitalize">In House</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Minimum Qualification</div>
                                    <div className="value text-capitalize">Select</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Department</div>
                                    <div className="value text-capitalize">Mobile Application 1</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-md-4 mb-2 mb-md-3 mb-xl-4">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Required By</div>
                                    <div className="value text-capitalize">26/01/2024</div>
                                </div>
                                <div className="info_wrapper d-flex gap-3 gap-lg-4 mb-2">
                                    <div className="col-5 col-lg-4 title_name text-secondary text-end">Requested By</div>
                                    <div className="value text-capitalize">
                                        <span className="req_name me-2">Suresh Kumar,</span>
                                        <span className="req_position me-2">Manager,</span>
                                        <span className="req_department">PHP</span>
                                    </div>
                                </div>
                            </div>

                            {/* Divider */}
                            <hr className="divider border-secondary border-opacity-75 my-3 my-md-4" />

                            <div className="col-12">
                                <Form>
                                    <Form.Select className="fs-6 mb-3" aria-label="Default select example" required>
                                        <option value="">Select Status</option>
                                        <option value="Activate">Activate</option>
                                        <option value="Re-Assign">Re-Assign</option>
                                        <option value="Pending">In Progress</option>
                                    </Form.Select>
                                    <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" className="fs-6" rows={4} placeholder="Note..." required />
                                    </Form.Group>
                                    <div className="btn_wrapper d-flex justify-content-center gap-3">
                                        <Button type="button" variant="secondary" className="py-2 px-5" onClick={handleClose}>Cancel</Button>
                                        <Button type="submit" variant="success" className="py-2 px-5">Accept</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
