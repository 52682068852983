import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-phone-input-2/lib/style.css'

export const InterviewCandidate = () => {
  const [isLoading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    user_name: '',
    email: '',
    contact_no:'',
    current_company: '',
    interview_date: '',
    interview_time: '',
    other_details: '',
    resume_url: null,
    added_by_email: '',
    added_by_name: '',
    job_status: '',
    notice_period: '',
    current_ctc: '',
    expected_ctc: ''
  });
  // React Bootstrap Model
  const [assign, setassign] = useState(false);
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAccept = () => setView(true);
  const handlecloseView = () => setView(false);

  const handlereassign = () => setassign(true);
  const handlecloseassign = () => setassign(false);

  const handleSubmit = () =>{

  }

  const handleInputChange = () =>{
    
  }
    return (
    <div className='dashboard-page py-3'>
      <div className='container-fluid'>
        {/* All Company Listing */}
        <div className='cmpny-list-section'>
          <div className='cmpny-list-wrapper bg-white rounded p-3 p-xxl-4'>
            <div className='d-flex align-items-sm-center justify-content-sm-between flex-column flex-sm-row gap-2 mb-3'>
              <div className='col-4 left-sec fs-5 fw-medium'>
                <i className="bi bi-journal-album"></i> My Recruitment List
              </div>
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-3 pt-2">
                        From
                      </div>
                      <div className="col-9">
                        <Form.Control type="date" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2 pt-2">
                        To
                      </div>
                      <div className="col-9">
                        <Form.Control type="date" />
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className='cmpny-list table-responsive rounded-table border rounded-3'>
              <table className="table table-hover align-middle text-nowrap mb-0">
                <thead className="bg-primary text-light position-sticky top-0">
                  <tr>
                    <th scope="col" className="fw-medium">Sr. No</th>
                    <th scope="col" className="fw-medium">Job Title</th>
                    <th scope="col" className="fw-medium">Minimum Experience</th>
                    <th scope="col" className="fw-medium">Location</th>
                    <th scope="col" className="fw-medium">Department</th>
                    <th scope="col" className="fw-medium">Required by</th>
                    <th scope="col" className="fw-medium">No. of HR working</th>
                    <th scope="col" className="fw-medium">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Sr. Software Engineer</td>
                    <td>5y 6m</td>
                    <td>NSEZ, Noida</td>
                    <td>Mobile Application</td>
                    <td>2024-01-03</td>
                    <td>1</td>
                    <td>
                      <div className="d-flex">
                        <span className="d-flex">
                          <NavLink to="/recruitmentdetails" className="nav-link d-flex align-items-center gap-2"><span className="text-capitalize badge rounded-pill fw-medium text-bg-warning" style={{ minWidth: '90px' }}>View Details</span></NavLink>
                        </span>
                        <span onClick={handleAccept} className={`text-capitalize badge rounded-pill fw-medium text-bg-success mx-2 cursor-pointer`} style={{ minWidth: '90px' }}>Accept</span>
                        <span onClick={handlereassign} className={`text-capitalize badge rounded-pill fw-medium text-bg-success cursor-pointer`} style={{ minWidth: '90px' }}>Re-assign</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Recuitment Details */}
        <Modal show={view} onHide={handlecloseView} centered backdrop="static" keyboard={false} size="lg" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-3 px-md-5 py-4">
            {/* <h1 className="modal-title fs-4 fw-medium text-center mb-3" id="addCandidate">View Recruitment</h1> */}
            <Form onSubmit={handleSubmit}>
              <div className="row gx-2 gx-sm-3 gy-2">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 text-end">
                      <h6>Job Title</h6>
                    </div>
                    <div className="col-6">
                      <span>Sr. Software Engineer</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Primary Role</h6>
                      </div>
                      <div className="col-5">
                        <span>In House</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Minimum Experience</h6>
                      </div>
                      <div className="col-6">
                        <span>5y 6m</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Minimum Qualification</h6>
                      </div>
                      <div className="col-5">
                        <span>Select</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Location</h6> 
                      </div>
                      <div className="col-6">
                        <span>NSEZ, Noida</span>
                      </div>
                    </div>                  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Department</h6>
                      </div>
                      <div className="col-5">
                        <span>Mobile Application</span>
                      </div>
                    </div>   
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Employment Type</h6>
                      </div>
                      <div className="col-6">
                        <span>Full Time</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Required by</h6>
                      </div>
                      <div className="col-5">
                        <span>24-1-26</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Key Skills</h6>
                      </div>
                      <div className="col-6">
                        <span>PHP, Java, Visual Studio</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Other HR Working</h6>
                      </div>
                      <div className="col-5">
                        <span>Keshav, Rajni</span>
                      </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                      <div className="col-3 text-end">
                        <h6>Job Description</h6>
                      </div>
                      <div className="col-9">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                      </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                      <div className="col-3 text-end">
                        <h6>Request by</h6>
                      </div>
                      <div className="col-9">
                        <span>Suresh Kumar, Manager, PHP</span>
                      </div>
                    </div>  
                </div>
                
              </div>
              <div className="btn-wrapper text-center mt-4">
                <Button variant="secondary" className="px-3 me-2" onClick={handlecloseView}>Cancel</Button>
                <Button variant="primary" type="submit" className="px-3 bg-success">
                  Accept
                  {isLoading ? <Spinner className="ms-2 border-3"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> : null }
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* Recuitment Details */}

        {/* Re-assign */}
        <Modal show={assign} onHide={handlecloseassign} centered backdrop="static" keyboard={false} size="lg" contentClassName="border-0 border-5 border-top border-primary rounded-0 rounded-bottom">
          <Modal.Body className="modal-body px-3 px-md-5 py-4">
            <h1 className="modal-title fs-4 fw-medium text-center mb-3" id="addCandidate">Re-Assign this job</h1>
            <Form onSubmit={handleSubmit}>
            <div className="row gx-2 gx-sm-3 gy-2">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 text-end">
                      <h6>Job Title</h6>
                    </div>
                    <div className="col-6">
                      <span>Sr. Software Engineer</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Primary Role</h6>
                      </div>
                      <div className="col-5">
                        <span>In House</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Minimum Experience</h6>
                      </div>
                      <div className="col-6">
                        <span>5y 6m</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Minimum Qualification</h6>
                      </div>
                      <div className="col-5">
                        <span>Select</span>
                      </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Location</h6> 
                      </div>
                      <div className="col-6">
                        <span>NSEZ, Noida</span>
                      </div>
                    </div>                  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Department</h6>
                      </div>
                      <div className="col-5">
                        <span>Mobile Application</span>
                      </div>
                    </div>   
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Employment Type</h6>
                      </div>
                      <div className="col-6">
                        <span>Full Time</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Required by</h6>
                      </div>
                      <div className="col-5">
                        <span>24-1-26</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-6 text-end">
                        <h6>Key Skills</h6>
                      </div>
                      <div className="col-6">
                        <span>PHP, Java, Visual Studio</span>
                      </div>
                    </div>  
                </div>
                <div className="col-6">
                    <div className="row">
                      <div className="col-7 text-end">
                        <h6>Request by</h6>
                      </div>
                      <div className="col-5">
                        <span>Suresh Kumar, Manager, PHP</span>
                      </div>
                    </div>  
                </div>
                <div className="col-12 mt-2 border-top mt-3 pt-3">
                  <Form.Group>
                    <Form.Select name="emp_type" onChange={handleInputChange} required>
                      <option value={""}>Select Team Member</option>
                      <option value=" ">Select 1</option>
                      <option value=" ">Select 2</option>
                      <option value=" ">Select 3</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-12 mt-2">
                  <Form.Group className="pt-1">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="key_skills"
                    //   value={formData.other_details}
                      onChange={handleInputChange}
                      autoComplete="off"
                      required
                      placeholder="Message for Team Member(Optional)"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="btn-wrapper text-center mt-4">
                <Button variant="secondary" className="px-3 me-2" onClick={handlecloseassign}>Cancel</Button>
                <Button variant="primary" type="submit" className="px-3 bg-success">
                  Save
                  {isLoading ? <Spinner className="ms-2 border-3"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> : null }
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* Re-assign */}

      </div>
    </div>
  )
}
