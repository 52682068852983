import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import imgDefault from '../assets/Avatar.png';
import searchIcon from '../assets/search.svg'
import { getAuth } from '../firebaseConfig';
import { UserAuth } from '../context/AuthContext';

import axios from 'axios';
import moment from 'moment';
import { Loader } from '../components/loader';
import ExportToExcel from '../components/ExportToExcel';
import CryptoJS from 'crypto-js';
import { useMyContext } from '../context/MyContext';

export const UsersList = () => {
  const auth = getAuth();
  const { user, userInfoLoaded, currentUserVal } = UserAuth();
  const [isActive, setIsActive] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [isDateSet, setDateVal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [userData, setUsers] = useState([]);
  const [regStatus, setRegstatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState(''); // Filter criteria
  const [currentData, setCurrentData] = useState([]);
  const { values, handleChange } = useMyContext()
  const [fetchObj, setfetchObj] = useState({
    "operation": "fetchCandidate",
    "queryType": [],
    "isSearch": false,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchUserList = async () => {
      setIsLoading(true);
      // let getToken = await auth?.currentUser?.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      let filterObjData = {
        "operation": "fetchCandidate",
        "queryType": [],
        "isSearch": false,
      };
      if (user?.userType == "HR") {
        filterObjData = {
          operation: "fetchCandidateByHR",
          queryType: [],
          isSearch: false,
          refered_by_email: user?.email
        }
      }


      var data = JSON.stringify(filterObjData);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {

          let userDatares = response.data;
          let data = userDatares.data?.length > 0 ? userDatares.data : [];
          setUsers(data);

          // Calculate the total number of pages based on the fetched data and items per page
          const totalPages = Math.ceil(data.length / itemsPerPage);
          setTotalPages(totalPages);

          setIsLoading(false);
        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
    if (auth)
      // fetchUserList();
      if ((values.Regstatus == '') || (values.Regstatus == undefined)) {
        fetchUserList();
      } else {
        onloadGetStatus()
        // console.log('handleChange.Regstatus')
        // console.log(values.Regstatus)
      }
  }, [auth])

  // Update the current data whenever the filter or page changes
  useEffect(() => {
    // Calculate the index range for items to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the filtered data to get the current page's data
    const newData = userData.slice(startIndex, endIndex);

    // Update the currentData state with the new data
    setCurrentData(newData);
  }, [currentPage, itemsPerPage, userData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const openFilter = (event) => {
    setIsActive(current => !current);
  };
  // const closeFilter = (event) => {
  //     setIsActive(false);
  // };

  const navigate = useNavigate();

  const InterviewerDetails = (info) => {
    let token = "";
    if (info?.user_photo != "") {
      token = info?.user_photo.match(/\/([^/]+)\.[^.]+$/)[1];
    }
    let sendInfo = {
      "candidate_id": info?.candidate_id,
      "email": info?.email,
      "profile_img": info?.profile_img,
      "candidate_name": info?.candidate_name,
      "login_token": token
    }
    const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();

    navigate(`/user-detail?query=${encodeURIComponent(encryptedQuery)}`);
  }

  const handleStartDateChange = (event) => {
    setError(null);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setError(null);
    if (!startDate && event.target.value != "") {
      setError("Please select start Date before end Date.");
      setDateVal(false);
      return;
    }
    if (startDate > event.target.value && event.target.value != "") {
      setError("End Date cannot be less than the start Date.");
      setDateVal(false);
      return;
    }
    setEndDate(event.target.value);
    setDateVal(event.target.value != "" ? true : false);
  };

  // onload Status
  const onloadGetStatus = async () => {
    const getStatusvalue = values.Regstatus
    setRegstatus(getStatusvalue);
    if (getStatusvalue && getStatusvalue != "") {
      let filterObj = {
        "operation": user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",//"fetchCandidatedata2",//"fetchCandidate",
        "isSearch": false,
        "queryType": [],
        "refered_by_email": user?.email ? user?.email : ''
      }

      if (isDateSet)
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate", //"fetchCandidate",
          queryType: [
            "DateRange", "profileStatus"
          ],
          isSearch: false,
          StartDate: startDate,
          endDate: endDate,
          profileValue: getStatusvalue,
          refered_by_email: user?.email ? user?.email : ''
        }
      else
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",//"fetchCandidate",
          queryType: [
            "profileStatus"
          ],
          isSearch: false,
          profileValue: getStatusvalue,
          refered_by_email: user?.email ? user?.email : ''
        }
      setfetchObj(filterObj);
      // console.log("filterObj profile");
      // console.log(filterObj);
      setIsLoading(true);
      //  let getToken = await auth.currentUser.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {

          let userDatares = response.data;
          let data = userDatares.data?.length > 0 ? userDatares.data : [];
          setUsers(data);

          const totalPages = Math.ceil(data.length / itemsPerPage);
          setTotalPages(totalPages);

          setIsLoading(false);

        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
    else {
      let filterObj = {
        "operation": user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate", //"fetchCandidate3",
        "queryType": [],
        "isSearch": false,
        "refered_by_email": user?.email ? user?.email : ''
      }
      if (isDateSet)
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
          queryType: [
            "DateRange"
          ],
          isSearch: false,
          StartDate: startDate,
          endDate: endDate,
          refered_by_email: user?.email ? user?.email : ''
        }
      else
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
          queryType: [
          ],
          isSearch: false,
          refered_by_email: user?.email ? user?.email : ''
        }
      setfetchObj(filterObj);
      setIsLoading(true);
      //  let getToken = await auth.currentUser.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {

          let userDatares = response.data;
          let data = userDatares.data?.length > 0 ? userDatares.data : [];
          setUsers(data);
          const totalPages = Math.ceil(data.length / itemsPerPage);
          setTotalPages(totalPages);
          setIsLoading(false);

        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
  }
  // onload status

  const funcGetStatus = async (event) => {
    handleChange('Regstatus', event.target.value)
    setRegstatus(event.target.value);
    if (event.target.value != "") {
      let filterObj = {
        "operation": user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
        "queryType": [],
        "isSearch": false,
        "refered_by_email": user?.email ? user?.email : ''
      }

      if (isDateSet)
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
          queryType: [
            "DateRange", "profileStatus"
          ],
          isSearch: false,
          StartDate: startDate,
          endDate: endDate,
          profileValue: event.target.value,
          refered_by_email: user?.email ? user?.email : ''
        }
      else
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
          queryType: [
            "profileStatus"
          ],
          isSearch: false,
          profileValue: event.target.value,
          refered_by_email: user?.email ? user?.email : ''
        }
      setfetchObj(filterObj);
      setIsLoading(true);
      //  let getToken = await auth.currentUser.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {

          let userDatares = response.data;
          let data = userDatares.data?.length > 0 ? userDatares.data : [];
          setUsers(data);

          const totalPages = Math.ceil(data.length / itemsPerPage);
          setTotalPages(totalPages);

          setIsLoading(false);

        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
    else {
      let filterObj = {
        "operation": user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
        "queryType": [],
        "isSearch": false,
        "refered_by_email": user?.email ? user?.email : ''
      }

      if (isDateSet)
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
          queryType: [
            "DateRange"
          ],
          isSearch: false,
          StartDate: startDate,
          endDate: endDate,
          refered_by_email: user?.email ? user?.email : ''
        }
      else
        filterObj = {
          operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",//"fetchCandidatedata2",
          queryType: [],
          isSearch: false,
          refered_by_email: user?.email ? user?.email : ''
        }
      setfetchObj(filterObj);
      setIsLoading(true);
      //  let getToken = await auth.currentUser.getIdToken();
      // let getToken = user?.accessToken;
      const refreshedToken = await currentUserVal?.getIdToken(true);
      var data = JSON.stringify(filterObj);
      var headers = {
        'Authorization': `Bearer ${refreshedToken}`,
        'Content-Type': 'application/json'
      };
      axios.post(process.env.REACT_APP_API_URL, data, { headers })
        .then(function (response) {

          let userDatares = response.data;
          let data = userDatares.data?.length > 0 ? userDatares.data : [];
          setUsers(data);
          const totalPages = Math.ceil(data.length / itemsPerPage);
          setTotalPages(totalPages);
          setIsLoading(false);

        })
        .catch(function (error) {
          // console.log(error);
          setIsLoading(false);

        });
    }
  }

  const handleApplyClick = async () => {
    if (!isDateSet && (startDate != "" || endDate != "")) {
      setError("Please select from and to Date.");
      setDateVal(false);
      return;
    }
    let filterObj = {
      operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
      queryType: [
        "DateRange"
      ],
      isSearch: false,
      StartDate: startDate,
      endDate: endDate,
      refered_by_email: user?.email ? user?.email : ''
    }

    if (regStatus != "" && isDateSet) {
      filterObj = {
        operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
        queryType: [
          "DateRange", "profileStatus"
        ],
        isSearch: false,
        StartDate: startDate,
        endDate: endDate,
        profileValue: regStatus,
        refered_by_email: user?.email ? user?.email : ''
      }
    }


    setfetchObj(filterObj);
    setIsLoading(true);
    // let getToken = await auth.currentUser.getIdToken();
    // let getToken = user?.accessToken;
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(filterObj);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(process.env.REACT_APP_API_URL, data, { headers })
      .then(function (response) {

        let userDatares = response.data;
        let data = userDatares.data?.length > 0 ? userDatares.data : [];
        setUsers(data);
        const totalPages = Math.ceil(data.length / itemsPerPage);
        setTotalPages(totalPages);
        setIsLoading(false);

      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);

      });


  }

  // Function to handle filter changes
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1); // Reset to the first page when the filter changes
  };

  const searchFunction = (event) => {
    let searchText = event.target.value;
    setSearchVal(searchText);
    if (searchText == "") {
      fetchListwithFilter(fetchObj);
    }
    if (searchText != "" & searchText?.length > 2) {
      let updatedFilterData = {
        operation: user?.userType == "HR" ? "fetchCandidateByHR" : "fetchCandidate",
        queryType: [],
        isSearch: true,
        search_query: searchText,
        refered_by_email: user?.email ? user?.email : ''
      };
      fetchListwithFilter(updatedFilterData);
    }
  }
  const fetchListwithFilter = async (filterObj) => {
    setIsLoading(true);

    // let getToken = await auth.currentUser.getIdToken();
    // let getToken = user?.accessToken;
    const refreshedToken = await currentUserVal?.getIdToken(true);
    var data = JSON.stringify(filterObj);
    var headers = {
      'Authorization': `Bearer ${refreshedToken}`,
      'Content-Type': 'application/json'
    };
    axios.post(process.env.REACT_APP_API_URL, data, { headers })
      .then(function (response) {

        let userDatares = response.data;
        let data = userDatares.data?.length > 0 ? userDatares.data : [];
        setUsers(data);
        // Calculate the total number of pages based on the fetched data and items per page
        const totalPages = Math.ceil(data.length / itemsPerPage);
        setTotalPages(totalPages);

        setIsLoading(false);

      })
      .catch(function (error) {
        // console.log(error);
        setIsLoading(false);

      });
  }

  return (
    <div className='interviewList-page py-3'>
      <div className="container-fluid">
        {/* Filter section code */}
        <div className='filter-sec'>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="filter-label fw-medium pointer" onClick={openFilter}><i className="bi bi-filter-circle-fill" title='Click to filter'></i> Filter By -</div>
            {/* <Link to={"/images/myw3schoolsimage.jpg"} type='button' className='btn btn-sm btn-primary focus-ring focus-ring-primary rounded-pill text-nowrap px-3 py-1' download>
                            <i className="bi bi-cloud-download me-1"></i>Export Data
                        </Link> */}
            {userData?.length > 0 ?
              <ExportToExcel data={userData} filename="candidate_data" /> :
              null
            }
          </div>

          {/* <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2 ${isActive ? 'active' : 'd-none'}`}> */}
          <div className={`d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-1 gap-xl-2 mt-2`}>
            <div className="searchfield-wrapper flex-fill">
              <input type="search"
                className="form-control rounded-2"
                id="search"
                placeholder="Search Here..."
                value={searchVal}
                onChange={searchFunction}
              />
              <img src={searchIcon} alt="Search Icon" className="position-absolute top-50 end-0 translate-middle" />
            </div>
            <div className="status-filter flex-fill">
              <select value={regStatus}
                onChange={funcGetStatus}
                className="form-select" aria-label="Default select example" required>
                <option value="">Registration Status</option>
                <option value="Completed">Completed</option>
                <option value="Incomplete">Incomplete</option>
              </select>
            </div>
            <div className="date-filter d-flex align-items-center gap-1 bg-white border rounded-2 px-3">
              <input type="date"
                className="form-control border-0 ps-0"
                id="startDate"
                value={startDate}
                onChange={handleStartDateChange}
                required />
              <input type="date"
                className="form-control border-0 ps-0"
                id="endDate"
                value={endDate}
                onChange={handleEndDateChange}
                required />
              <button type='button'
                className='btn btn-sm btn-primary px-3 lh-1'
                onClick={() => handleApplyClick()}
              >Apply</button>
            </div>
          </div>
        </div>
        {/* Table listing code */}
        <div className='candidates-wrapper'>
          <div className='candidate-list border rounded-3'>
            {isLoading ? (
              <Loader />
            ) : ''}
            {currentData.map((info, id) => {
              let profilePic = "";
              let regDate = moment(info?.created_at);
              let lastActivity = moment(info?.updated_at);
              let regDatetime = regDate.format("YYYY-MM-DD HH:mm:ss");
              let lastActivityDatetime = lastActivity.format("YYYY-MM-DD HH:mm:ss");

              profilePic = info?.profile_img;
              return (
                <div className="info-cards card small bg-white rounded overflow-auto p-3 mt-2">
                  <div className="row g-2">
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="img-wrapper mb-2">
                          <img src={info.profile_img} referrerPolicy="no-referrer" alt="Candidate" className="img-fluid object-fit-cover border border-2 border-secondary border-opacity-75 rounded-1" style={{ width: '45px', height: '40px' }} />
                        </div>
                        <div className="cand-name fs-6 fw-medium text-truncate text-capitalize">{info.candidate_name}</div>
                        {info.email && <div className="cand-email text-muted text-truncate text-lowercase"><i className="bi bi-envelope-fill"></i> {info.email}</div>}
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Phone Number</div>
                        {info.contact_no && <div className="cand-contact text-muted text-truncate text-capitalize"><i className="bi bi-telephone-fill"></i> {info.contact_no}</div>}
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Registration Date</div>
                        <div className="regis-date text-capitalize">{regDatetime}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box action-wrapper">
                        <div className="info-label text-secondary">Total Test</div>
                        <div className="cand-lang btn-success text-capitalize">{info.test_entry_count}</div>
                      </div>

                      <div className="info-box">
                        <div className="info-label text-secondary">Last Active</div>
                        <div className="cand-exp text-capitalize">{lastActivityDatetime}</div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 item-wrapper d-flex flex-column gap-2">
                      <div className="info-box">
                        <div className="info-label text-secondary">Registration Status</div>
                        <span className={`fs-12 badge rounded-pill px-3 ${info.profile_completion_status === 'Completed' ? 'text-bg-success' : 'text-bg-secondary' && info.profile_completion_status === 'Incomplete' ? 'text-bg-danger' : 'text-bg-success'}`}>{info.profile_completion_status}</span>
                      </div>

                      <div className='action-wrapper d-flex gap-2 mt-2'>
                        <button type='button' className='btn btn-sm btn-light border px-3' onClick={() => InterviewerDetails(info)}>View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          {/* Pagination code */}
          <nav className='card overflow-auto mt-2 p-3'>
            <ul className="pagination justify-content-center m-0">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
              </li>
              {/* Previous ellipsis */}
              {currentPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
              {/* Individual page numbers */}
              {Array.from({ length: totalPages }, (_, index) => {
                if (index === 0 || index === totalPages - 1 || Math.abs(currentPage - (index + 1)) <= 1) {
                  return (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                  );
                }
                return null;
              })}
              {/* Next ellipsis */}
              {currentPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
              </li>
            </ul>
          </nav>
        </div >

      </div>
    </div>
  )
}
